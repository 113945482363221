import {apiSlice} from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: `/auth/login/`,
                method: 'POST',
                body: {...credentials}
            })
        }),
        sendConfirmMail: builder.mutation({
            query: () => ({
                url: `/auth/confirm-email/`,
                method: 'POST'
            })
        }),
        confirmMail: builder.mutation({
            query: (token) => ({
                url: `/auth/confirm-email/${token}`,
                method: 'POST'
            }),
            invalidatesTags: [{type: 'confirmMail', id: 'UNIQUE'}],
        }),
        createAccount: builder.mutation({
            query: (params) => {
                const {
                    prenom,
                    nom,
                    adresse,
                    codepostale,
                    ville,
                    telephone,
                    email,
                    datenaissance,
                    password,
                    passwordConfirmation,
                    pays,
                    langue,
                    motoclub,
                    demarche
                } = params;
                return {
                    url: `/auth/signup/`,
                    method: 'POST',
                    body: {
                        nom: prenom,
                        prenom: nom,
                        adresse: adresse,
                        codepostale: codepostale,
                        ville: ville,
                        telephone: telephone,
                        email: email,
                        datenaissance: datenaissance,
                        password: password,
                        passwordConfirmation: passwordConfirmation,
                        paysId: pays,
                        langueId: langue,
                        motoclubId: motoclub,
                        demarche: demarche
                    }
                }
            },
        }),
        verifMail: builder.query({
            query: (email) => ({
                url: `/auth/email?email=${email}`,
                method: 'GET'
            })
        }),
        sendConfirmSmsPhoneNumber: builder.mutation({
            query: () => ({
                url: `/auth/phone-number/`,
                method: 'POST',
            }),
        }),
        verifyCodeSmsPhoneNumber: builder.mutation({
            query: (code) => ({
                url: `/auth/confirm-phone-number/`,
                method: 'POST',
                body: {
                    code: code
                }
            }),
        }),
        disableSms: builder.mutation({
            query: () => ({
                url: `/auth/phone-number/`,
                method: 'DELETE',
            }),
        }),
    })
})

export const {
    useLoginMutation,
    useSendConfirmMailMutation,
    useConfirmMailMutation,
    useCreateAccountMutation,
    useVerifMailQuery,
    useSendConfirmSmsPhoneNumberMutation,
    useVerifyCodeSmsPhoneNumberMutation,
    useDisableSmsMutation,
} = authApiSlice