import {apiSlice} from "../../app/api/apiSlice";
const urlAssurance = "assurance" ;

export const motosApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Assurance'],
    endpoints: builder => ({
        getAssurancesEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlAssurance}/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
            providesTags: (result) =>
                // is result available?
                result ? [...result.map(({id}) => ({type: 'Assurances', id})), {type: 'Assurances', id: 'LIST'},] :
                    [{type: 'Assurances', id: 'LIST'}],
        })
    })
})

export const {
    useGetAssurancesEvenementQuery
} = motosApiSlice