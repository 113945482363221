import {Divider, ListItemIcon, MenuItem, MenuList} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import {useEffect, useState} from "react";

const BlocMenu = (props) => {

    const {nom, prenom, selectedMenu, changeMenu} = props;

    const [nomPrenom, setNomPrenom] = useState('');

    const formatNomPrenom = (nom, prenom) => {
        let Nom = nom.charAt(0).toUpperCase().concat(nom.slice(1));
        let Prenom = prenom.charAt(0).toUpperCase().concat(prenom.slice(1));
        return Nom.concat(' ').concat(Prenom);
    };

    useEffect(() => {
        setNomPrenom(formatNomPrenom(nom, prenom));
    }, []);


    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{
                textAlign: 'center',
                pb: 1
            }}>
                <Typography variant="subtitle1" sx={{
                    fontWeight: "bold"
                }}>{nomPrenom}</Typography>
            </Box>
            <Divider variant="middle"/>
            <MenuList>
                <MenuItem selected={selectedMenu.infoPerso} onClick={() => changeMenu('infoPerso')}>
                    <ListItemIcon>
                        <PersonIcon fontSize="medium"/>
                    </ListItemIcon>
                    Information Personnelle
                </MenuItem>
                <MenuItem selected={selectedMenu.motDePasse} onClick={() => changeMenu('motDePasse')}>
                    <ListItemIcon>
                        <PasswordIcon fontSize="medium"/>
                    </ListItemIcon>
                    Mot de passe
                </MenuItem>
                <MenuItem disabled={true} selected={selectedMenu.historique}
                          onClick={() => changeMenu('historique')}>
                    <ListItemIcon>
                        <HistoryIcon fontSize="medium"/>
                    </ListItemIcon>
                    Mon historique
                </MenuItem>
            </MenuList>
        </Box>
    )
};

export default BlocMenu;