import * as Constants from "../../src/utils/ContantesDossier";

export default [
    {
        statut: Constants.STATUT_DOSSIER_INIT,
        desciption: 'Votre dossier a été créé sans que des informations y aient été ajoutées. Tant qu\'il reste dans cet état, le dossier ne sera pas considéré pour l\'événement.'
    }, {
        statut: Constants.STATUT_DOSSIER_IN_PROGRESS,
        desciption: 'Des informations ont été ajoutées au dossier. Avec cette mention, le dossier ne sera pas considéré pour l\'événement.'
    }, {
        statut: Constants.STATUT_DOSSIER_ATTENTE_VALIDATION,
        desciption: "Votre dossier indique qu'il a été soumis avec succès. Il est actuellement en cours de révision par notre équipe. Une fois validé, vous recevrez une notification par e-mail. Assurez-vous de vérifier également votre dossier de courriers indésirables/spam. Nous vous remercions de votre patience et de votre compréhension."
    }, {
        statut: Constants.STATUT_DOSSIER_VALIDER,
        desciption: "Votre dossier d'inscription a été examiné et validé par notre organisation. Cependant, cela ne garantit pas automatiquement votre participation à notre événement"
    }, {
        statut: Constants.STATUT_DOSSIER_INCOMPLET,
        desciption: "Nous avons examiné votre dossier d'inscription et constaté qu'il manque certaines informations essentielles. Afin de poursuivre le processus de validation, nous vous invitons à revoir et modifier votre dossier en vous assurant de fournir toutes les informations demandées. Une fois les modifications effectuées, veuillez soumettre à nouveau votre dossier pour validation. Nous vous remercions de votre coopération et de votre compréhension."
    }, {
        statut: Constants.STATUT_DOSSIER_CONFIRMER,
        desciption: "Votre dossier d'inscription a été examiné avec succès et est maintenant confirmé par notre organisation. Pour finaliser votre dossier et sécuriser votre place, il est essentiel d'effectuer le paiement requis. Vous avez reçu un e-mail ou un SMS avec les détails de paiement"
    }, {
        statut: Constants.STATUT_DOSSIER_ATTENTE,
        desciption: "Votre dossier d'inscription a été validé. Cependant, du fait d'un grand nombre de demandes, la série est actuellement complète. Votre dossier est donc en liste d'attente. Si une place se libère en raison d'un désistement, nous vous en informerons pour que vous puissiez intégrer la série. Merci de votre patience"
    }, {
        statut: Constants.STATUT_DOSSIER_ERREUR_PAIEMENT,
        desciption: "Une erreur s'est produite lors de la tentative de paiement. Nous vous invitons à vérifier vos informations bancaires ou à essayer un autre moyen de paiement. Si le problème persiste, veuillez nous contacter pour obtenir de l'aide. Il est important de résoudre cette erreur rapidement pour garantir la validité de votre inscription."
    }, {
        statut: Constants.STATUT_DOSSIER_PAYER,
        desciption: "Nous avons bien reçu votre paiement. Un e-mail de confirmation de paiement vous a été envoyé. Quelques semaines avant l'événement, vous recevrez un mail concernant la validation administrative. Nous vous remercions pour votre engagement et sommes impatients de vous accueillir lors de l'événement."
    }, {
        statut: Constants.STATUT_DOSSIER_REFUSER,
        desciption: "Nous regrettons de vous informer que votre dossier d'inscription n'a pas été retenu. Nous vous remercions de l'intérêt que vous portez à notre organisation."
    }, {
        statut: Constants.STATUT_DOSSIER_PRESENT,
        desciption: "Votre dossier d'inscription a été vérifié et validé lors de la validation administrative à l'événement. Votre présence est désormais confirmée. Nous vous souhaitons une expérience enrichissante et agréable lors de notre événement."
    },{
        statut: Constants.STATUT_DOSSIER_ANNULE,
        desciption: "Votre dossier d'inscription a été annulé suite à votre demande. Si un remboursement est effectué, vous pourrez consulter les détails de ce remboursement directement sur votre carte d'inscription"
    },{
        statut: Constants.STATUT_DOSSIER_ABANDON,
        desciption: "Suite à un incident survenu lors de l'événement, le pilote n'a pas été en mesure de poursuivre. Le dossier d'inscription est donc marqué comme \"abandonné\". Nous espérons que tout va bien et restons à votre disposition pour toute assistance ou information complémentaire."
    },
]