import {
    Alert,
    Card,
    CardContent,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {setLibelleGroupe} from "../../feature/inscription/inscriptionSlice";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import formUtils from "../../utils/formUtils";

export default (props) => {

    const [errorField, setErrorField] = useState({
        error: false, message: ''
    });

    useEffect(() => {
        const {errorField} = props;
        setErrorField({
            ...errorField,
            error: errorField["input-text-groupe-pilote"].error,
            message: errorField["input-text-groupe-pilote"].message
        })
    }, [props.errorField]);

    const dispatch = useDispatch();
    const sliceInscription = useSelector((state) => state.inscription);

    const handleChangeNomGroupe = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            dispatch(setLibelleGroupe(event.target.value));
        }
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Alert severity="warning">Attention de bien mettre un nom identique a celui de vos amis</Alert>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="input-text-groupe-pilote"
                            required
                            fullWidth
                            label="Nom du groupe"
                            value={sliceInscription.groupe.libelle}
                            onChange={handleChangeNomGroupe}
                            error={errorField.error}
                            helperText={errorField.error ? errorField.message : ''}
                            disabled={props.disable}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};