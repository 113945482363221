import {apiSlice} from "../../app/api/apiSlice"
const urlPaiement = "paiement";

export const paiementApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Paiement'],
    endpoints: builder => ({
        getPublicKey: builder.query({
            query: () => ({
                url: `${urlPaiement}/config`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
        }),
        getFacture: builder.query({
            query: (factureID) => ({
                url: `${urlPaiement}/facture/${factureID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
        }),
        confirmPaiement: builder.mutation({
            query: (params) => {
                const { idfacture, clientsecret, paiementid, statutpaiement } = params;
                return {
                    url: `${urlPaiement}/confirm-paiement`,
                    method: 'POST',
                    body: {
                        idfacture: idfacture,
                        clientsecret: clientsecret,
                        paiementid: paiementid,
                        statutpaiement: statutpaiement,
                    }
                }
            },
        }),
        errorPaiement: builder.mutation({
            query: (params) => {
                const { idfacture } = params;
                return {
                    url: `${urlPaiement}/error-paiement`,
                    method: 'POST',
                    body: {
                        idfacture: idfacture
                    }
                }
            },
        }),

    })
})

export const {
    useGetPublicKeyQuery,
    useGetFactureQuery,
    useConfirmPaiementMutation,
    useErrorPaiementMutation,
} = paiementApiSlice