import {Fragment, useEffect, useMemo, useState} from "react";
import Container from "@mui/material/Container";
import {Paper} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import OkMailConfirm from "../../file/picture/confirm-mail-ok.png";
import KoMailConfirm from "../../file/picture/confirm-mail-ko.png";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useConfirmPaiementMutation} from "../../feature/paiement/paiementApiSlice";

const theme = createTheme();

const PaiementSucess = (props) => {

    const [email, setEmail] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const {search} = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    const navigate = useNavigate();

    const {factureID} = useParams();

    const [confirmPaiement] = useConfirmPaiementMutation();

    useEffect(() => {
        const confirmPaiementRequest = async () => {
            const idfacture = factureID;
            const clientsecret = query.get('payment_intent_client_secret');
            const paiementid = query.get('payment_intent');
            const statutpaiement = query.get('redirect_status');

            if (idfacture === null || clientsecret === null || paiementid === null || statutpaiement === null) {
                navigate('/home');
            } else {
                await confirmPaiement({
                    idfacture: idfacture,
                    clientsecret: clientsecret,
                    paiementid: paiementid,
                    statutpaiement: statutpaiement
                }).unwrap()
                    .then(data => {
                        const {adressemail} = data;
                        setEmail(adressemail);
                        setIsLoad(true);
                    })
                    .catch(errorConfirm => {
                        if (errorConfirm.status === 406 || errorConfirm.status === 403) {
                            setError(true);
                            setErrorMessage('Erreur merci de contacter le gestionnnaire des inscriptions');
                            setIsLoad(true);
                        } else if (errorConfirm.status === 404) {
                            navigate('/home');
                        } else {
                            setError(true);
                            setErrorMessage('Erreur technique merci de réessayer ultérieurement');
                            setIsLoad(true);
                        }
                    })
            }
        }
        confirmPaiementRequest();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Paper elevation={24} sx={{
                    pl: 2,
                    pr: 2,
                }}>
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {isLoad &&
                            <Fragment>
                                <Typography paddingTop={2} component="h1" variant="h5" textAlign="center"
                                            marginBottom={2}>
                                    {!error ? 'Paiement réussi' : 'Paiement échoué'}
                                </Typography>
                                <img src={!error ? OkMailConfirm : KoMailConfirm} alt={"OK"} style={{
                                    width: 100,
                                    marginBottom: 20
                                }}/>
                                <Box sx={{
                                    pl: 2,
                                    pr: 2,
                                    pb: 2,
                                    textAlign: 'center'
                                }}>
                                    <Typography variant="subtitle1" fontSize={15} color={"gray"}>
                                        {!error ? `Merci, votre paiement a été effectué avec succès. Un email de confirmation a été envoyé à l'adresse suivante : ${email}` :
                                            errorMessage}
                                    </Typography>
                                </Box>
                            </Fragment>
                        }
                    </Box>
                </Paper>
            </Container>
        </ThemeProvider>
    )
};
export default PaiementSucess;