import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useCallback, useRef, useState} from "react";
import {Alert, CircularProgress, Paper} from "@mui/material";

const theme = createTheme();

const SendLinkNewPassword = () => {

    const emailInputRef = useRef();


    const [validEmail, setValidEmail] = useState(false);
    const [isTouchEmail, setIsTouchEmail] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [Error, setError] = useState(false);
    const [isMailSend, setisMailSend] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        // validation du formulaire
        emailOnChange();
        if (!isTouchEmail) {
            setIsTouchEmail(true);
        }
        if (validEmail) {
            setIsSubmit(true);
            await SendMailLinkNewPassword();
        }
    };

    const VerifEmailBlur = () => {
        setIsTouchEmail(true);
    };

    const emailOnChange = () => {
        const enteredEmail = emailInputRef.current.value;
        // vérification du format de l'email
        if (enteredEmail.trim().length > 0) {
            setValidEmail(true);
        } else {
            if (validEmail) {
                setValidEmail(false);
            }
        }
    };

    const SendMailLinkNewPassword = useCallback(async () => {
        setError(false);
        setisMailSend(false);
        setInvalidEmail(false);
        setIsLoading(true);
        try {
            const enteredEmail = emailInputRef.current.value;
            const responseEmailExist = await fetch(`${process.env.REACT_APP_API_URL}/auth/password-reset/`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    email: enteredEmail
                })
            });
            if (responseEmailExist.status === 400) {
                setInvalidEmail(true);
                setIsLoading(false);
                return;
            }

            if (!responseEmailExist.ok) {
                setIsLoading(false);
                new Error('Impoosible to send mail of new password');
            } else {
                setisMailSend(true);
            }
            setIsLoading(false);

        } catch (e) {
            setError(true);
            setIsLoading(false);
            console.log(e);
        }
    }, []);

    const ValidEmail = !validEmail && isTouchEmail;

    const HandlerInvalidLogin = invalidEmail && isSubmit;
    const HandlerValidSendMailPassword = isMailSend && isSubmit;

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Paper elevation={24} sx={{
                    pl: 2,
                    pr: 2,
                }}>
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography paddingTop={2} component="h1" variant="h5">
                            Mot de passe oublié
                        </Typography>
                        <Typography paddingTop={2} textAlign={"center"} variant="body1"
                                    paddingLeft={2} paddingRight={2}>
                            Saisissez l'adresse email que vous utilisez habituellement pour vous connecter
                        </Typography>
                        <Typography paddingLeft={2} paddingRight={2} paddingTop={2} textAlign={"center"}
                                    fontSize={15} color={"gray"}>
                            Un email vous sera envoyé pour réinitialiser votre mot de passe
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 2}}>
                            {isLoading && <CircularProgress color="success"/>}
                            {Error && <Alert sx={{width: '100%'}} severity="error">Erreur Interne</Alert>}
                            {HandlerInvalidLogin &&
                                <Alert sx={{width: 1}} severity="error">Compte non existant</Alert>}
                            {HandlerValidSendMailPassword &&
                                <Alert sx={{width: 1}} severity="success">Liens envoyer sur votre adresse
                                    mail</Alert>}
                            <TextField
                                margin="normal"
                                fullWidth
                                id="email"
                                label="e-mail"
                                name="email"
                                autoComplete="email"
                                inputRef={emailInputRef}
                                onBlur={VerifEmailBlur}
                                onChange={emailOnChange}
                                helperText={ValidEmail ? 'format e-mail invalide' : ''}
                                error={ValidEmail}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Recevoir l'email
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}

export default SendLinkNewPassword;