import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    Alert,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {green} from "@mui/material/colors";
import {useLoginMutation} from "../../feature/auth/authApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentToken, setCredentials} from "../../feature/auth/authSlice";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://google.com/">
                Trofeo Rosso
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const Signin = () => {

    const [login] = useLoginMutation();
    const dispatch = useDispatch();

    const connected = useSelector(selectCurrentToken);
    const navigate = useNavigate();

    const emailInputRef = useRef();


    const [validEmail, setValidEmail] = useState(false);
    const [isTouchEmail, setIsTouchEmail] = useState(false);
    const [isTouchPassword, setIsTouchPassword] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [invalidEmailPassword, setInvalidEmailPassword] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [Error, setError] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [motDePasse, setMotDePasse] = useState('');


    useEffect(() => {
        if (connected) {
            navigate('/home');
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        // validation du formulaire
        setInvalidEmailPassword(false);
        setError(false);
        passwordOnChange(motDePasse);
        emailOnChange();
        if (!isTouchEmail) {
            setIsTouchEmail(true);
        }
        if (!isTouchPassword) {
            setIsTouchPassword(true);
        }
        if (validEmail && validPassword) {
            setIsSubmit(true);
            await loginCall();
        }
    };

    const loginCall = async () => {

        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = motDePasse;
        setIsLoading(true);

        try {
            const userData = await login({email: enteredEmail, password: enteredPassword}).unwrap();
            dispatch(setCredentials({...userData}));
            navigate('/home');
            setIsLoading(false);
        } catch (e) {
            if (e.status === 401) {
                setInvalidEmailPassword(true);
            } else {
                setError(true);
            }
            setIsLoading(false);
        }
    };

    const VerifEmailBlur = () => {
        setIsTouchEmail(true);
    };

    const emailOnChange = () => {
        const enteredEmail = emailInputRef.current.value;
        // vérification du format de l'email
        if (enteredEmail.trim().length > 0) {
            setValidEmail(true);
        } else {
            if (validEmail) {
                setValidEmail(false);
            }
        }
    };

    const VerifPasswordBlur = () => {
        setIsTouchPassword(true);
    };

    const passwordOnChange = (password) => {
        const enteredPassword = password;
        if (enteredPassword.trim().length > 6) {
            setValidPassword(true);
        } else {
            if (validPassword) {
                setValidPassword(false);
            }
        }
    };

    const handleChangePassword = (event) => {
        const enteredPassword = event.target.value;
        setMotDePasse(enteredPassword);
        passwordOnChange(enteredPassword);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const ValidEmail = !validEmail && isTouchEmail;

    const ValidPassword = !validPassword && isTouchPassword;

    const HandlerInvalidLogin = invalidEmailPassword && isSubmit;

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: green[600]}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Connexion
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        {Error && <Alert sx={{width: '100%'}} severity="error">Erreur Interne, réessayer ultérieurement</Alert>}
                        {HandlerInvalidLogin &&
                            <Alert sx={{width: '100%'}} severity="error">e-mail ou mot de passe invalide</Alert>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="e-mail"
                            name="email"
                            autoComplete="email"
                            inputRef={emailInputRef}
                            onBlur={VerifEmailBlur}
                            onChange={emailOnChange}
                            helperText={ValidEmail ? 'format e-mail invalide' : ''}
                            error={ValidEmail}
                        />
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel required error={ValidPassword}>Mot de passe</InputLabel>
                            <OutlinedInput
                                required
                                error={ValidPassword}
                                id="mot-de-passe"
                                type={showPassword ? 'text' : 'password'}
                                value={motDePasse}
                                autoComplete='current-password'
                                onChange={handleChangePassword}
                                onBlur={VerifPasswordBlur}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Mot de passe"/>
                            <FormHelperText id="outlined-error-password"
                                            error={ValidPassword}>{ValidPassword ? 'Le mot de passe doit faire au moins 6 caractères' : ''}</FormHelperText>
                        </FormControl>

                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Connexion
                        </LoadingButton>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/lostpassword" variant="body2">
                                    Mot de Passe oublié?
                                </Link>
                            </Grid>
                            <Grid item xs>
                                <Link href="/signup" variant="body2">
                                    {"Création de compte"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}

export default Signin;