import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Dialog, DialogContent, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import Inscription from "./Inscription";
import Benevole from "./Benevole";
import {Fragment, useState} from "react";
import QRCodeCanvas from "qrcode.react";
import {useGetBenevoleEvenementQuery, useGetInscriptionEvenementQuery} from "../../feature/evenement/evenementApiSlice";
import moment from "moment";
import 'moment/locale/fr';
import ModalRemboursement from "./ModalRemboursement";

const Evenement = (props) => {

    const evenement = props.evenement;

    const {
        data: dossierBenevole,
        isSuccess: SucessDossierBenevole,
        isError: isErreurDossierBenevole,
    } = useGetBenevoleEvenementQuery(evenement.id);

    const {
        data: dossierInsciption,
        isSuccess: SucessDossierInsciption,
        isError: isErreurDossierInsciption,
    } = useGetInscriptionEvenementQuery(evenement.id);

    const [openQrCode, setOpenQrCode] = useState(false);
    const [dataQrCode, setDataQrCode] = useState('');

    const [modalRemboursement, setModalRemboursement] = useState({
        open: false,
        data: null
    });

    const handleError = (value) => {
        props.isError(value);
    };

    const handleOpenQrCode = (dataQrCode) => {
        setDataQrCode(dataQrCode);
        setOpenQrCode(true);
    };

    const handleOpenModalRemboursement = (data) => {
        setModalRemboursement({...modalRemboursement, open: true, data: data});
    };
    const handleCloseModalRemboursement = () => {
        setModalRemboursement({...modalRemboursement, open: false});
    };

    return (
        <Grid item xs={12} sm={12}>
            {(SucessDossierBenevole && SucessDossierInsciption) && <Box>
                <Typography variant="h6">
                    {evenement.libelle} ( {moment(evenement.date).locale('fr').format('LL')} )
                </Typography>
                <Divider/>
                {evenement.rouleur &&
                    <Fragment>
                        <Divider sx={{
                            marginTop: 3
                        }}>INSCRIPTION</Divider>
                        <Grid container spacing={1.5} sx={{
                            marginTop: 1
                        }}>
                            <Inscription evenement={evenement}
                                         insciptions={dossierInsciption}
                                         openQrCode={handleOpenQrCode}
                                         openRemboursement={handleOpenModalRemboursement}
                                         isError={handleError}/>
                        </Grid>
                    </Fragment>}
                {evenement.benevole &&
                    <Fragment>
                        <Divider sx={{
                            marginTop: 3
                        }}>BENEVOLE</Divider>
                        <Grid container spacing={2} sx={{
                            marginTop: 1
                        }}>
                            <Benevole evenement={evenement}
                                      benevoles={dossierBenevole}
                                      openQrCode={handleOpenQrCode}
                                      isError={handleError}/>
                        </Grid>
                    </Fragment>
                }
                <Dialog onClose={() => setOpenQrCode(false)} open={openQrCode}>
                    <DialogContent>
                        <QRCodeCanvas value={`${dataQrCode}`} size={200} level="H"/>
                    </DialogContent>
                </Dialog>
                {modalRemboursement.open &&
                    <ModalRemboursement
                        close={handleCloseModalRemboursement}
                        data={modalRemboursement.data}/>}
            </Box>}
        </Grid>
    )
};

export default Evenement;