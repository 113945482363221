import {apiSlice} from "../../app/api/apiSlice"

const urlUtilisateur = "user";

export const utilisateurApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Utilisateur'],
    endpoints: builder => ({
        getUserInfoVerifAccount: builder.query({
            query: (userID) => ({
                url: `${urlUtilisateur}/verifaccount/${userID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: () =>
                [{type: 'confirmMail', id: 'UNIQUE'}]
        }),
        getUserInfo: builder.query({
            query: (userID) => ({
                url: `${urlUtilisateur}/info/${userID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: () =>
                [{type: 'userInfo', id: 'UNIQUE'}]
        }),
        updateUserInfo: builder.mutation({
            query: (obj) => ({
                url: `${urlUtilisateur}/info/${obj.userID}`,
                method: 'PUT',
                body: {
                    nom: obj.data.nom,
                    prenom: obj.data.prenom,
                    adresse: obj.data.adresse,
                    codepostale: obj.data.codePostale,
                    ville: obj.data.ville,
                    telephone: obj.data.numeroTelephone,
                    email: obj.data.mail,
                    datenaissance: obj.data.dateNaissance,
                    id_pays: obj.data.pays,
                    id_langue: obj.data.langue,
                    id_motoclub: obj.data.motoClub.length === 0 ? null : obj.data.motoClub
                }
            }),
            invalidatesTags: [{type: 'confirmMail', id: 'UNIQUE'}, {type: 'userInfo', id: 'UNIQUE'}],
        }),
        updatePassword: builder.mutation({
            query: (obj) => ({
                url: `${urlUtilisateur}/update-password`,
                method: 'POST',
                body: {
                    ancienmotdepasse: obj.ancienMotDePasse,
                    nouveaumotdepasse: obj.nouveauMotDePasse
                }
            }),
        }),
    })
})

export const {
    useGetUserInfoVerifAccountQuery,
    useGetUserInfoQuery,
    useUpdateUserInfoMutation,
    useUpdatePasswordMutation,
} = utilisateurApiSlice