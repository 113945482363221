import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useSendConfirmMailMutation} from "../../feature/auth/authApiSlice";

const AlertMail = (props) => {

    const [sendConfirmMail] = useSendConfirmMailMutation();

    const handleSendConfirmMail = async () => {
        await sendConfirmMail().unwrap();
    };

    return (
        <Grid container justifyContent="center" sx={{
            backgroundColor: "red"
        }} columns={{xs: 4, sm: 8, md: 12}}>
            <Grid item>
                <Grid container spacing={1} alignItems="center" sx={{
                    paddingTop: 0.5,
                    paddingBottom: 0.5
                }}>
                    <Grid item>
                        <Typography variant="body2" sx={{
                            color: "white"
                        }} gutterBottom>
                            Adresse-mail non confirmée , pour renvoyer un mail cliquer-ici :
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="inherit" onClick={handleSendConfirmMail} sx={{
                            color: 'white'
                        }} size="small">Envoyer mail</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
        ;
}
export default AlertMail;