const dayjs = require("dayjs");
const verifEmptyEntry = (entry) => {
    if (typeof entry !== 'number') {
        return entry.trim().length === 0;
    } else
        return false;
};

const verifAlpha = (value) => {
    const regex = new RegExp("^[a-zA-Z0-9\\\\,\\\\@\\\\+\\\\/\\\\:\\\\.\\\\'\\\\-\\\\\n ]*$");
    return regex.test(value);
};

const verifEmailFormat = (email) => {
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

const verifNumerique = (value) => {
    const regex = new RegExp("^[0-9]*$");
    return regex.test(value);
};

const verifErreurFormulaire = (stateFormError) => {
    let arrayError = [];
    for (let i in stateFormError) {
        if (stateFormError[i]) {
            arrayError.push(true);
        }
    }
    return arrayError.length === 0;
};

const passwordPolicieRegexLowerCase = () => {
    return /[a-z]+/;
};

const passwordPolicieRegexUpperCase = () => {
    return /[A-Z]+/;
};

const passwordPolicieRegexNumber = () => {
    return /[1-9]+/;
};

const passwordPolicieLenght = () => {
    return 8;
};

const verifPasswordGlobalPolicie = (password) => {

    const lowerCase = passwordPolicieRegexLowerCase().test(password);
    const upperCase = passwordPolicieRegexUpperCase().test(password);
    const number = passwordPolicieRegexNumber().test(password);
    const lenght = String(password).length >= passwordPolicieLenght();

    return lowerCase && upperCase && number && lenght;
};

const isNotNegative = (number) => {
    return number >= 0;
}

const verifEmptyEntryDateDaysJs = (entry) => {
    return !dayjs(entry).isValid();
};

module.exports = {
    verifEmptyEntry,
    verifAlpha,
    verifErreurFormulaire,
    verifNumerique,
    verifEmailFormat,
    passwordPolicieRegexLowerCase,
    passwordPolicieRegexUpperCase,
    passwordPolicieRegexNumber,
    passwordPolicieLenght,
    verifPasswordGlobalPolicie,
    isNotNegative,
    verifEmptyEntryDateDaysJs
}