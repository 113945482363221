import * as React from "react";
import {Fragment, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ValidationPassword from "../sign-up/ValidationPassword";
import {useUpdatePasswordMutation} from "../../feature/utilisateur/utilisateurApiSlice";
import {LoadingButton} from "@mui/lab";
import {FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default (props) => {

    const [motDePasse, setMotDePasse] = useState({
        ancienMotDePasse: '',
        nouveauMotDePasse: '',
        confirmerMotDePasse: ''
    });
    const [error, setError] = useState({
        ancienMotDePasse: {
            error: false,
            message: ''
        },
        nouveauMotDePasse: {
            error: false,
            message: ''
        },
        confirmerMotDePasse: {
            error: false,
            message: ''
        },
    });
    const [snackBar, setSnackBar] = useState({
        success: {
            open: false,
            message: ''
        },
        error: {
            open: false,
            message: ''
        }
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordOld, setShowPasswordOld] = useState(false);

    const [showValidPassword, setShowValidPassword] = useState(false);

    const [updatePassword, {isLoading: isLoadingUpdatePassword}] = useUpdatePasswordMutation();


    const handleModifierMotDePasse = (value, field) => {
        setMotDePasse({
            ...motDePasse,
            ancienMotDePasse: field === 'ancienMotDePasse' ? value : motDePasse.ancienMotDePasse,
            nouveauMotDePasse: field === 'nouveauMotDePasse' ? value : motDePasse.nouveauMotDePasse,
            confirmerMotDePasse: field === 'confirmerMotDePasse' ? value : motDePasse.confirmerMotDePasse,
        })
    }

    const validerMotDePasse = () => {
        let newErrorField = {...error};
        let isFormValid = true;

        const setErrorForField = (field, isEmpty, message) => {
            newErrorField[field].error = isEmpty;
            newErrorField[field].message = isEmpty ? message : "";
        };

        // Vérification des champs requis
        const fields = [
            {key: 'ancienMotDePasse', message: 'Renseigner votre ancien mot de passe'},
            {key: 'nouveauMotDePasse', message: 'Renseigner votre nouveau mot de passe'},
            {key: 'confirmerMotDePasse', message: 'Renseigner votre nouveau mot de passe de confirmation'}
        ];

        fields.forEach(({key, message}) => {
            setErrorForField(key, motDePasse[key].length === 0, message);
            if (motDePasse[key].length === 0) isFormValid = false;
        });

        if (isFormValid && motDePasse.nouveauMotDePasse !== motDePasse.confirmerMotDePasse) {
            setErrorForField('confirmerMotDePasse', true, "Les mots de passe ne sont pas identiques");
            isFormValid = false;
        }

        setError(newErrorField);
        return isFormValid;
    };

    const handleSauvegarderNoveauMotDePasse = async () => {
        if (validerMotDePasse()) {
            await updatePassword({...motDePasse}).unwrap()
                .then(() => {
                    handleOpenCloseSucessSnackBar(true, 'Mot de passe mise à jour avec succès !');
                    setMotDePasse({...motDePasse, ancienMotDePasse: '', confirmerMotDePasse: '', nouveauMotDePasse: ''});
                })
                .catch((errorCatch) => {
                    if (errorCatch.status === 406) {
                        setError({...error, ancienMotDePasse: {error: true, message: 'Ancien Mot de passe invalide'}});
                    } else {
                        handleOpenCloseErrorSnackBar(true, 'Erreur technique veuillez ressayer ultérieurement');
                    }
                })
        }
    }

    const handleOpenCloseSucessSnackBar = (choice, message) => {
        setSnackBar({...snackBar, success: {open: choice, message: message}});
    }
    const handleOpenCloseErrorSnackBar = (choice, message) => {
        setSnackBar({...snackBar, error: {open: choice, message: message}});
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowPasswordOld = () => {
        setShowPasswordOld(!showPasswordOld);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom textAlign="center" sx={{fontWeight: 'bold'}}>
                        Changer mot de passe
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel required error={error.ancienMotDePasse.error}>Ancien mot de
                                    passe</InputLabel>
                                <OutlinedInput
                                    required
                                    error={error.ancienMotDePasse.error}
                                    name="AncienMotDePasse"
                                    type={showPasswordOld ? 'text' : 'password'}
                                    value={motDePasse.ancienMotDePasse}
                                    onChange={(event) => handleModifierMotDePasse(event.target.value, 'ancienMotDePasse')}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPasswordOld}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPasswordOld ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Ancien mot de passe"/>
                                {error.ancienMotDePasse.error && <FormHelperText
                                    error={error.ancienMotDePasse.error}>{error.ancienMotDePasse.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {showValidPassword && <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={4} sm={6}>
                            <ValidationPassword
                                password={motDePasse.nouveauMotDePasse}/>
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel required error={error.nouveauMotDePasse.error}>Nouveau mot de
                                    passe</InputLabel>
                                <OutlinedInput
                                    required
                                    error={error.nouveauMotDePasse.error}
                                    name="NouveauMotDePasse"
                                    type={showPassword ? 'text' : 'password'}
                                    value={motDePasse.nouveauMotDePasse}
                                    onChange={(event) => handleModifierMotDePasse(event.target.value, 'nouveauMotDePasse')}
                                    onFocus={() => setShowValidPassword(true)}
                                    onBlur={() => setShowValidPassword(false)}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Nouveau mot de passe"/>
                                {error.nouveauMotDePasse.error && <FormHelperText
                                    error={error.nouveauMotDePasse.error}>{error.nouveauMotDePasse.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel required error={error.confirmerMotDePasse.error}>Confirmer nouveau mot de
                                    passe</InputLabel>
                                <OutlinedInput
                                    required
                                    error={error.confirmerMotDePasse.error}
                                    name="NouveauMotDePasse"
                                    type={showPassword ? 'text' : 'password'}
                                    value={motDePasse.confirmerMotDePasse}
                                    onChange={(event) => handleModifierMotDePasse(event.target.value, 'confirmerMotDePasse')}
                                    onFocus={() => setShowValidPassword(true)}
                                    onBlur={() => setShowValidPassword(false)}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Confirmer nouveau mot de passe"/>
                                {error.confirmerMotDePasse.error && <FormHelperText
                                    error={error.confirmerMotDePasse.error}>{error.confirmerMotDePasse.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} sx={{mt: 2}}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <LoadingButton loading={isLoadingUpdatePassword} onClick={handleSauvegarderNoveauMotDePasse}
                                           fullWidth variant="contained">
                                Changer mot de passe</LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={snackBar.success.open} autoHideDuration={5000}
                      onClose={() => handleOpenCloseSucessSnackBar(false, '')}>
                <Alert onClose={() => handleOpenCloseSucessSnackBar(false, '')} severity="success" sx={{width: '100%'}}>
                    {snackBar.success.message}
                </Alert>
            </Snackbar>
            <Snackbar open={snackBar.error.open} autoHideDuration={5000}
                      onClose={() => handleOpenCloseErrorSnackBar(false, '')}>
                <Alert onClose={() => handleOpenCloseErrorSnackBar(false, '')} severity="error" sx={{width: '100%'}}>
                    {snackBar.error.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}