import {apiSlice} from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Authentication'],
    endpoints: builder => ({
        allCountries: builder.query({
            query: () => ({
                url: `/pays/all`,
                method: 'GET'
            })
        }),
    })
})

export const {
    useAllCountriesQuery,
} = authApiSlice