import {Fragment} from "react";
import {Card, List, ListItem, ListItemText, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ListMotoBenevole = (props) => {

    const handleEditMoto = (event, params) => {
        props.editMoto(params);
    };

    const handleDeleteMoto = (event, params) => {
        props.removeMoto(params);
    };

    return (
        <Fragment>
            {props.listMoto.length > 0 &&
                <Card variant="outlined">
                    <List>
                        {props.listMoto.map(result => {
                            return <ListItem key={result.id} secondaryAction={
                                <Stack direction="row" spacing={2}>
                                    <IconButton edge="end" aria-label="edit" disabled={props.disable}
                                                onClick={event => handleEditMoto(event, result.id)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" value={result.id}
                                                disabled={props.disable}
                                                onClick={event => handleDeleteMoto(event, result.id)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Stack>}>
                                <ListItemText
                                    primary={`${result.marque} ${result.modele}`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Card>}
        </Fragment>
    )
};

export default ListMotoBenevole;