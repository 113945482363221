import {Fragment} from "react";
import {Checkbox, FormControlLabel, FormGroup, FormLabel} from "@mui/material";

const ListRepas = (props) => {

    let listRepas;

    const handleCheckRepas = (event, dispoID) => {
        const choice = { id: dispoID, choice: event.target.checked};
        props.selectRepas(choice);
    };

    if (props.data.repas.length > 0) {
        listRepas = props.data.repas.map(value => {
            return <FormControlLabel disabled={props.disable} key={value.id} control={<Checkbox checked={value.check} name={value.date} onClick={event => handleCheckRepas(event, value.id)}/>}
                                     label={value.date}/>
        })
    }

    return (
        <Fragment>
            <FormLabel component="legend">{`${props.data.libelle} ${props.data.prixpublic} €`}</FormLabel>
            <FormGroup>
                {listRepas}
            </FormGroup>
        </Fragment>
    )
};

export default ListRepas;