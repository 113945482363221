import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {
    Alert,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {MuiTelInput, matchIsValidTel} from "mui-tel-input";
import {useSelector} from "react-redux";
import {selectCurrentToken} from "../../feature/auth/authSlice";
import {useCreateAccountMutation} from "../../feature/auth/authApiSlice";
import {useAllCountriesQuery} from "../../feature/pays/paysApiSlice";
import {useAllLangueQuery} from "../../feature/langue/langueApiSlice";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import formUtils from "../../utils/formUtils";
import {useGetAllMotoClubQuery} from "../../feature/motoclub/motoClubApiSlice";
import ValidationPassword from "../../components/sign-up/ValidationPassword";

const theme = createTheme();

export default () => {

    const connected = useSelector(selectCurrentToken);

    const [createAccount] = useCreateAccountMutation();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [validEmail, setvalidEmail] = useState(true);
    const [numeroTelephone, setNumeroTelephone] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [motDePasse, setMotDePasse] = useState('');
    const [confirmMotDePasse, setConfirmMotDePasse] = useState('');
    const [technicalError, setTechnicalError] = useState(false);
    const [technicalErrorMessage, setTechnicalErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showValidPassword, setShowValidPassword] = useState(false);
    // error
    const [errorField, setErrorField] = useState({
        nom: {
            error: false, message: ''
        }, prenom: {
            error: false, message: ''
        }, dateNaissance: {
            error: false, message: ''
        }, numeroTelephone: {
            error: false, message: ''
        }, adresse: {
            error: false, message: ''
        }, codePostale: {
            error: false, message: ''
        }, ville: {
            error: false, message: ''
        }, email: {
            error: false, message: ''
        }, motDePasse: {
            error: false, message: ''
        }, confirmerMotDePasse: {
            error: false, message: ''
        }, pays: {
            error: false, message: ''
        }, langue: {
            error: false, message: ''
        }, motoClub: {
            error: false, message: ''
        },
    });

    const prenomInputRef = useRef();
    const nomInputRef = useRef();
    const dateNaissanceInputRef = useRef();
    const adresseInputRef = useRef();
    const codePostaleInputRef = useRef();
    const villeInputRef = useRef();
    const [emailCompte, setEmailCompte] = useState('');
    const [selectLangue, setSelectLangue] = useState('');
    const [selectPays, setSelectPays] = useState('');
    const [selectMotoClub, setSelectMotoClub] = useState('');
    const [listPays, setListPays] = useState([]);
    const [listLangue, setListLangue] = useState([]);
    const [listMotoClub, setListMotoClub] = useState([]);
    const [demarche, setDemarche] = useState(true);
    const [successCreate, setsuccessCreate] = useState(false);
    const [disableEmail, setDisableEmail] = useState(false);

    useEffect(() => {
        if (connected) {
            navigate('/home');
        }
        const email = searchParams.get('email');
        if (email !== null && formUtils.verifEmailFormat(email)) {
            setEmailCompte(email);
            setDisableEmail(true);
        }
    }, []);

    const {
        data: countries, isSuccess: SucessCountries, isError: isErreurCountries,
    } = useAllCountriesQuery();

    const {
        data: langue, isSuccess: SucessLangue, isError: isErreurLangue,
    } = useAllLangueQuery();

    const {
        data: motoclubs, isSuccess: SucessMotoclubs, isError: isErreurMotoclubs,
    } = useGetAllMotoClubQuery();

    useEffect(() => {
        if (langue !== null && SucessLangue) {
            const listItemLangue = langue.map(value => {
                return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
            })
            setListLangue(listItemLangue);
        }
        if (countries !== null && SucessCountries) {
            const listItemPays = countries.map(value => {
                return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
            })
            setListPays(listItemPays);
        }
        if (motoclubs !== null && SucessMotoclubs) {
            const listItemMotoClub = motoclubs.map(value => {
                return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
            })
            setListMotoClub(listItemMotoClub);
        }
    }, [langue, SucessLangue, countries, SucessCountries, SucessMotoclubs, motoclubs]);

    useEffect(() => {
        if (isErreurCountries || isErreurLangue) {
            navigate('/500');
        }
    }, [isErreurCountries, isErreurLangue, isErreurMotoclubs]);

    useEffect(() => {
        if (successCreate) {
            const timer = setTimeout(() => navigate('/login'), 2000);
            return () => clearTimeout(timer);
        }
    }, [successCreate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setTechnicalError(false);
        if (validFormulate()) {
            setIsLoading(true);
            setvalidEmail(true);
            await createAccount({
                prenom: nomInputRef.current.value,
                nom: prenomInputRef.current.value,
                adresse: adresseInputRef.current.value,
                codepostale: codePostaleInputRef.current.value,
                ville: villeInputRef.current.value,
                telephone: numeroTelephone.replace(/ /g, ''),
                email: emailCompte,
                datenaissance: dateNaissanceInputRef.current.value,
                password: motDePasse,
                passwordConfirmation: confirmMotDePasse,
                pays: selectPays,
                langue: selectLangue,
                motoclub: selectMotoClub === '' ? null : selectMotoClub,
                demarche: demarche
            }).unwrap()
                .then(() => {
                    setIsLoading(false);
                    setsuccessCreate(true);
                    scrollHeaderPage();
                })
                .catch((error) => {
                    if (error.status === 403) {
                        setvalidEmail(false);
                        setIsLoading(false);
                        scrollHeaderPage();
                    } else {
                        setTechnicalError(true);
                        setTechnicalErrorMessage('Erreur technique veuillez ressayer ultérieurement');
                        setIsLoading(false);
                    }
                })
        }
    };

    const scrollHeaderPage = () => {
        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    const validFormulate = () => {
        const fieldsToValidate = [{
            name: 'nom',
            value: nomInputRef.current.value,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'prenom',
            value: prenomInputRef.current.value,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'dateNaissance',
            value: dateNaissanceInputRef.current.value,
            validator: formUtils.verifEmptyEntry
        }, {name: 'numeroTelephone', value: numeroTelephone, validator: formUtils.verifEmptyEntry}, {
            name: 'adresse',
            value: adresseInputRef.current.value,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'codePostale',
            value: codePostaleInputRef.current.value,
            validator: formUtils.verifEmptyEntry
        }, {name: 'ville', value: villeInputRef.current.value, validator: formUtils.verifEmptyEntry}, {
            name: 'email',
            value: emailCompte,
            validator: formUtils.verifEmptyEntry
        }, {name: 'motDePasse', value: motDePasse, validator: formUtils.verifEmptyEntry}, {
            name: 'confirmerMotDePasse',
            value: confirmMotDePasse,
            validator: formUtils.verifEmptyEntry
        }, {name: 'pays', value: selectPays, validator: formUtils.verifEmptyEntry}, {
            name: 'langue',
            value: selectLangue,
            validator: formUtils.verifEmptyEntry
        },];
        let firstErrorFieldName = '';
        let newErrorField = {...errorField};
        let isFormValid = true;

        // Valider tous les champs qui ne doivent pas être vides
        fieldsToValidate.forEach(({name, value, validator}) => {
            const isFieldValid = !validator(value); // Nous utilisons "!" pour inverser la logique car "true" signifie ici "champ vide"
            newErrorField[name] = {
                error: !isFieldValid, message: isFieldValid ? '' : 'Champ Obligatoire'
            };
            if (!isFieldValid && !firstErrorFieldName) {
                firstErrorFieldName = name; // Enregistre le premier champ en erreur
                isFormValid = false; // Marque le formulaire comme invalide
            }
        });
        if (!isNaN(codePostaleInputRef.current.value) && formUtils.isNotNegative(codePostaleInputRef.current.value)) {
            newErrorField.codePostale = {
                error: false, message: ''
            };
        } else {
            newErrorField.codePostale = {
                error: true, message: "Code postale est négatif ou n'est pas un nombre."
            };
            isFormValid = false;
        }

        // Validation du numéro de téléphone
        const NumeroTelephoneNotEmpty = !formUtils.verifEmptyEntry(numeroTelephone);
        if (NumeroTelephoneNotEmpty) {
            const validPhoneNumber = matchIsValidTel(numeroTelephone);

            newErrorField.numeroTelephone = {
                error: !validPhoneNumber, message: !validPhoneNumber ? 'Format de numéro de téléphone invalide' : ''
            };
            if (!validPhoneNumber) {
                firstErrorFieldName = 'numeroTelephone';
                isFormValid = false;
            }
        }
        // Validation du mot de passe
        const isPasswordNotEmpty = !formUtils.verifEmptyEntry(motDePasse);
        const isPasswordPolicyValid = formUtils.verifPasswordGlobalPolicie(motDePasse);
        if (isPasswordNotEmpty && !isPasswordPolicyValid) {
            if (!firstErrorFieldName) {
                firstErrorFieldName = 'motDePasse';
            }
            newErrorField.motDePasse = {
                error: true, message: 'Format de mot de passe invalide'
            };
            isFormValid = false;
        } else {
            newErrorField.motDePasse = {
                error: false, message: ''
            };
        }

        // Validation de la confirmation du mot de passe
        const isConfirmPasswordNotEmpty = !formUtils.verifEmptyEntry(confirmMotDePasse);
        if (isConfirmPasswordNotEmpty && (motDePasse !== confirmMotDePasse)) {
            if (!firstErrorFieldName) {
                firstErrorFieldName = 'confirmerMotDePasse';
            }
            newErrorField.confirmerMotDePasse = {
                error: true, message: 'Les mots de passe ne correspondent pas'
            };
            isFormValid = false;
        } else {
            newErrorField.confirmerMotDePasse = {
                error: false, message: ''
            };
        }

        setErrorField(newErrorField);

        if (firstErrorFieldName) {
            const errorElement = document.querySelector(`[name="${firstErrorFieldName}"]`);
            if (errorElement) {
                errorElement.focus();
                errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }
        return isFormValid; // Retourne true si le formulaire est valide, false sinon
    };

    const handleChangePays = (event) => {
        setSelectPays(event.target.value);
    };

    const handleChangeLangue = (event) => {
        setSelectLangue(event.target.value);
    };

    const handleChangeMotoClub = (event) => {
        setSelectMotoClub(event.target.value);
    };

    const handleChangeNumeroTelephone = (event) => {
        setNumeroTelephone(event);
    };

    const handleChangePassword = (event) => {
        setMotDePasse(event.target.value);
    };

    const handleChangeConfirmPassword = (event) => {
        setConfirmMotDePasse(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleChangeDemarche = (event) => {
        setDemarche(event.target.checked)
    };

    return (<ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Création de compte
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {successCreate && <Alert severity="success">Compte crée avec succès</Alert>}
                            {!validEmail && <Alert severity="error">email deja utiliser</Alert>}
                            {technicalError && <Alert severity="error">{technicalErrorMessage}</Alert>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="prenom"
                                required
                                fullWidth
                                label="Prenom"
                                autoFocus
                                inputRef={prenomInputRef}
                                error={errorField.prenom.error}
                                helperText={errorField.prenom.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="Nom"
                                name="nom"
                                autoComplete="family-name"
                                inputRef={nomInputRef}
                                error={errorField.nom.error}
                                helperText={errorField.nom.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="dateNaissance"
                                label="Date de naissance"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputRef={dateNaissanceInputRef}
                                error={errorField.dateNaissance.error}
                                helperText={errorField.dateNaissance.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <MuiTelInput
                                fullWidth
                                label="Numero de Telephone"
                                name="numeroTelephone"
                                autoComplete="phone-number"
                                value={numeroTelephone}
                                onChange={handleChangeNumeroTelephone}
                                onlyCountries={['FR', 'BE', 'IT', 'ES', 'GB', 'CH', 'IE', 'NL', 'LU', 'PT']}
                                langOfCountryName="fr"
                                defaultCountry="FR"
                                forceCallingCode
                                error={errorField.numeroTelephone.error}
                                helperText={errorField.numeroTelephone.message}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Adresse"
                                name="adresse"
                                inputRef={adresseInputRef}
                                error={errorField.adresse.error}
                                helperText={errorField.adresse.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                inputProps={{
                                    min: "0"
                                }}
                                label="Code Postale"
                                name="codePostale"
                                type="number"
                                inputRef={codePostaleInputRef}
                                error={errorField.codePostale.error}
                                helperText={errorField.codePostale.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="Ville"
                                name="ville"
                                inputRef={villeInputRef}
                                error={errorField.ville.error}
                                helperText={errorField.ville.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Email"
                                name="email"
                                autoComplete={disableEmail ? '' : 'email'}
                                value={emailCompte}
                                error={errorField.email.error}
                                helperText={errorField.email.message}
                                disabled={disableEmail}
                                onChange={(event) => setEmailCompte(event.target.value)}
                            />
                        </Grid>
                        {showValidPassword && <Grid item xs={12}>
                            <ValidationPassword
                                password={motDePasse}/>
                        </Grid>}
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel required error={errorField.motDePasse.error}>Mot de
                                    passe</InputLabel>
                                <OutlinedInput
                                    required
                                    error={errorField.motDePasse.error}
                                    name="motDePasse"
                                    type={showPassword ? 'text' : 'password'}
                                    value={motDePasse}
                                    onChange={handleChangePassword}
                                    onFocus={() => setShowValidPassword(true)}
                                    onBlur={() => setShowValidPassword(false)}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Mot de passe"/>
                                {errorField.motDePasse.error && <FormHelperText id="outlined-error-password"
                                                                                error={errorField.motDePasse.error}>{errorField.motDePasse.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel required error={errorField.confirmerMotDePasse.error}>Confirmer mot
                                    de
                                    passe</InputLabel>
                                <OutlinedInput
                                    id="confirmerMotDePasse"
                                    required
                                    name="confirmerMotDePasse"
                                    error={errorField.confirmerMotDePasse.error}
                                    type={showPassword ? 'text' : 'password'}
                                    value={confirmMotDePasse}
                                    onChange={handleChangeConfirmPassword}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm-password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Confirmer mot de passe"/>
                                {errorField.confirmerMotDePasse.error && <FormHelperText id="outlined-error-password"
                                                                                         error={errorField.confirmerMotDePasse.error}>{errorField.confirmerMotDePasse.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel required error={errorField.pays.error}>Pays</InputLabel>
                                <Select
                                    required
                                    label="Pays"
                                    name="pays"
                                    value={selectPays}
                                    onChange={handleChangePays}
                                    error={errorField.pays.error}
                                >
                                    {listPays}
                                </Select>
                                {errorField.pays.error && <FormHelperText
                                    error={errorField.pays.error}>{errorField.pays.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel required error={errorField.langue.error}>Langue</InputLabel>
                                <Select
                                    required
                                    label="Langue"
                                    name="langue"
                                    value={selectLangue}
                                    onChange={handleChangeLangue}
                                    error={errorField.langue.error}
                                >
                                    {listLangue}
                                </Select>
                                {errorField.langue.error && <FormHelperText
                                    error={errorField.langue.error}>{errorField.langue.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel error={errorField.motoClub.error}>Moto
                                    club</InputLabel>
                                <Select
                                    label="Moto club"
                                    value={selectMotoClub}
                                    onChange={handleChangeMotoClub}
                                    error={errorField.motoClub.error}
                                >
                                    <MenuItem value={''}>Pas de moto club</MenuItem>
                                    {listMotoClub}
                                </Select>
                                {errorField.motoClub.error && <FormHelperText
                                    error={errorField.motoClub.error}>{errorField.motoClub.error}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={demarche} onChange={handleChangeDemarche}/>}
                                    label="Je ne souhaite pas être sollicité par les partenaires du Trofeo Rosso"/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        S'inscrire
                    </LoadingButton>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Vous avez déja un compte? Connexion
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 5}}>
                {'Copyright © '}
                <Link color="inherit" href="https://google.com/">
                    Trofeo Rosso
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Container>
    </ThemeProvider>);
}