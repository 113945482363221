import {apiSlice} from "../../app/api/apiSlice";
const urlExperience = "experience" ;

export const motosApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Experience'],
    endpoints: builder => ({
        getExperienceEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlExperience}/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
            providesTags: (result) =>
                // is result available?
                result ? [...result.map(({id}) => ({type: 'Experience', id})), {type: 'Experience', id: 'LIST'},] :
                    [{type: 'Experience', id: 'LIST'}],
        })
    })
})

export const {
    useGetExperienceEvenementQuery
} = motosApiSlice