import {createTheme, ThemeProvider} from "@mui/material/styles";
import {
    useGetEvenementQuery,
} from "../../feature/evenement/evenementApiSlice";
import {Fragment, useState, useEffect} from "react";
import Evenement from "../../components/home/Evenement";
import {reset} from "../../feature/inscription/inscriptionSlice";
import {useDispatch, useSelector} from "react-redux";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Box,
    Card,
    CardContent,
    CircularProgress,
    Divider, Snackbar
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ModalConfirmPhoneNumber from "../../components/home/ModalConfirmPhoneNumber";
import {useGetUserInfoVerifAccountQuery} from "../../feature/utilisateur/utilisateurApiSlice";
import {selectCurrentTokenPayload} from "../../feature/auth/authSlice";
import Typography from "@mui/material/Typography";
import StatutDossier from "../../components/home/StatutDossier";
import DescriptionStatutDossier from "../../utils/DescriptionStatutDossier";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as ConstanteNotification from "../../utils/ConstantesNotificationHomePage";

const theme = createTheme();

const HomePage = () => {

        const tokenPayload = useSelector(selectCurrentTokenPayload);
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(reset());
            if (localStorage.getItem('notification-home') !== null) {
                if (localStorage.getItem('notification-home') === ConstanteNotification.DOSSIER_PRISE_EN_COMPTE){

                }
                setOpenSnackBarMessage('Votre dossier a bien été prise en compte');
                setOpenSnackBar(true);
                localStorage.removeItem('notification-home');
            }
        }, []);

        const [isLoading, setIsLoading] = useState(false);
        const [technicalError, setTechnicalError] = useState(false);
        const [openVerifPhone, setOpenVerifPhone] = useState(false);

        const [openSnackBar, setOpenSnackBar] = useState(false);
        const [openSnackBarMessage, setOpenSnackBarMessage] = useState('');

        const {
            data: evenements,
            isLoading: isLoadingEvenement,
            isSuccess: SucessEvenement,
            isError: isErreurEvenement,
            error: errorEvenement
        } = useGetEvenementQuery();

        const {
            data: userVerif,
            isLoading: isLoadingUserVerif,
            isSuccess: SucessUserVerif,
            isError: isErreurUserVerif,
            error: errorUserVerif
        } = useGetUserInfoVerifAccountQuery(tokenPayload.userID);

        const SucessLoadAll = SucessEvenement && SucessUserVerif;
        const ErrorLoadAll = isErreurEvenement || isErreurUserVerif;

        useEffect(() => {
            if (SucessLoadAll && evenements != null) {
                setIsLoading(true);
            }

            if (ErrorLoadAll) {
                setIsLoading(true);
                setTechnicalError(true);
            }

            if (SucessLoadAll && userVerif != null) {
                if (userVerif.confirmphonenumber === null) {
                    setOpenVerifPhone(false);
                } else {
                    setOpenVerifPhone(!userVerif.confirmphonenumber);
                }
            }
        }, [evenements, SucessLoadAll, ErrorLoadAll, userVerif]);

        const handleErrorEvenement = (value) => {
            setTechnicalError(value);
        };

        const handleChangeOpenPhoneNumber = (value) => {
            setOpenVerifPhone(value);
        };

        const handleCloseSnackBar = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenSnackBar(false);
        };

        return (
            <Fragment>
                {isLoading ?
                    <ThemeProvider theme={theme}>
                        <ModalConfirmPhoneNumber open={openVerifPhone} changeOpen={handleChangeOpenPhoneNumber}/>
                        <Container component="main" maxWidth="md" sx={{
                            marginTop: 2,
                            marginBottom: 4
                        }}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        {technicalError &&
                                            <Alert severity="error">
                                                Erreur Technique, réessayer ultérieurement !
                                            </Alert>
                                        }
                                    </Grid>
                                    {SucessEvenement &&
                                        evenements.map(evenement => (
                                            <Evenement key={evenement.id} evenement={evenement}
                                                       isError={handleErrorEvenement}/>
                                        ))}
                                </Grid>
                            </Box>
                        </Container>
                    </ThemeProvider>
                    : <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={true}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                }
                <Container component="main" maxWidth="lg" sx={{
                    marginTop: 2,
                    marginBottom: 4
                }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Explication statut dossier</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Card variant="outlined">
                                <CardContent>
                                    {DescriptionStatutDossier.map((statut, index) => {
                                        return (
                                            <Fragment key={statut.statut}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} sm={4}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            flexDirection: 'row',
                                                            paddingTop: 0.5
                                                        }}>
                                                            <Card>
                                                                <StatutDossier statut={statut.statut}/>
                                                            </Card>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={8}>
                                                        <Typography variant="body1">{statut.desciption}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {DescriptionStatutDossier.length !== index + 1 &&
                                                    <Divider sx={{mb: 1.5, mt: 1.5}}/>}
                                            </Fragment>
                                        )
                                    })}
                                </CardContent>
                            </Card>
                        </AccordionDetails>
                    </Accordion>
                </Container>
                <Snackbar open={openSnackBar} autoHideDuration={10000} onClose={handleCloseSnackBar}>
                    <Alert onClose={handleCloseSnackBar} severity="success" sx={{width: '100%'}}>
                        {openSnackBarMessage}
                    </Alert>
                </Snackbar>
            </Fragment>
        )
    }
;

export default HomePage;