import {Alert, Card, CardContent} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useState, useEffect} from "react";

const GroupeBenevole = (props) => {

    const [nomGroupe, setNomGroupe] = useState('');

    const handleChangeNomGroupe = (event) => {
        props.changeGroupe(event.target.value);
    };

    useEffect( () => {
        setNomGroupe(props.nameGroupe);
    },[props.nameGroupe]);

    return(
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Alert severity="warning">Attention de bien mettre un nom identique a celui de vos amis</Alert>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="Nom du groupe"
                            required
                            fullWidth
                            id="Nom_du_groupe"
                            label="Nom du groupe"
                            value={nomGroupe}
                            onChange={handleChangeNomGroupe}
                            error={props.errorGroupe}
                            helperText={props.errorGroupe ? 'Veuillez renseigner le nom du groupe' : ''}
                            disabled={props.disable}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};
export default GroupeBenevole