import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Card, CardContent, Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Fragment} from "react";
import {
    useDeleteInscriptionMutation,
    useInitInscriptionMutation, useInitPaiementInscriptionMutation,
} from "../../feature/inscription/inscriptionApiSlice";
import {useNavigate} from "react-router-dom";
import ButtonInsciption from "./button/ButtonInsciption";
import StatutDossier from "./StatutDossier";
import {Alert, LoadingButton} from "@mui/lab";

const Inscription = (props) => {

    let dossier;

    const evenement = props.evenement;
    const navigate = useNavigate();

    const [initInscription, {isLoading: isInitInscription}] = useInitInscriptionMutation();
    const [deleteInscription] = useDeleteInscriptionMutation();
    const [initPaiementInscription, {isLoading: isInitPaiementInscription}] = useInitPaiementInscriptionMutation();

    const handleClickInitDossier = async () => {
        await initInscription(evenement.id).unwrap()
            .then(payload => {
                navigate(`/inscription/${payload.id_inscription}/${evenement.id}`);
            })
            .catch(() => {
                props.isError(true);
            });
    };
    const handleClickUpdateDossier = (idDossier) => {
        navigate(`/inscription/${idDossier}/${evenement.id}`);
    };
    const handleClickDeleteDossier = async (idDossier) => {
        await deleteInscription(idDossier).unwrap()
            .catch(() => {
                props.isError(true);
            })
    };
    const handleClickPayDossier = async (idDossier) => {
        initPaiementInscription(idDossier).unwrap()
            .then((data) => {
                let {factureID} = data;
                navigate(`/paiement/${factureID}`);
            })
            .catch(() => {
                props.isError(true);
            })
    };
    const handleOpenQrCode = (barCodeDossier) => {
        props.openQrCode(barCodeDossier);
    };
    const handleConsulterInscription = (idDossier) => {
        navigate(`/inscription/${idDossier}/${evenement.id}`);
    };
    const handleOpenModalRemboursement = (dataRemboursement) => {
        props.openRemboursement(dataRemboursement);
    };

    dossier = props.insciptions.map(val => {
        return (
            <Grid key={val.id} item xs={12} sm={6}>
                <Paper elevation={2} sx={{p: {xs: 2, md: 2}}}>
                    <Typography variant="subtitle1" align="center">
                        {val.barcode}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        paddingTop: 0.5
                    }}>
                        <Card>
                            <StatutDossier statut={val.statut}/>
                        </Card>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <ButtonInsciption
                            data={{
                                idInscription: val.id,
                                statut: val.statut,
                                barcode: val.barcode,
                                authorizedPaiement: val.paiement,
                                remboursement: val.remboursement
                            }}
                            suppresionInscription={handleClickDeleteDossier}
                            updateInscription={handleClickUpdateDossier}
                            payInscription={handleClickPayDossier}
                            showQrCode={handleOpenQrCode}
                            consulterInscription={handleConsulterInscription}
                            remboursementInscription={handleOpenModalRemboursement}
                            isLoadingPaiement={isInitPaiementInscription}
                        />
                    </Box>
                </Paper>
            </Grid>)
    });

    return (
        <Fragment>
            <Grid item xs={12} sm={12}>
                {new Date(evenement.datefininscription).getTime() >= new Date().getTime() &&
                    < Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <LoadingButton loading={isInitInscription} color="info" size="medium" variant="contained"
                                       sx={{ml: 1}}
                                       onClick={handleClickInitDossier}
                                       disabled={props.insciptions.length >= process.env.REACT_APP_MAX_INSCRIPTION}>
                            créer Dossier Inscription
                        </LoadingButton>
                    </Box>}
            </Grid>
            {dossier}
        </Fragment>
    )
};

export default Inscription;