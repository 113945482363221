import {Fragment} from "react";
import * as Constants from "../../../utils/ContantesDossier";
import Button from "@mui/material/Button";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {LoadingButton} from "@mui/lab";

const ButtonInsciption = (props) => {
    const {idInscription, statut, barcode, authorizedPaiement, remboursement} = props.data;
    let returnButton;
    const buttonConsultation =
        <Button size="small" variant="contained" sx={{mt: 1, ml: 1}}
                onClick={() => props.consulterInscription(idInscription)}>
            Consulter
        </Button>

    if (statut === Constants.STATUT_DOSSIER_INIT || statut === Constants.STATUT_DOSSIER_IN_PROGRESS) {
        returnButton =
            <Fragment>
                <Button color="error" size="small" variant="contained" onClick={() => {
                    props.suppresionInscription(idInscription)
                }} sx={{mt: 1, ml: 1}}>
                    Suppression
                </Button>
                <Button size="small" variant="contained" onClick={() => {
                    props.updateInscription(idInscription)
                }} sx={{mt: 1, ml: 1}}>
                    Modifier
                </Button>
            </Fragment>
    } else if (statut === Constants.STATUT_DOSSIER_ATTENTE_VALIDATION || statut === Constants.STATUT_DOSSIER_VALIDER || statut === Constants.STATUT_DOSSIER_ANNULE || statut === Constants.STATUT_DOSSIER_ATTENTE || statut === Constants.STATUT_DOSSIER_REFUSER) {
        returnButton =
            <Fragment>
                {buttonConsultation}
                {remboursement !== null &&
                    <Button color="success" size="small" variant="contained" onClick={() => {
                        props.remboursementInscription(remboursement)
                    }} sx={{mt: 1, ml: 1}}>
                        Remboursement
                    </Button>}
            </Fragment>
    } else if (statut === Constants.STATUT_DOSSIER_CONFIRMER || statut === Constants.STATUT_DOSSIER_ATTENTE_PAIEMENT || statut === Constants.STATUT_DOSSIER_ERREUR_PAIEMENT) {
        returnButton =
            <Fragment>
                {buttonConsultation}
                {authorizedPaiement && <Button size="small" variant="contained" onClick={() => {
                    props.payInscription(idInscription)
                }} sx={{mt: 1, ml: 1}}>
                    Payer
                </Button>}
            </Fragment>
    } else if (statut === Constants.STATUT_DOSSIER_PAYER) {
        returnButton =
            <Fragment>
                {buttonConsultation}
                <LoadingButton loading={props.isLoadingPaiement} size="small" variant="contained" sx={{mt: 1, ml: 1}}
                               onClick={() => {
                                   props.showQrCode(barcode)
                               }}>
                    <QrCode2Icon/>
                </LoadingButton>
            </Fragment>
    } else if (statut === Constants.STATUT_DOSSIER_INCOMPLET) {
        returnButton =
            <Fragment>
                <Button size="small" variant="contained" onClick={() => {
                    props.updateInscription(idInscription)
                }} sx={{mt: 1, ml: 1}}>
                    Modifier
                </Button>
            </Fragment>
    } else {
        returnButton =
            <Fragment>
                {buttonConsultation}
            </Fragment>
    }

    return (
        <Fragment>
            {returnButton}
        </Fragment>
    )
};

export default ButtonInsciption;