export const STATUT_DOSSIER_INIT = 'CRÉÉ' ;
export const STATUT_DOSSIER_IN_PROGRESS = 'EN COURS';
export const STATUT_DOSSIER_ATTENTE_VALIDATION = 'ATTENTE DE VALIDATION';
export const STATUT_DOSSIER_VALIDER = 'VALIDÉ';
export const STATUT_DOSSIER_CONFIRMER = 'CONFIRMÉ';
export const STATUT_DOSSIER_INCOMPLET = 'INCOMPLET';
export const STATUT_DOSSIER_ATTENTE = 'LISTE D\'ATTENTE';
export const STATUT_DOSSIER_ATTENTE_PAIEMENT = 'ATTENTE PAIEMENT';
export const STATUT_DOSSIER_ERREUR_PAIEMENT = 'ERREUR PAIEMENT';
export const STATUT_DOSSIER_PAYER = 'PAYÉ';
export const STATUT_DOSSIER_REFUSER = 'REFUSÉ';
export const STATUT_DOSSIER_PRESENT = 'PRESENT';
export const STATUT_DOSSIER_ANNULE = 'ANNULÉ';
export const STATUT_DOSSIER_ABANDON = 'ABANDONNÉ';