import {Chip} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import * as Constants from "../../utils/ConstantesRemboursement";
import Box from "@mui/material/Box";

export default (props) => {

    const [colorChip, setColorChip] = useState('info');
    const [load, setLoad] = useState(false);

    useEffect(() => {
        switch (props.statut) {
            case Constants.STATUT_REMBOURSEMENT_VALIDER :
                setColorChip('success');
                break;
            case Constants.STATUT_REMBOURSEMENT_ERREUR :
                setColorChip('error');
                break;
        }
        setLoad(true);
    }, [props.statut])

    return (
        <Grid justifyContent="center" container spacing={2}>
            <Grid item xs={12} lg={12} md={12}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    {load &&
                        <Chip color={colorChip} label={props.statut}/>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}