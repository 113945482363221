import {apiSlice} from "../../app/api/apiSlice"
const urlMotoCLub = "motoclub" ;

export const motoClubApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['MotoClub'],
    endpoints: builder => ({
        getAllMotoClub: builder.query({
            query: () => ({
                url: `${urlMotoCLub}/all`,
                method: 'GET',
            }),
        }),
    })
})

export const {
    useGetAllMotoClubQuery,
} = motoClubApiSlice