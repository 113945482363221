import {apiSlice} from "../../app/api/apiSlice";
const urlRepas = "repas";

export const repasApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Repas'],
    endpoints: builder => ({
        listRepasEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlRepas}/evenement/${evenementID}`,
                method: 'GET'
            })
        }),
    })
})

export const {
    useListRepasEvenementQuery,
} = repasApiSlice