import {Fragment, useState, useEffect} from "react";
import {Card, CardContent, FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const AssuranceForm = (props) => {

    let listAssurance, listDesciptionAssurance;

    const [selectAssurance, SetSelectAssurance] = useState('');

    if (props.listAssurance.length > 0) {
        listAssurance = props.listAssurance.map(value => {
            return <MenuItem value={value.id} key={value.id}>{`${value.libellecourt} (${value.prix} €)`}</MenuItem>
        });

        listDesciptionAssurance = props.listAssurance.map(value => {
            return <li key={value.id}>{`${value.libellecourt}: ${value.libellelong}`}</li>
        });
    }

    useEffect( () => {
        SetSelectAssurance(props.selected);
    },[props.selected]);

    const handleChangeAssurance = (event) => {
        SetSelectAssurance(event.target.value);
        props.selectAssurance(event.target.value);
    }

    return (
        <Fragment>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"
                                required error={props.errorAssurance}>Assurance</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Assurance"
                        value={selectAssurance}
                        onChange={handleChangeAssurance}
                        error={props.errorAssurance}
                        disabled={props.disable}
                    >
                        {listAssurance}
                    </Select>
                    {props.errorAssurance &&
                        <FormHelperText error={true}>Selectionner votre assurance</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Card sx={{
                    backgroundColor: "#D7D7D7"
                }}>
                    <CardContent>
                        <Typography variant="caption">
                            <ul>
                                {listDesciptionAssurance}
                            </ul>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Fragment>
    )
};

export default AssuranceForm