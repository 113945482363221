import {Fragment, useEffect, useState} from "react";
import AlertMail from "./AlertMail";
import {useGetUserInfoVerifAccountQuery} from "../../feature/utilisateur/utilisateurApiSlice";
import {useSelector} from "react-redux";
import {selectCurrentTokenPayload} from "../../feature/auth/authSlice";

const AlertBar = (props) => {

    const tokenPayload = useSelector(selectCurrentTokenPayload);

    const [alertMail, setAlertMail] = useState(false);

    const {
        data: userVerif,
        isLoading: isLoadingUserVerif,
        isSuccess: SucessUserVerif,
        isError: isErreurUserVerif,
        error: errorUserVerif
    } = useGetUserInfoVerifAccountQuery(tokenPayload.userID);

    useEffect(() => {
        if (userVerif != null && SucessUserVerif) {
            setAlertMail(!userVerif.confirmadressemail);
        }

    }, [userVerif, SucessUserVerif]);


    return (
        <Fragment>
            {alertMail && <AlertMail/>}
        </Fragment>
    )
};

export default AlertBar;