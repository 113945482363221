import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useState} from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import Alert from '@mui/material/Alert';
import {useErrorPaiementMutation} from "../../feature/paiement/paiementApiSlice";

const FormulairePaiement = (props) => {
    let listItemFacture;

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [technicalError, setTechnicalError] = useState(false);
    const [loadingPaymentElement, setLoadingPaymentElement] = useState(false);

    const [errorPaiement] = useErrorPaiementMutation();

    listItemFacture = props.dataFacture.items.map(val => {
        return <TableRow key={val.id}>
            <TableCell>{val.libelle}</TableCell>
            <TableCell align="right">{val.quantiter}</TableCell>
            <TableCell align="right">{val.prix} €</TableCell>
            <TableCell align="right">{val.prix_total} €</TableCell>
        </TableRow>
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);
        setTechnicalError(false);

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/confirm-paiement/${props.factureId}`,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            try {
                await errorPaiement({idfacture: props.factureId}).unwrap();
                setMessage(error.message);
            } catch (e) {
                setTechnicalError(true);
                setMessage(error.message);
            }
        } else {
            setMessage("Une erreur inattendue s'est produite.");
        }
        setIsProcessing(false);
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3, mb: 2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Typography align="center" variant="h3" gutterBottom>
                            Paiement
                        </Typography>
                    </Grid>
                    {technicalError &&
                        <Grid item xs={12} sm={12}>
                            <Alert severity="error">
                                Erreur Technique, réessayer ultérieurement !
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Desciption</TableCell>
                                        <TableCell align="right">Quantité</TableCell>
                                        <TableCell align="right">Prix Unitaire</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listItemFacture}
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell align="center" colSpan={1}>Total</TableCell>
                                        <TableCell align="right">{props.dataFacture.total} €</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {message && <Alert severity="error">{message}</Alert>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <PaymentElement onReady={() => {
                            setLoadingPaymentElement(true);
                        }} id="payment-element"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <LoadingButton fullWidth variant="contained" loading={isProcessing} type="submit"
                                       disabled={!stripe || !elements || !loadingPaymentElement} id="submit"
                                       sx={{mt: 1}}>
                            {`Payer ${props.dataFacture.total} €`}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
};
export default FormulairePaiement;