import {apiSlice} from "../../app/api/apiSlice";
const urlBenevole = "benevole" ;

export const motosApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Benevole'],
    endpoints: builder => ({
        getListTShirt: builder.query({
            query: () => ({
                url: `${urlBenevole}/list/tshirt`,
                method: 'GET',
            }),
            keepUnusedDataFor: 60,
        }),
        getListDisponibliter: builder.query({
            query: (evenementID) => ({
                url: `${urlBenevole}/list/disponibiliter/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 60,
        }),
        initBenevole: builder.mutation({
            query: (evenementID) => ({
                url: `${urlBenevole}/init/`,
                method: 'POST',
                body: {
                    evenementid: evenementID
                }
            }),
        }),
        deleteBenevole: builder.mutation({
            query: (benevoleID) => ({
                url: `${urlBenevole}/${benevoleID}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{type: 'BenevolesEvenement', id: 'LIST'}],
        }),
        getListMotosBenevole: builder.query({
            query: (benevoleID) => ({
                url: `${urlBenevole}/motos/${benevoleID}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            providesTags: (result) =>
                result ? [...result.map(({id}) => ({type: 'Motosbenevole', id})), {type: 'Motosbenevole', id: 'LIST'}] :
                    [{type: 'Motosbenevole', id: 'LIST'}],
        }),
        getListMotosNotBenevole: builder.query({
            query: (benevoleID) => ({
                url: `${urlBenevole}/motos/not/${benevoleID}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            providesTags: (result) =>
                result ? [...result.map(({id}) => ({type: 'MotosNotbenevole', id})), {type: 'MotosNotbenevole', id: 'LIST'}] :
                    [{type: 'MotosNotbenevole', id: 'LIST'}],
        }),
        addMotoToBenevole: builder.mutation({
            query: (obj) => ({
                url: `${urlBenevole}/${obj.benevoleID}/moto/${obj.motoID}`,
                method: 'PUT'
            }),
            invalidatesTags: [
                {type: 'MotosNotbenevole', id: 'LIST'},
                {type: 'Motosbenevole', id: 'LIST'}],
        }),
        deleteMotoToIBenevole: builder.mutation({
            query: (obj) => ({
                url: `${urlBenevole}/${obj.benevoleID}/moto/${obj.motoID}`,
                method: 'DELETE'
            }),
            invalidatesTags: [
                {type: 'MotosNotbenevole', id: 'LIST'},
                {type: 'Motosbenevole', id: 'LIST'}],
        }),
        saveBenevole: builder.mutation({
            query: (obj) => ({
                url: `${urlBenevole}/${obj.benevoleID}`,
                method: 'PUT',
                body: {...obj.data}
            }),
            invalidatesTags: [{type: 'DossierBenevole', id: 'UNIQUE'},{type: 'Motosbenevole', id: 'LIST'},{type: 'MotosNotbenevole', id: 'LIST'}]
        }),
        getBenevole: builder.query({
            query: (benevoleID) => ({
                url: `${urlBenevole}/${benevoleID}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            providesTags: (result) =>
                result ? [{type: 'DossierBenevole', ...result.id}, {type: 'DossierBenevole', id: 'UNIQUE'}] :
                    [{type: 'DossierBenevole', id: 'UNIQUE'}],
        }),
        confirmBenevole: builder.mutation({
            query: (benevoleID) => ({
                url: `${urlBenevole}/confirm`,
                method: 'POST',
                body: {
                    benevoleid: benevoleID
                }
            }),
        }),
        initPaiementBenevole: builder.mutation({
            query: (benevoleID) => ({
                url: `${urlBenevole}/init-paiement`,
                method: 'POST',
                body: {
                    benevoleid: benevoleID
                }
            }),
        }),
    })
})

export const {
    useGetListTShirtQuery,
    useGetListDisponibliterQuery,
    useInitBenevoleMutation,
    useDeleteBenevoleMutation,
    useGetListMotosBenevoleQuery,
    useGetListMotosNotBenevoleQuery,
    useAddMotoToBenevoleMutation,
    useDeleteMotoToIBenevoleMutation,
    useSaveBenevoleMutation,
    useGetBenevoleQuery,
    useConfirmBenevoleMutation,
    useInitPaiementBenevoleMutation,
} = motosApiSlice