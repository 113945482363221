import './App.css';
import SignIn from "./pages/authentification/SignIn";
import {Route, Routes, Navigate} from "react-router-dom";
import SignUp from "./pages/authentification/SignUp";
import SendLinkNewPassword from "./pages/authentification/SendLinkNewPassword";
import NewPassword from "./pages/authentification/NewPassword";
import HomePage from "./pages/private/HomePage";
import NavBar from "./components/NavBar/NavBar";
import * as React from "react";
import RequireAuth from "./feature/auth/RequireAuth";
import {Fragment} from "react";
import AlertConnected from "./components/bar/AlertConnected";
import ConfirmMail from "./pages/authentification/ConfirmMail";
import PaiementSucess from "./pages/private/PaiementSucess";
import Error500 from "./pages/public/Error500";
import PaiementFacture from "./pages/private/PaiementFacture";
import Footer from "./components/footer/Footer";
import RegistrationBenevole from "./pages/private/RegistrationBenevole";
import FetchRegistrationEvent from "./pages/private/fetch/FetchRegistrationEvent";
import FetchMonCompte from "./pages/private/fetch/FetchMonCompte";

function App() {
    return (
        <Fragment>
            <AlertConnected/>
            <NavBar/>
            <Routes>
                <Route path="/" element={<Navigate replace to="/login"/>}/>
                <Route path="/login" element={<SignIn/>}/>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/confirm-mail" element={<ConfirmMail/>}/>
                <Route path="/lostpassword" element={<SendLinkNewPassword/>}/>
                <Route path="/resetpassword" element={<NewPassword/>}/>
                <Route path="/500" element={<Error500/>}/>
                {/* protected route */}
                <Route element={<RequireAuth/>}>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/mon-compte" element={<FetchMonCompte/>}/>
                    <Route path="/paiement/:factureID" element={<PaiementFacture/>}/>
                    <Route path="/confirm-paiement/:factureID" element={<PaiementSucess/>}/>
                    <Route path="/inscription/:inscriptionId/:evenementId" element={<FetchRegistrationEvent/>}/>
                    <Route path="/benevole/:benevoleId/:evenementId" element={<RegistrationBenevole/>}/>
                </Route>
            </Routes>
            {/*<Footer/>*/}
        </Fragment>
    );
}

export default App;
