import {Fragment} from "react";
import * as Constants from "../../../utils/ContantesDossier";
import Button from "@mui/material/Button";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const ButtonBenevole = (props) => {
    const {idBenevole, statut, barcode} = props.data;
    let returnButton;
    const buttonConsulation =
        <Button size="small" variant="contained" sx={{mt: 1, ml: 1}} onClick={ () => {
            props.consulterBenevole(idBenevole)
        }}>
            Consulter
        </Button>

    if (statut === Constants.STATUT_DOSSIER_INIT || statut === Constants.STATUT_DOSSIER_IN_PROGRESS) {
        returnButton =
            <Fragment>
                <Button color="error" size="small" variant="contained" onClick={() => {
                    props.suppresionBenevole(idBenevole)
                }} sx={{mt: 1, ml: 1}}>
                    Suppression
                </Button>
                <Button size="small" variant="contained" onClick={() => {
                    props.updateBenevole(idBenevole)
                }} sx={{mt: 1, ml: 1}}>
                    Modifier
                </Button>
            </Fragment>
    } else if (statut === Constants.STATUT_DOSSIER_ATTENTE_VALIDATION ||
        statut === Constants.STATUT_DOSSIER_VALIDER ||
        statut === Constants.STATUT_DOSSIER_ANNULE) {
        returnButton =
            <Fragment>
                {buttonConsulation}
            </Fragment>
    } else if (statut === Constants.STATUT_DOSSIER_CONFIRMER || statut === Constants.STATUT_DOSSIER_ATTENTE_PAIEMENT || statut === Constants.STATUT_DOSSIER_ERREUR_PAIEMENT) {
        returnButton =
            <Fragment>
                {buttonConsulation}
                <Button size="small" variant="contained" onClick={() => {
                    props.payBenevole(idBenevole)
                }} sx={{mt: 1, ml: 1}}>
                    Payer
                </Button>
            </Fragment>
    } else if (statut === Constants.STATUT_DOSSIER_PAYER) {
        returnButton =
            <Fragment>
                {buttonConsulation}
                <Button size="small" variant="contained" sx={{mt: 1, ml: 1}} onClick={ () => { props.showQrCode(barcode)}}>
                    <QrCode2Icon/>
                </Button>
            </Fragment>
    }

    return (
        <Fragment>
            {returnButton}
        </Fragment>
    )
};

export default ButtonBenevole;