import {Fragment} from "react";
import {Card, List, ListItem, ListItemText, Stack, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    useDeleteMotoToInscriptionMutation
} from "../../feature/inscription/inscriptionApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {setEditionMoto, setEditionMotoID, setPanelMoto} from "../../feature/inscription/inscriptionSlice";
import * as Constants from "../../utils/ContantesDossier";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const ListMotoInscription = (props) => {

    let listMotoInscription;
    const dispatch = useDispatch();

    const sliceInscription = useSelector((state) => state.inscription);

    const [deleteMotoToInscription] = useDeleteMotoToInscriptionMutation();

    const handleDeleteMoto = async (event, params) => {
        const objAddMotoInscription = {
            inscriptionID: sliceInscription.inscriptionID,
            motoID: params
        };
        await deleteMotoToInscription(objAddMotoInscription);
    };

    const handleEditMoto = (event, params) => {
        dispatch(setPanelMoto(true));
        dispatch(setEditionMoto(true));
        dispatch(setEditionMotoID(params));
    };

    const validationMoto = props.disable && (sliceInscription.statut !== Constants.STATUT_DOSSIER_INIT || sliceInscription.statut !== Constants.STATUT_DOSSIER_IN_PROGRESS || sliceInscription.statut !== Constants.STATUT_DOSSIER_INCOMPLET);

    if (props.listMotos.length > 0) {
        listMotoInscription =
            <Card variant="outlined">
                <List>
                    {props.listMotos.map(result => {
                        return <ListItem key={result.id} secondaryAction={
                            <Stack direction="row" spacing={2}>
                                {validationMoto && <Tooltip
                                    title={validationMoto && result.validation ? 'Moto valider' : 'Moto non valider'}>
                                    <IconButton edge="end" aria-label="validation">
                                        {validationMoto && result.validation ?
                                            <CheckCircleIcon color={'success'}/> :
                                            <NotInterestedIcon color={'error'}/>}
                                    </IconButton>
                                </Tooltip>}
                                <IconButton edge="end" aria-label="edit" disabled={props.disable}
                                            onClick={event => handleEditMoto(event, result.id)}>
                                    <EditIcon/>
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" value={result.id}
                                            disabled={props.disable}
                                            onClick={event => handleDeleteMoto(event, result.id)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Stack>}>
                            <ListItemText
                                primary={`${result.marque} ${result.modele}`}
                            />
                        </ListItem>
                    })}
                </List>
            </Card>
    }

    return (
        <Fragment>
            {listMotoInscription}
        </Fragment>
    )
};

export default ListMotoInscription;