import {apiSlice} from "../../app/api/apiSlice"

export const motosApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Langue'],
    endpoints: builder => ({
        allLangue: builder.query({
            query: () => ({
                url: `/langue/all`,
                method: 'GET',
            }),
        }),
    })
})

export const {
    useAllLangueQuery,
} = motosApiSlice