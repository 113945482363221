import * as React from "react";
import {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import formUtils from "../../utils/formUtils";
import {
    useAddMotoMutation,
    useGetMotoQuery,
    useGetTypesMotosEventQuery,
    usePutMotoMutation
} from "../../feature/moto/motoApiSlice";
import {useAddMotoToInscriptionMutation} from "../../feature/inscription/inscriptionApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {setPanelMoto} from "../../feature/inscription/inscriptionSlice";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";

const CardAddMoto = (props) => {

    const dispatch = useDispatch();
    const sliceInscription = useSelector((state) => state.inscription);

    const [addMoto, {isLoading: isLoadingAddMoto}] = useAddMotoMutation();
    const [putMoto, {isLoading: isLoadingPutMoto}] = usePutMotoMutation();
    const [addMotoToInscription, {isLoading: isLoadingAddMotoToInscription}] = useAddMotoToInscriptionMutation();

    const [policeAssurance, setPoliceAssurance] = useState(false);

    const [errorMoto, setErrorMoto] = useState(false);
    const [errorModel, setErrorModel] = useState(false);
    const [errorCylindree, setErrorCylindree] = useState(false);
    const [errorAnnee, setErrorAnnee] = useState(false);
    const [errorSerialNumber, setErrorSerialNumber] = useState(false);
    const [errorPoliceAssurance, setErrorPoliceAssurance] = useState(false);

    const [errorMotoMessage, setErrorMotoMessage] = useState('');
    const [errorModelMessage, setErrorModelMessage] = useState('');
    const [errorCylindreeMessage, setErrorCylindreeMessage] = useState('');
    const [errorAnneeMessage, setErrorAnneeMessage] = useState('');
    const [errorSerialNumberMessage, setErrorSerialNumberMessage] = useState('');
    const [errorPoliceAssuranceMessage, setErrorPoliceAssuranceMessage] = useState('');

    const [selectTypeMoto, setSelectTypeMoto] = useState('');
    const [selectMarqueMoto, setSelectMarqueMoto] = useState('');
    const [selectModelMoto, setSelectmodelMoto] = useState('');
    const [selectCylindreeMoto, setSelectCylindreeMoto] = useState('');
    const [selectAnneeMoto, setSelectAnneeMoto] = useState('');
    const [selectSerialNumberMoto, setSelectSerialNumberMoto] = useState('');
    const [selectPoliceAssuranceMoto, setSelectPoliceAssuranceMoto] = useState('');
    const [selectPartageMoto, setSelectPartageMoto] = useState(false);
    const [selectPreparerMoto, setSelectPreparerMoto] = useState(false);

    const handleChangePoliceAssurance = (event) => {
        setPoliceAssurance(event.target.checked);
    };
    const handleChangeMotoPartager = (event) => {
        setSelectPartageMoto(event.target.checked);
    };
    const handleChangeMotoPreparer = (event) => {
        setSelectPreparerMoto(event.target.checked);
    };
    const handleChangeTypeMoto = (event) => {
        setSelectTypeMoto(event.target.value);
    };
    const handleSubmitCreateMoto = async (event) => {
        event.preventDefault();
        if (verificationFormulaire()) {
            await createMoto();
        }
    };
    const handleSubmitUpdateMoto = async (event) => {
        event.preventDefault();
        if (verificationFormulaire()) {
            await updateMoto();
        }
    };
    const handleClose = async () => {
        dispatch(setPanelMoto(false));
    };
    const createMoto = async () => {
        const objNewMoto = {
            marque: selectMarqueMoto,
            modele: selectModelMoto,
            cylindree: Number(selectCylindreeMoto),
            annee: Number(selectAnneeMoto),
            partager: selectPartageMoto,
            preparer: selectPreparerMoto,
            serialnumber: selectSerialNumberMoto,
            assurance: policeAssurance ? selectPoliceAssuranceMoto : null,
            typeid: selectTypeMoto
        }
        try {
            const newMoto = await addMoto(objNewMoto).unwrap();
            const objAddMotoInscription = {
                inscriptionID: sliceInscription.inscriptionID,
                motoID: newMoto.id
            };
            await addMotoToInscription(objAddMotoInscription).unwrap();
            dispatch(setPanelMoto(false));
        } catch (e) {
            if (e.status === 405) {
                dispatch(setPanelMoto(false));
                props.errorMaxMoto(true);
            }
        }
    };
    const updateMoto = async () => {
        const objNewMoto = {
            motoID: sliceInscription.editionMotoID,
            data: {
                marque: selectMarqueMoto,
                modele: selectModelMoto,
                cylindree: Number(selectCylindreeMoto),
                annee: Number(selectAnneeMoto),
                partager: selectPartageMoto,
                preparer: selectPreparerMoto,
                serialnumber: selectSerialNumberMoto,
                assurance: policeAssurance ? selectPoliceAssuranceMoto : null,
                typeid: selectTypeMoto
            }
        };
        try {
            await putMoto(objNewMoto).unwrap();
            dispatch(setPanelMoto(false));
        } catch (e) {

        }
    };

    let listTypeMoto;

    const {
        data: typesMoto,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetTypesMotosEventQuery(sliceInscription.evenementID, {skip: sliceInscription.skipFetchData});

    if (isSuccess) {
        listTypeMoto = typesMoto.map(value => {
            return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
        });
    }
    const {
        data: moto,
        isLoading: isLoadingMoto,
        isSuccess: isSuccessMoto,
        isError: isErrorMoto,
        error: errorMotott
    } = useGetMotoQuery(sliceInscription.editionMotoID, {
        skip: !sliceInscription.editionMoto
    });
    useEffect(() => {
        if (isSuccessMoto && isSuccess) {
            setSelectTypeMoto(moto.mototype.id);
            setSelectMarqueMoto(moto.marque);
            setSelectmodelMoto(moto.modele);
            setSelectCylindreeMoto(moto.cylindree);
            setSelectAnneeMoto(moto.annee);
            setSelectPartageMoto(moto.partager);
            setSelectPreparerMoto(moto.preparer);
            setSelectSerialNumberMoto(moto.serialnumber);
            if (moto.policeassurance !== null) {
                setPoliceAssurance(true);
                setSelectPoliceAssuranceMoto(moto.policeassurance);
            }
        }
    }, [isSuccessMoto, isSuccess]);

    const invalidFormatLibelle = 'Format invalid';
    const invalidChampsOnligatoire = 'Champs obligatoire';

    const verificationFormulaire = () => {
        let returnFormValidity;
        // vérification de champs s'il ne sont pas vide
        const champMarqueEmpty = formUtils.verifEmptyEntry(selectMarqueMoto);
        setErrorMoto(champMarqueEmpty);
        setErrorMotoMessage(champMarqueEmpty ? invalidChampsOnligatoire : '');

        const champModelEmpty = formUtils.verifEmptyEntry(selectModelMoto);
        setErrorModel(champModelEmpty);
        setErrorModelMessage(champModelEmpty ? invalidChampsOnligatoire : '');

        const champCylindreEmpty = formUtils.verifEmptyEntry(selectCylindreeMoto);
        setErrorCylindree(champCylindreEmpty);
        setErrorCylindreeMessage(champCylindreEmpty ? invalidChampsOnligatoire : '');

        const champAnneeEmpty = formUtils.verifEmptyEntry(selectAnneeMoto);
        setErrorAnnee(champAnneeEmpty);
        setErrorAnneeMessage(champAnneeEmpty ? invalidChampsOnligatoire : '');

        const champSerialNumberEmpty = formUtils.verifEmptyEntry(selectSerialNumberMoto);
        setErrorSerialNumber(champSerialNumberEmpty);
        setErrorSerialNumberMessage(champSerialNumberEmpty ? invalidChampsOnligatoire : '');

        const champAssuranceEmpty = formUtils.verifEmptyEntry(selectSerialNumberMoto);
        setErrorPoliceAssurance(champAssuranceEmpty);
        setErrorPoliceAssuranceMessage(champAssuranceEmpty ? invalidChampsOnligatoire : '');

        if (policeAssurance) {
            returnFormValidity = !champMarqueEmpty && !champModelEmpty && !champCylindreEmpty && !champAnneeEmpty && !champSerialNumberEmpty && !champAssuranceEmpty;
        } else {
            returnFormValidity = !champMarqueEmpty && !champModelEmpty && !champCylindreEmpty && !champAnneeEmpty && !champSerialNumberEmpty;
        }
        return returnFormValidity;
    };
    const buttonSave = () => {
        if (props.edit) {
            return <LoadingButton loading={loadingSave} variant="contained"
                                  onClick={handleSubmitUpdateMoto}>Enregistrer</LoadingButton>
        } else {
            return <LoadingButton loading={loadingSave} variant="contained"
                                  onClick={handleSubmitCreateMoto}>Ajouter</LoadingButton>
        }
    };

    const loadingSave = isLoadingAddMotoToInscription || isLoadingAddMoto || isLoadingPutMoto;

    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <DialogContent>
                <Grid container sx={{
                    marginBottom: 1
                }}>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel required id="demo-simple-select-label">Type moto</InputLabel>
                            <Select
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Type moto"
                                value={selectTypeMoto}
                                onChange={handleChangeTypeMoto}
                            >
                                {listTypeMoto}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                name="Marque"
                                fullWidth
                                id="prenom"
                                label="Marque"
                                value={selectMarqueMoto}
                                onChange={event => {
                                    if (formUtils.verifAlpha(event.target.value)) {
                                        setSelectMarqueMoto(event.target.value)
                                    }
                                }}
                                error={errorMoto}
                                helperText={errorMoto && errorMotoMessage}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                name="Model"
                                fullWidth
                                id="model_Moto"
                                label="Model"
                                value={selectModelMoto}
                                onChange={event => {
                                    if (formUtils.verifAlpha(event.target.value)) {
                                        setSelectmodelMoto(event.target.value)
                                    }
                                }}
                                error={errorModel}
                                helperText={errorModel && errorModelMessage}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body2" sx={{
                            fontSize: 10
                        }}>
                            Indique la marque de ta moto et, pour les side-cars, indique la marque du chassis et du
                            moteur
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="middle" sx={{
                    marginTop: 2,
                    marginBottom: 2
                }}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            name="Cylindrée"
                            fullWidth
                            id="prenom"
                            label="Cylindrée"
                            type="number"
                            value={selectCylindreeMoto}
                            onChange={event => {
                                setSelectCylindreeMoto(event.target.value)
                            }}
                            error={errorCylindree}
                            helperText={errorCylindree && errorCylindreeMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            name="Année"
                            fullWidth
                            id="prenom"
                            label="Année"
                            type="number"
                            value={selectAnneeMoto}
                            onChange={event => {
                                setSelectAnneeMoto(event.target.value)
                            }}
                            error={errorAnnee}
                            helperText={errorAnnee && errorAnneeMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            name="N° de série de ta moto"
                            fullWidth
                            id="prenom"
                            label="N° de série de ta moto"
                            value={selectSerialNumberMoto}
                            onChange={event => {
                                if (formUtils.verifAlpha(event.target.value)) {
                                    setSelectSerialNumberMoto(event.target.value);
                                }
                            }}
                            error={errorSerialNumber}
                            helperText={errorSerialNumber && errorSerialNumberMessage}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body2" sx={{
                            fontSize: 10
                        }}>
                            Il nous faut le numéro de série de ta machine pour l'identifier clairement. Ce numéro de
                            série sera vérifié durant le contrôle technique.
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="middle" sx={{
                    marginTop: 2,
                    marginBottom: 2
                }}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={selectPartageMoto}
                                                 onChange={handleChangeMotoPartager}/>}
                                label="Ma moto est partagée"/>
                            <FormControlLabel
                                control={<Switch checked={selectPreparerMoto}
                                                 onChange={handleChangeMotoPreparer}/>}
                                label="Ma moto est préparée spécialement pour rouler sur circuit"/>
                            <FormControlLabel
                                control={<Switch checked={policeAssurance} onChange={handleChangePoliceAssurance}/>}
                                label="Ma moto est couverte par une RC"/>
                        </FormGroup>
                    </Grid>
                    {policeAssurance && <Grid item xs={12} sm={12}>
                        <TextField
                            required={policeAssurance}
                            name="N° police assurance"
                            fullWidth
                            id="prenom"
                            label="N° police assurance"
                            value={selectPoliceAssuranceMoto}
                            error={errorPoliceAssurance}
                            onChange={event => {
                                setSelectPoliceAssuranceMoto(event.target.value)
                            }}
                            helperText={errorPoliceAssurance && errorPoliceAssuranceMessage}
                        />
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>
                {buttonSave()}
            </DialogActions>
        </Dialog>
    )
};

export default CardAddMoto;