import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import 'dayjs/locale/fr';
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Snackbar} from "@mui/material";
import {Fragment, useState} from "react";
import Button from "@mui/material/Button";
import formUtils from "../../utils/formUtils";
import {useUpdateUserInfoMutation} from "../../feature/utilisateur/utilisateurApiSlice";
import Alert from "@mui/material/Alert";
import dayjs from "dayjs";
import {LoadingButton} from "@mui/lab";

export default (props) => {

    const {listMotoClub, listLanguage, listCountries, userInfo} = props;

    const [technicalError, setTechnicalError] = useState(false);
    const [technicalErrorMessage, setTechnicalErrorMessage] = useState('');
    const [successSnackBar, setSuccessSnackBar] = useState(false);
    const [successSnackBarMessage, setSuccessSnackBarMessage] = useState('');

    const [info, setInfo] = useState({
        prenom: userInfo.prenom,
        nom: userInfo.nom,
        dateNaissance: dayjs(userInfo.datenaissance),
        numeroTelephone: userInfo.telephone,
        adresse: userInfo.adresse,
        codePostale: userInfo.codepostale,
        ville: userInfo.ville,
        mail: userInfo.adressemail,
        pays: userInfo.pays.id,
        langue: userInfo.langue.id,
        motoClub: userInfo.motoclub != null ? userInfo.motoclub.id : ''
    });

    const [errorField, setErrorField] = useState({
        nom: {
            error: false, message: ''
        }, prenom: {
            error: false, message: ''
        }, dateNaissance: {
            error: false, message: ''
        }, numeroTelephone: {
            error: false, message: ''
        }, adresse: {
            error: false, message: ''
        }, codePostale: {
            error: false, message: ''
        }, ville: {
            error: false, message: ''
        }, email: {
            error: false, message: ''
        }, pays: {
            error: false, message: ''
        }, langue: {
            error: false, message: ''
        }, motoClub: {
            error: false, message: ''
        },
    });

    const [modif, setmodif] = useState(true);

    const [updateUserInfo, {isLoading: isLoadingUpdateUserInfo}] = useUpdateUserInfoMutation();

    const handleModification = (value) => {
        setmodif(value);
    }

    const handleModifierInformationPersonnel = (value, field) => {
        setInfo({
            ...info,
            prenom: field === 'prenom' ? value : info.prenom,
            nom: field === 'nom' ? value : info.nom,
            dateNaissance: field === 'dateNaissance' ? value : info.dateNaissance,
            numeroTelephone: field === 'numeroTelephone' ? value : info.numeroTelephone,
            adresse: field === 'adresse' ? value : info.adresse,
            codePostale: field === 'codePostale' ? value : info.codePostale,
            ville: field === 'ville' ? value : info.ville,
            mail: field === 'mail' ? value : info.mail,
            pays: field === 'pays' ? value : info.pays,
            langue: field === 'langue' ? value : info.langue,
            motoClub: field === 'motoClub' ? value : info.motoClub,
        })
    }

    const validFormulate = () => {
        const fieldsToValidate = [{
            name: 'nom',
            value: info.nom,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'prenom',
            value: info.prenom,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'dateNaissance',
            value: info.dateNaissance,
            validator: formUtils.verifEmptyEntryDateDaysJs
        }, {
            name: 'numeroTelephone',
            value: info.numeroTelephone,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'adresse',
            value: info.adresse,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'codePostale',
            value: info.codePostale,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'ville',
            value: info.ville,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'email',
            value: info.mail,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'pays',
            value: info.pays,
            validator: formUtils.verifEmptyEntry
        }, {
            name: 'langue',
            value: info.langue,
            validator: formUtils.verifEmptyEntry
        },];
        let firstErrorFieldName = '';
        let newErrorField = {...errorField};
        let isFormValid = true;

        // Valider tous les champs qui ne doivent pas être vides
        fieldsToValidate.forEach(({name, value, validator}) => {
            const isFieldValid = !validator(value); // Nous utilisons "!" pour inverser la logique car "true" signifie ici "champ vide"
            newErrorField[name] = {
                error: !isFieldValid, message: isFieldValid ? '' : 'Champ Obligatoire'
            };
            if (!isFieldValid && !firstErrorFieldName) {
                firstErrorFieldName = name; // Enregistre le premier champ en erreur
                isFormValid = false; // Marque le formulaire comme invalide
            }
        });
        if (!isNaN(info.codePostale) && formUtils.isNotNegative(info.codePostale)) {
            newErrorField.codePostale = {
                error: false, message: ''
            };
        } else {
            newErrorField.codePostale = {
                error: true, message: "Code postale est négatif ou n'est pas un nombre."
            };
            isFormValid = false;
        }

        // Validation du numéro de téléphone
        const NumeroTelephoneNotEmpty = !formUtils.verifEmptyEntry(info.numeroTelephone);
        if (NumeroTelephoneNotEmpty) {
            const validPhoneNumber = matchIsValidTel(info.numeroTelephone);

            newErrorField.numeroTelephone = {
                error: !validPhoneNumber, message: !validPhoneNumber ? 'Format de numéro de téléphone invalide' : ''
            };
            if (!validPhoneNumber) {
                firstErrorFieldName = 'numeroTelephone';
                isFormValid = false;
            }
        }

        setErrorField(newErrorField);

        if (firstErrorFieldName) {
            const errorElement = document.querySelector(`[name="${firstErrorFieldName}"]`);
            if (errorElement) {
                errorElement.focus();
                errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }
        return isFormValid;
    };

    const handleSaveUserInfo = async () => {
        if (validFormulate()) {
            await updateUserInfo({
                userID: userInfo.id,
                data: {...info}
            }).unwrap()
                .then(() => {
                    setSuccessSnackBarMessage("Information utilisateur enregistrée avec succès");
                    setSuccessSnackBar(true);
                })
                .catch((error) => {
                    if (error.status === 403) {
                        setErrorField({
                            ...errorField,
                            email: {
                                error: true,
                                message: 'Email deja utiliser'
                            }
                        });
                    } else {
                        setTechnicalError(true);
                        setTechnicalErrorMessage('Erreur technique veuillez ressayer ultérieurement');
                    }
                })
        }
    };

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom textAlign="center" sx={{fontWeight: 'bold'}}>
                        Information Personnelle
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={2.5}>
                            <TextField
                                name="prenom"
                                value={info.prenom}
                                disabled={modif}
                                required
                                fullWidth
                                label="Prenom"
                                onChange={
                                    (event) => handleModifierInformationPersonnel(event.target.value, 'prenom')}
                                error={errorField.prenom.error}
                                helperText={errorField.prenom.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2.5}>
                            <TextField
                                required
                                fullWidth
                                label="Nom"
                                name="nom"
                                value={info.nom}
                                disabled={modif}
                                onChange={
                                    (event) => handleModifierInformationPersonnel(event.target.value, 'nom')}
                                error={errorField.nom.error}
                                helperText={errorField.nom.message}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <Box sx={{
                                textAlign: 'center'
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                                    <DesktopDatePicker
                                        sx={{width: '100%'}}
                                        label="Date de naissance"
                                        inputFormat="DD/MM/YYYY"
                                        value={info.dateNaissance}
                                        disabled={modif}
                                        name="dateNaissance"
                                        onChange={
                                            (event) => handleModifierInformationPersonnel(event, 'dateNaissance')}
                                        slotProps={{
                                            textField: {
                                                error: errorField.dateNaissance.error,
                                                helperText: errorField.dateNaissance.error ? errorField.dateNaissance.message : '',
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <MuiTelInput
                                fullWidth
                                label="Numero de Telephone"
                                name="numeroTelephone"
                                value={info.numeroTelephone}
                                disabled={modif}
                                onChange={
                                    (event) => handleModifierInformationPersonnel(event.target.value, 'numeroTelephone')}
                                onlyCountries={['FR', 'BE', 'IT', 'ES', 'GB', 'CH', 'IE', 'NL', 'LU', 'PT']}
                                langOfCountryName="fr"
                                defaultCountry="FR"
                                forceCallingCode
                                error={errorField.numeroTelephone.error}
                                helperText={errorField.numeroTelephone.message}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <TextField
                                name="adresse"
                                required
                                fullWidth
                                label="Adresse"
                                value={info.adresse}
                                disabled={modif}
                                onChange={
                                    (event) => handleModifierInformationPersonnel(event.target.value, 'adresse')}
                                error={errorField.adresse.error}
                                helperText={errorField.adresse.message}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={2.5}>
                            <TextField
                                name="codePostale"
                                required
                                fullWidth
                                label="Code Postale"
                                value={info.codePostale}
                                disabled={modif}
                                onChange={
                                    (event) => handleModifierInformationPersonnel(event.target.value, 'codePostale')}
                                error={errorField.codePostale.error}
                                helperText={errorField.codePostale.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2.5}>
                            <TextField
                                name="ville"
                                required
                                fullWidth
                                label="Ville"
                                value={info.ville}
                                disabled={modif}
                                onChange={
                                    (event) => handleModifierInformationPersonnel(event.target.value, 'ville')}
                                error={errorField.ville.error}
                                helperText={errorField.ville.message}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <TextField
                                name="email"
                                required
                                fullWidth
                                label="Email"
                                value={info.mail}
                                disabled={modif}
                                onChange={
                                    (event) => handleModifierInformationPersonnel(event.target.value, 'mail')}
                                error={errorField.email.error}
                                helperText={errorField.email.message}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={2.5}>
                            <FormControl fullWidth>
                                <InputLabel required error={errorField.pays.error}>Pays</InputLabel>
                                <Select
                                    required
                                    label="Pays"
                                    name="pays"
                                    value={info.pays}
                                    disabled={modif}
                                    onChange={
                                        (event) => handleModifierInformationPersonnel(event.target.value, 'pays')}
                                    error={errorField.pays.error}
                                >
                                    {listCountries.map(value => {
                                        return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
                                    })}
                                </Select>
                                {errorField.pays.error && <FormHelperText
                                    error={errorField.pays.error}>{errorField.pays.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2.5}>
                            <FormControl fullWidth>
                                <InputLabel required error={errorField.langue.error}>Langue</InputLabel>
                                <Select
                                    required
                                    label="Langue"
                                    name="langue"
                                    value={info.langue}
                                    disabled={modif}
                                    onChange={
                                        (event) => handleModifierInformationPersonnel(event.target.value, 'langue')}
                                    error={errorField.langue.error}
                                >
                                    {listLanguage.map(value => {
                                        return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
                                    })}
                                </Select>
                                {errorField.langue.error && <FormHelperText
                                    error={errorField.langue.error}>{errorField.langue.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <FormControl fullWidth>
                                <InputLabel error={errorField.motoClub.error}>Moto
                                    club</InputLabel>
                                <Select
                                    label="Moto club"
                                    value={info.motoClub}
                                    disabled={modif}
                                    onChange={
                                        (event) => handleModifierInformationPersonnel(event.target.value, 'motoClub')}
                                    error={errorField.motoClub.error}
                                >
                                    <MenuItem value={''}>Pas de moto club</MenuItem>
                                    {listMotoClub.map(value => {
                                        return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
                                    })}
                                </Select>
                                {errorField.motoClub.error && <FormHelperText
                                    error={errorField.motoClub.error}>{errorField.motoClub.error}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={2.5} sx={{textAlign: 'center'}}>
                            {modif &&
                                <Button onClick={() => handleModification(false)} variant="contained">Modifier</Button>}
                            {!modif &&
                                <LoadingButton loading={isLoadingUpdateUserInfo} onClick={handleSaveUserInfo}
                                               variant="contained">enregistrer</LoadingButton>}
                        </Grid>
                        {!modif && <Grid item xs={12} sm={2.5} sx={{textAlign: 'center'}}>
                            <Button onClick={() => handleModification(true)} variant="contained"
                                    color="error">Annuler</Button>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={successSnackBar} autoHideDuration={5000} onClose={() => setSuccessSnackBar(false)}>
                <Alert onClose={() => setSuccessSnackBar(false)} severity="success" sx={{width: '100%'}}>
                    {successSnackBarMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={technicalError} autoHideDuration={5000} onClose={() => setTechnicalError(false)}>
                <Alert onClose={() => setTechnicalError(false)} severity="error" sx={{width: '100%'}}>
                    {technicalErrorMessage}
                </Alert>
            </Snackbar>
        </Fragment>
    )
};