import {Fragment, useEffect, useRef, useState} from "react";
import {
    Alert,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
    useDisableSmsMutation,
    useSendConfirmSmsPhoneNumberMutation,
    useVerifyCodeSmsPhoneNumberMutation
} from "../../feature/auth/authApiSlice";
import formUtils from "../../utils/formUtils";
import {LoadingButton} from "@mui/lab";

const ModalConfirmPhoneNumber = (props) => {

    const Ref = useRef(null);

    const [open, setOpen] = useState(false);
    const [noSMS, setnoSMS] = useState(false);
    const [codeSMS, setcodeSMS] = useState('');
    const [erreurCodeSMS, setErreurCodeSMS] = useState(false);
    const [codeInvalide, setCodeInvalide] = useState(false);
    const [nombreEnvoieSMS, setNombreEnvoieSMS] = useState(0);
    const [technicalError, setTechnicalError] = useState(false);
    const [displayTimerSMS, setDisplayTimerSMS] = useState(false);
    const [desactiverBoutonEnvoie, setDesactiverBoutonEnvoie] = useState(false);

    const [timer, setTimer] = useState('00:00:00');

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    };
    const startTimer = (e) => {
        let {total, hours, minutes, seconds}
            = getTimeRemaining(e);
        if (total >= 0) {

            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    };
    const clearTimer = (e) => {

        setTimer('00:15:00');

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    };
    const getDeadTime = () => {
        let deadline = new Date();

        deadline.setSeconds(deadline.getSeconds() + 900);
        return deadline;
    };
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }


    const [sendConfirmSmsPhoneNumber, {isLoading: isLoadingSendConfirmSmsPhoneNumber}] = useSendConfirmSmsPhoneNumberMutation();
    const [verifyCodeSmsPhoneNumber, {isLoading: isLoadingVerifyCodeSmsPhoneNumber}] = useVerifyCodeSmsPhoneNumberMutation();
    const [disableSms, {isLoading: isLoadingDisableSms}] = useDisableSmsMutation();

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);


    const handleClose = () => {
        props.changeOpen(false);
    };
    const handleClickSendCode = async () => {
        try {
            setDesactiverBoutonEnvoie(true);
            setTechnicalError(false);
            setNombreEnvoieSMS((number) => number + 1);
            await sendConfirmSmsPhoneNumber().unwrap();
            setDisplayTimerSMS(true);
            onClickReset();
            setTimeout(() => {
                setDesactiverBoutonEnvoie(false);
            }, 90000);
            focusChampsDeSaisie();
        } catch (e) {
            setDesactiverBoutonEnvoie(true);
            setTechnicalError(true);
        }
    };
    const handleConfirmPhoneNumber = async () => {
        setCodeInvalide(false);
        try {
            setTechnicalError(false);
            if (!noSMS) {
                if (codeSMS.length === 6) {
                    setErreurCodeSMS(false);
                    await verifyCodeSmsPhoneNumber(codeSMS).unwrap();
                    setOpen(false);
                } else {
                    setErreurCodeSMS(true);
                }
            } else {
                await disableSms().unwrap();
                setOpen(false);
            }
        } catch (e) {
            if (e.status === 406) {
                setCodeInvalide(true);
            } else {
                setTechnicalError(true);
            }
        }
    };
    const handleChangeCodeSms = (event) => {
        if (formUtils.verifNumerique(event.target.value)) {
            setcodeSMS(event.target.value);
        }
    };
    const focusChampsDeSaisie = () => {
        const errorElement = document.querySelector(`[name="code-verification"]`);
        errorElement.focus();
        errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    };

    return (
        <Fragment>
            <Dialog open={open}>
                <DialogTitle sx={{
                    textAlign: 'center'
                }}>Confirmer Numero Téléphone</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{pb: 2}}>
                        Cette confirmation nous servira à vous envoyer les information de suivi sur votre dossier
                        (paiement, refus, ...)
                    </DialogContentText>
                    {!noSMS &&
                        <Fragment>
                            <DialogContentText sx={{textAlign: 'center', pb: 2}}>
                                <LoadingButton loading={isLoadingSendConfirmSmsPhoneNumber}
                                               disabled={desactiverBoutonEnvoie}
                                               onClick={handleClickSendCode}
                                               variant="contained">{nombreEnvoieSMS > 0 ? 'Renvoyer Code' : 'Envoyer code'}</LoadingButton>
                            </DialogContentText>
                            {displayTimerSMS &&
                                <DialogActions>
                                    <Alert sx={{width: '100%'}} severity="info">Votre code est
                                        valide: <b>{timer}</b></Alert>
                                </DialogActions>
                            }
                            <DialogActions sx={{textAlign: 'center'}}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    value={codeSMS}
                                    onChange={handleChangeCodeSms}
                                    name="code-verification"
                                    label="Code vérification"
                                    inputProps={{maxLength: 6}}
                                    error={erreurCodeSMS}
                                    helperText={erreurCodeSMS ? 'Veuillez renseigner votre code recu par SMS' : ''}
                                />
                            </DialogActions>
                            {codeInvalide &&
                                <DialogActions>
                                    <Alert sx={{width: '100%'}} severity="warning">Code de vérification invalide ou
                                        expiré !</Alert>
                                </DialogActions>
                            }
                        </Fragment>
                    }
                    {technicalError &&
                        <DialogActions>
                            <Alert sx={{width: '100%'}} severity="error">Erreur Technique Essayer ultérieurement
                                !</Alert>
                        </DialogActions>
                    }
                    <DialogActions sx={{textAlign: 'center'}}>
                        <FormControl>
                            <FormControlLabel
                                control={<Checkbox checked={noSMS} onChange={event => setnoSMS(event.target.checked)}/>}
                                label="Je ne souhaite pas être informer par sms de mon suivi de dossiers"/>
                        </FormControl>
                    </DialogActions>
                </DialogContent>
                <DialogActions>
                    <Button color='error' variant="contained" onClick={handleClose}>Quitter</Button>
                    <LoadingButton loading={isLoadingVerifyCodeSmsPhoneNumber || isLoadingDisableSms}
                                   variant="contained" onClick={handleConfirmPhoneNumber}>Confirmer</LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default ModalConfirmPhoneNumber;