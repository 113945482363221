import {Fragment} from "react";
import {useSelector} from "react-redux";
import {userConnected} from "../../feature/auth/authSlice";
import AlertBar from "./AlertBar";

const AlertConnected = () => {

    const userConnect = useSelector(userConnected);

    return (
        <Fragment>
            {userConnect &&
                <AlertBar/>}
        </Fragment>
    )
};

export default AlertConnected;