import {
    useGetInscriptionQuery, useGetListMotosInscriptionQuery,
    useGetListTypeInscriptionQuery, useMaxMotoInscriptionQuery
} from "../../../feature/inscription/inscriptionApiSlice";
import {useGetAssurancesEvenementQuery} from "../../../feature/assurance/assuranceApiSlice";
import {useGetExperienceEvenementQuery} from "../../../feature/experience/experienceApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setEvenementid, setInscriptionid, setSkipFetchData} from "../../../feature/inscription/inscriptionSlice";
import {useNavigate, useParams} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import RegistrationEvent from "../RegistrationEvent";
import {Fragment} from "react";
import {selectCurrentTokenPayload} from "../../../feature/auth/authSlice";
import {useGetMotosQuery} from "../../../feature/moto/motoApiSlice";
import {useAllCountriesQuery} from "../../../feature/pays/paysApiSlice";
import {useAllLangueQuery} from "../../../feature/langue/langueApiSlice";
import {useGetAllMotoClubQuery} from "../../../feature/motoclub/motoClubApiSlice";
import {useGetUserInfoQuery} from "../../../feature/utilisateur/utilisateurApiSlice";
import MonCompte from "../MonCompte";

export default () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenPayload = useSelector(selectCurrentTokenPayload);

    const sliceInscription = useSelector((state) => state.inscription);
    const {inscriptionId, evenementId} = useParams();

    useEffect(() => {
        dispatch(setInscriptionid(inscriptionId));
        dispatch(setEvenementid(evenementId));
        dispatch(setSkipFetchData(false));
    }, []);

    const {
        data: countries, isSuccess: SucessCountries, isError: isErreurCountries,
    } = useAllCountriesQuery();

    const {
        data: langue, isSuccess: SucessLangue, isError: isErreurLangue,
    } = useAllLangueQuery();

    const {
        data: motoclubs, isSuccess: SucessMotoclubs, isError: isErreurMotoclubs,
    } = useGetAllMotoClubQuery();

    const {
        data: userInfo,
        isLoading: isLoadingUserInfo,
        isSuccess: SucessUserInfo,
        isError: isErreurUserInfo,
        error: errorUserInfo
    } = useGetUserInfoQuery(tokenPayload.userID);

    const AllSucessLoad = SucessMotoclubs && SucessLangue && SucessCountries && SucessUserInfo;

    // useEffect(() => {
    //     if (ErreurFetchData) {
    //         navigate('/500');
    //     }
    //     if (isErreurDossierInsciption) {
    //         navigate('/home');
    //     }
    // }, [ErreurFetchData, isErreurDossierInsciption]);
    return (
        <Fragment>
            {!AllSucessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <MonCompte
                    listMotoClub={motoclubs}
                    listLanguage={langue}
                    listCountries={countries}
                    userInfo={userInfo}/>
            }
        </Fragment>
    )
}