import {
    useGetInscriptionQuery, useGetListMotosInscriptionQuery,
    useGetListTypeInscriptionQuery, useMaxMotoInscriptionQuery
} from "../../../feature/inscription/inscriptionApiSlice";
import {useGetAssurancesEvenementQuery} from "../../../feature/assurance/assuranceApiSlice";
import {useGetExperienceEvenementQuery} from "../../../feature/experience/experienceApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setEvenementid, setInscriptionid, setSkipFetchData} from "../../../feature/inscription/inscriptionSlice";
import {useNavigate, useParams} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import RegistrationEvent from "../RegistrationEvent";
import {Fragment} from "react";
import {selectCurrentTokenPayload} from "../../../feature/auth/authSlice";
import {useGetMotosQuery} from "../../../feature/moto/motoApiSlice";

export default () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenPayload = useSelector(selectCurrentTokenPayload);

    const sliceInscription = useSelector((state) => state.inscription);
    const {inscriptionId, evenementId} = useParams();

    useEffect(() => {
        dispatch(setInscriptionid(inscriptionId));
        dispatch(setEvenementid(evenementId));
        dispatch(setSkipFetchData(false));
    }, []);

    const {
        data: assurance,
        isSuccess: SucessAssurance,
        isError: isErreurAssurance,
    } = useGetAssurancesEvenementQuery(sliceInscription.evenementID, {skip: sliceInscription.skipFetchData});
    const {
        data: experience,
        isSuccess: SucessExperience,
        isError: isErreurExperience,
    } = useGetExperienceEvenementQuery(sliceInscription.evenementID, {skip: sliceInscription.skipFetchData});
    const {
        data: typeInsciption,
        isSuccess: SucessTypeInsciption,
        isError: isErreurTypeInsciption,
    } = useGetListTypeInscriptionQuery(sliceInscription.evenementID, {skip: sliceInscription.skipFetchData});

    const {
        data: dossierInsciption,
        isSuccess: SucessDossierInsciption,
        isError: isErreurDossierInsciption,
    } = useGetInscriptionQuery(sliceInscription.inscriptionID, {skip: sliceInscription.skipFetchData});

    const {
        data: maxMotoInscription,
        isSuccess: SucessMaxMotoInscription,
        isError: isErreurMaxMotoInscription,
    } = useMaxMotoInscriptionQuery({skip: sliceInscription.skipFetchData}, {skip: sliceInscription.skipFetchData});

    const {
        data: listMotosDossier,
        isSuccess: SucessListMotosDossier,
        isError: isErreurListMotosDossier,
    } = useGetListMotosInscriptionQuery(sliceInscription.inscriptionID, {skip: sliceInscription.skipFetchData});

    const {
        data: listAllMotosDossier,
        isSuccess: SucessListAllMotosDossier,
        isError: isErreurListAllMotosDossier,
    } = useGetMotosQuery(tokenPayload.userID);

    const AllSucessLoad = SucessAssurance && SucessExperience && SucessTypeInsciption && SucessDossierInsciption && SucessMaxMotoInscription && SucessListMotosDossier && SucessListAllMotosDossier;

    const ErreurFetchData = isErreurMaxMotoInscription || isErreurAssurance || isErreurTypeInsciption || isErreurExperience || isErreurListMotosDossier || isErreurListAllMotosDossier;

    useEffect(() => {
        if (ErreurFetchData) {
            navigate('/500');
        }
        if (isErreurDossierInsciption) {
            navigate('/home');
        }
    }, [ErreurFetchData, isErreurDossierInsciption]);
    return (
        <Fragment>
            {!AllSucessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <RegistrationEvent
                    listAssurances={assurance}
                    listExperience={experience}
                    listTypeInscription={typeInsciption}
                    dossierInscription={dossierInsciption}
                    listMotosDossier={listMotosDossier}
                    listAllMotosUtilisateur={listAllMotosDossier}
                    maxMoto={maxMotoInscription}/>
            }
        </Fragment>
    )
}