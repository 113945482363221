import {Fragment} from "react";
import {Checkbox, FormControl, FormControlLabel, FormGroup} from "@mui/material";

const ListDisponibiliter = (props) => {

    let listDispo;

    const handleCheckDispo = (event, dispoID) => {
        const choice = { id: dispoID, choice: event.target.checked};
        props.selectDispo(choice);
    };

    listDispo = props.data.map(value => {
        return <FormControlLabel disabled={props.disable} key={value.id} control={<Checkbox checked={value.check} name={value.description} onClick={event => handleCheckDispo(event, value.id)}/>}
                                 label={value.description}/>
    });

    return(
        <Fragment>
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    {listDispo}
                </FormGroup>
            </FormControl>
        </Fragment>
    )
}

export default ListDisponibiliter;