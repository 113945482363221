import {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Chip, MenuItem} from "@mui/material";
import Box from "@mui/material/Box";
import ListMotoBenevole from "./ListMotoBenevole";
import CardAddMotoBenevole from "./CardAddMotoBenevole";
import {Alert} from "@mui/lab";
import Button from "@mui/material/Button";

export default (props) => {

    const [addMoto, setAddMoto] = useState(false);
    const [editMoto, setEditMoto] = useState(false);
    const [dataEditMoto, setDataEditMoto] = useState({});
    const [maxMotoAlert, setMaxMotoAlert] = useState(false);

    useEffect(() => {
        const {editMoto, maxMoto} = props.errorModal;
        setAddMoto(editMoto);
        setMaxMotoAlert(maxMoto);

    }, [props.errorModal]);

    let listMoto;

    listMoto = props.listSelectMoto.map(value => {
        return <MenuItem value={value.id} key={value.id}>{`${value.marque} ${value.modele}`}</MenuItem>
    });

    const handleAddMoto = () => {
        setEditMoto(false);
        setAddMoto(true);
    };

    const handleClosePanel = (data) => {
        setAddMoto(data);
    }

    const handleUpdateMoto = (data) => {
        props.editMoto(data);
    };

    const handleCreateMoto = (data) => {
        props.createMoto(data);
    };

    const handleDeleteMotoBenevole = (data) => {
        props.removeMotoBenevole(data);
    }

    const handleEditMoto = (idMoto) => {
        const objMoto = props.listMotoBenevole.filter(val => val.id === idMoto)[0];
        setDataEditMoto({...objMoto});
        setEditMoto(true);
        setAddMoto(true);
    };

    return (
        <Fragment>
            <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                <Button
                    disabled={props.disable}
                    variant="contained"
                    onClick={handleAddMoto}>Ajouter une moto</Button>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Box sx={{
                    textAlign: "center"
                }}>
                    <Chip id="input-number-moto"
                          label={`Nombres de motos maximum : ${props.maxMoto}`}
                          color={props.errorMoto ? "error" : "primary"}
                          variant="outlined"/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ListMotoBenevole
                    editMoto={handleEditMoto}
                    removeMoto={handleDeleteMotoBenevole}
                    listMoto={props.listMotoBenevole}
                    disable={props.disable}/>
            </Grid>
            {maxMotoAlert &&
                <Grid item xs={12} sm={8}>
                    <Alert onClose={() => {
                        setMaxMotoAlert(false)
                    }} severity="warning">Nombre de moto maximum atteint !</Alert>
                </Grid>
            }
            {addMoto &&
                <CardAddMotoBenevole
                    typeMoto={props.typeMoto}
                    openPanel={handleClosePanel}
                    edit={editMoto}
                    updateMoto={handleUpdateMoto}
                    createMoto={handleCreateMoto}
                    dataMoto={dataEditMoto}
                />}
        </Fragment>
    )
}