import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {Paper} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import OkMailConfirm from '../../file/picture/confirm-mail-ok.png';
import KoMailConfirm from '../../file/picture/confirm-mail-ko.png';
import {useMemo, Fragment, useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useConfirmMailMutation} from "../../feature/auth/authApiSlice";

const theme = createTheme();

const ConfirmMail = () => {

    const [isfetch, setIsfetch] = useState(false);
    const [error, setError] = useState(false);
    const [errorLibelle, setErrorLibelle] = useState('');

    const {search} = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    const [confirmMail] = useConfirmMailMutation();

    useEffect(() => {
        const fetchConfirmMail = async () => {
            await confirmMail(query.get("token")).unwrap()
                .then(() => {
                    setError(false);
                    setIsfetch(true);
                })
                .catch((errorConfirmMail) => {
                    if (errorConfirmMail.status) {
                        setErrorLibelle('Lien de confirmation mail invalide');
                        setError(true);
                        setIsfetch(true);
                    } else {
                        setErrorLibelle('Erreur Technique : Rééessayer ultérieurement');
                        setError(true);
                        setIsfetch(true);
                    }
                })
        };
        fetchConfirmMail();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Paper elevation={24} sx={{
                    pl: 2,
                    pr: 2,
                    height: 223
                }}>
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {isfetch &&
                            <Fragment>
                                <Typography paddingTop={2} component="h1" variant="h5" textAlign="center">
                                    Confirmation adresse mail
                                </Typography>
                                <Typography paddingLeft={2} paddingRight={2} paddingTop={2} textAlign={"center"}
                                            paddingBottom={2} fontSize={15} color={"gray"}>
                                    {!error ? 'Adresse-mail confirmer avec succès' : errorLibelle}
                                </Typography>
                                <img src={!error ? OkMailConfirm : KoMailConfirm} alt={error ? "OK" : "KO"} style={{
                                    width: 100,
                                    marginBottom: 20
                                }}/>
                            </Fragment>
                        }
                    </Box>
                </Paper>
            </Container>
        </ThemeProvider>
    )
};

export default ConfirmMail;