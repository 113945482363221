import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
    Alert,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    InputLabel,
    Select,
    Switch
} from "@mui/material";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import formUtils from "../../utils/formUtils";

export default (props) => {

    const [selectLicenceChecked, setSelectLicenceChecked] = useState(false);
    const [selectAssurance, setSelectAssurance] = useState('');
    const [pilotePrenom, setPilotePrenom] = useState('');
    const [piloteNom, setPiloteNom] = useState('');
    const [pilotePermis, setPilotePermis] = useState('');
    const [piloteLicence, setPiloteLicence] = useState('');
    const [piloteMail, setPiloteMail] = useState('');

    const [errorField, setErrorField] = useState({
        assurance: {
            error: false, message: ''
        },
        prenom: {
            error: false, message: ''
        },
        nom: {
            error: false, message: ''
        },
        numeroPermis: {
            error: false, message: ''
        },
        numeroLicence: {
            error: false, message: ''
        },
        mail: {
            error: false, message: ''
        },
    });

    useEffect(() => {
        const pilote = props.data;
        if (pilote.id.length > 0) {
            setPiloteNom(pilote.nom);
            setPilotePrenom(pilote.prenom);
            setSelectAssurance(pilote.assurance);
            setPiloteMail(pilote.mail);
            if (props.type === 'partage') {
                setPilotePermis(pilote.permis);
            }
            if (pilote.licence.length > 0) {
                setPiloteLicence(pilote.licence);
                setSelectLicenceChecked(true);
            }
        }
    }, [props.data]);

    useEffect(() => {
        const {errorField} = props;
        if (props.type === 'partage') {
            setErrorField({
                ...errorField,
                assurance: {
                    error: errorField["input-assurance-pilote-partage"].error,
                    message: errorField["input-assurance-pilote-partage"].message,
                },
                prenom: {
                    error: errorField["input-text-prenom-pilote-partage"].error,
                    message: errorField["input-text-prenom-pilote-partage"].message,
                },
                nom: {
                    error: errorField["input-text-nom-pilote-partage"].error,
                    message: errorField["input-text-nom-pilote-partage"].message,
                },
                numeroPermis: {
                    error: errorField["input-text-numero-permis-pilote-partage"].error,
                    message: errorField["input-text-numero-permis-pilote-partage"].message,
                },
                numeroLicence: {
                    error: errorField["input-text-numero-licence-pilote-partage"].error,
                    message: errorField["input-text-numero-licence-pilote-partage"].message,
                },
                mail: {
                    error: errorField["input-text-email-pilote-partage"].error,
                    message: errorField["input-text-email-pilote-partage"].message,
                }
            });
        } else {
            setErrorField({
                ...errorField,
                assurance: {
                    error: errorField["input-assurance-pilote-passager"].error,
                    message: errorField["input-assurance-pilote-passager"].message,
                },
                prenom: {
                    error: errorField["input-text-prenom-pilote-passager"].error,
                    message: errorField["input-text-prenom-pilote-passager"].message,
                },
                nom: {
                    error: errorField["input-text-nom-pilote-passager"].error,
                    message: errorField["input-text-nom-pilote-passager"].message,
                },
                numeroLicence: {
                    error: errorField["input-text-numero-licence-pilote-passager"].error,
                    message: errorField["input-text-numero-licence-pilote-passager"].message,
                },
                mail: {
                    error: errorField["input-text-email-pilote-passager"].error,
                    message: errorField["input-text-email-pilote-passager"].message,
                }
            });
        }
    }, [props.errorField]);

    const sendDateParent = (champ, data) => {
        return {champ: champ, content: data};
    };
    const handleChangeLicenceChecked = (event) => {
        setSelectLicenceChecked(event.target.checked);
        setPiloteLicence('');
        props.retrieveData(sendDateParent('checkLicence', event.target.checked));
    };
    const handleChangeAssurance = (event) => {
        setSelectAssurance(event.target.value);
        props.retrieveData(sendDateParent('assurance', event.target.value));
    };
    const handleChangeNom = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            setPiloteNom(event.target.value);
            props.retrieveData(sendDateParent('nom', event.target.value));
        }
    };
    const handleChangePrenom = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            setPilotePrenom(event.target.value);
            props.retrieveData(sendDateParent('prenom', event.target.value));
        }
    };
    const handleChangePermis = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            setPilotePermis(event.target.value);
            props.retrieveData(sendDateParent('permis', event.target.value));
        }
    };
    const handleChangeLicence = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            setPiloteLicence(event.target.value);
            props.retrieveData(sendDateParent('licence', event.target.value));
        }
    };
    const handleChangeMail = (event) => {
        setPiloteMail(event.target.value);
        props.retrieveData(sendDateParent('mail', event.target.value));
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name={`input-text-nom-pilote-${props.type === undefined ? 'passager' : props.type}`}
                            required
                            fullWidth
                            id="Nom"
                            label="Nom"
                            value={piloteNom}
                            onChange={handleChangeNom}
                            error={errorField.nom.error}
                            helperText={errorField.nom.error ? errorField.nom.message : ''}
                            disabled={props.disable}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name={`input-text-prenom-pilote-${props.type === undefined ? 'passager' : props.type}`}
                            required
                            fullWidth
                            id="prenom"
                            label="Prenom"
                            value={pilotePrenom}
                            onChange={handleChangePrenom}
                            error={errorField.prenom.error}
                            helperText={errorField.prenom.error ? errorField.prenom.message : ''}
                            disabled={props.disable}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name={`input-text-email-pilote-${props.type === undefined ? 'passager' : props.type}`}
                            required
                            fullWidth
                            label="E-mail"
                            value={piloteMail}
                            onChange={handleChangeMail}
                            error={errorField.mail.error}
                            helperText={errorField.mail.error ? errorField.mail.message : ''}
                            disabled={props.disable}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel error={errorField.assurance.error} required>Assurance</InputLabel>
                            <Select
                                name={`input-assurance-pilote-${props.type === undefined ? 'passager' : props.type}`}
                                label="Assurance"
                                value={selectAssurance}
                                onChange={handleChangeAssurance}
                                error={errorField.assurance.error}
                                disabled={props.disable}
                            >
                                {props.listAssurance}
                            </Select>
                            {errorField.assurance.error &&
                                <FormHelperText error={true}>{errorField.assurance.message}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {props.type === 'partage' &&
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                name={`input-text-numero-permis-pilote-${props.type === undefined ? 'passager' : props.type}`}
                                fullWidth
                                label="Numero de permis"
                                value={pilotePermis}
                                onChange={handleChangePermis}
                                error={errorField.numeroPermis.error}
                                helperText={errorField.numeroPermis.error ? errorField.numeroPermis.message : ''}
                                disabled={props.disable}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={12}>
                        <FormGroup>
                            <FormControlLabel
                                disabled={props.disable}
                                control={<Switch checked={selectLicenceChecked} onChange={handleChangeLicenceChecked}/>}
                                label="Il possède une licence"/>
                        </FormGroup>
                    </Grid>
                    {selectLicenceChecked &&
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                name={`input-text-numero-licence-pilote-${props.type === undefined ? 'passager' : props.type}`}
                                fullWidth
                                label="Licence"
                                value={piloteLicence}
                                onChange={handleChangeLicence}
                                error={errorField.numeroLicence.error}
                                helperText={errorField.numeroLicence.error ? errorField.numeroLicence.message : ''}
                                disabled={props.disable}
                            />
                        </Grid>}

                    <Grid item xs={12} sm={12}>
                        <Alert severity="warning">Merci d'indiquer l'adresse e-mail correcte du second conducteur ou du
                            passager side-car. Si cette adresse n'est pas associée à un compte existant, un e-mail sera
                            envoyé pour inviter la personne à créer un compte. Ceci permettra à notre gestionnaire
                            d'inscription de lier le compte au dossier concerné. Si un compte avec cette adresse e-mail
                            existe déjà, un e-mail d'information sera envoyé pour l'informer de la création d'un nouveau
                            dossier.</Alert>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};