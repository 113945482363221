import {Fragment, useEffect, useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {
    Backdrop, Checkbox, CircularProgress,
    Divider, FormControl,
    FormControlLabel,
    FormGroup, Snackbar, Switch, Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {useNavigate, useParams} from "react-router-dom";
import {
    useAddMotoToBenevoleMutation, useConfirmBenevoleMutation, useDeleteMotoToIBenevoleMutation, useGetBenevoleQuery,
    useGetListDisponibliterQuery,
    useGetListMotosBenevoleQuery, useGetListMotosNotBenevoleQuery,
    useGetListTShirtQuery, useSaveBenevoleMutation
} from "../../feature/benevole/benevoleApiSlice";
import ListTShirt from "../../components/formBenevole/ListTShirt";
import ListDisponibiliter from "../../components/formBenevole/ListDisponibiliter";
import {useListRepasEvenementQuery} from "../../feature/repas/repasApiSlice";
import ListRepas from "../../components/formBenevole/ListRepas";
import {useGetZorgaEvenementQuery} from "../../feature/evenement/evenementApiSlice";
import TextField from "@mui/material/TextField";
import ContactForm from "../../components/formBenevole/ContactForm";
import AssuranceForm from "../../components/formBenevole/AssuranceForm";
import {useGetAssurancesEvenementQuery} from "../../feature/assurance/assuranceApiSlice";
import MotoForm from "../../components/formBenevole/MotoForm";
import {useMaxMotoInscriptionQuery} from "../../feature/inscription/inscriptionApiSlice";
import {useAddMotoMutation, useGetTypesMotosEventQuery, usePutMotoMutation} from "../../feature/moto/motoApiSlice";
import {LoadingButton} from "@mui/lab";
import GroupeBenevole from "../../components/formBenevole/GroupeBenevole";
import {verifAlpha, verifErreurFormulaire} from "../../utils/formUtils";
import Alert from "@mui/material/Alert";
import * as Constants from "../../utils/ContantesDossier";

const theme = createTheme();

const RegistrationBenevole = () => {

    const [addMoto] = useAddMotoMutation();
    const [putMoto] = usePutMotoMutation();
    const [addMotoToBenevole] = useAddMotoToBenevoleMutation();
    const [deleteMotoToIBenevole] = useDeleteMotoToIBenevoleMutation();
    const [saveBenevole] = useSaveBenevoleMutation();
    const [confirmBenevole] = useConfirmBenevoleMutation();

    const {benevoleId, evenementId} = useParams();
    const navigate = useNavigate();

    const [urlData, setUrlData] = useState({
        benevoleID: null,
        evenementID: null
    });

    const [modeConsultation, setModeConsultation] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarError, setOpenSnackBarError] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [skipFetchData, setSkipFetchData] = useState(true);
    const [skipFetchDataUser, setSkipFetchDataUser] = useState(true);
    const [loadingGlobal, setLoadingGlobal] = useState(true);
    const [checkBoxValidation, setCheckBoxValidation] = useState(false);
    const [formInformation, setFormInformation] = useState({
        disponibiliter: [],
        repas: [],
        TShirt: '',
        numeroPermis: '',
        nomContact: '',
        prenomContact: '',
        telephoneContact: '',
        assurance: '',
        checkGroupe: false,
        groupe: {
            id: '',
            libelle: ''
        },
        messageComplementaire: '',
        openZorga: false,
        participationZorga: false
    });
    const [formInformationError, setFormInformationError] = useState({
        disponibiliter: false,
        repas: false,
        TShirt: false,
        numeroPermis: false,
        nomContact: false,
        prenomContact: false,
        telephoneContact: false,
        assurance: false,
        moto: false,
        nomGroupe: false
    });
    const [motoModal, setMotoModal] = useState({
        editMoto: false,
        maxMoto: false
    });

    useEffect(() => {
        setUrlData({
            ...urlData,
            benevoleID: benevoleId,
            evenementID: evenementId
        });
        setSkipFetchData(false);
    }, []);

    const {
        data: listDisponibiliter,
        isSuccess: SucessListDisponibiliter,
        isError: isErreurListDisponibiliter,
    } = useGetListDisponibliterQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listRepas,
        isSuccess: SucessListRepas,
        isError: isErreurListRepas,
    } = useListRepasEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listTShirt,
        isSuccess: SucessListTShirt,
        isError: isErreurListTShirt,
    } = useGetListTShirtQuery({skip: skipFetchData});

    const {
        data: serieZorga,
        isSuccess: SucessSerieZorga,
        isError: isErreurSerieZorga,
    } = useGetZorgaEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: assurance,
        isSuccess: SucessAssurance,
        isError: isErreurAssurance,
    } = useGetAssurancesEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listMotoBenevole,
        isSuccess: SucessListMotoBenevole,
        isError: isErreurListMotoBenevole,
    } = useGetListMotosBenevoleQuery(urlData.benevoleID, {skip: skipFetchData});

    const {
        data: listMotoSelectBenevole,
        isSuccess: SucessListMotoSelectBenevole,
        isError: isErreurListMotoSelectBenevole,
    } = useGetListMotosNotBenevoleQuery(urlData.benevoleID, {skip: skipFetchData});

    const {
        data: maxMotoInscription,
        isSuccess: SucessMaxMotoInscription,
        isError: isErreurMaxMotoInscription,
    } = useMaxMotoInscriptionQuery({skip: skipFetchData});

    const {
        data: typeMoto,
        isSuccess: SucessTypeMoto,
        isError: isErreurTypeMoto,
    } = useGetTypesMotosEventQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: dataBenevole,
        isSuccess: SucessDataBenevole,
        isError: isErreurDataBenevole,
        error: errorDataBenevole
    } = useGetBenevoleQuery(urlData.benevoleID, {skip: skipFetchDataUser});

    useEffect(() => {
        if (SucessListDisponibiliter && SucessListTShirt && SucessListRepas && SucessSerieZorga && SucessAssurance && SucessListMotoBenevole && SucessListMotoSelectBenevole && SucessMaxMotoInscription && SucessTypeMoto) {
            setSkipFetchDataUser(false);
            setFormInformation({
                ...formInformation,
                disponibiliter: listDisponibiliter.map(value => {
                    return {...value, check: false}
                }),
                repas: listRepas.map(value => {
                    return {
                        ...value, repas: value.repas.map(val => {
                            return {...val, check: false}
                        })
                    }
                })
            })
        }
    }, [SucessListDisponibiliter, SucessListTShirt, SucessListRepas, SucessSerieZorga, SucessAssurance, SucessListMotoBenevole, SucessListMotoSelectBenevole, SucessMaxMotoInscription, SucessTypeMoto]);

    useEffect(() => {
        if (isErreurTypeMoto || isErreurListMotoSelectBenevole || isErreurMaxMotoInscription || isErreurListMotoBenevole || isErreurAssurance || isErreurSerieZorga || isErreurListTShirt || isErreurListRepas || isErreurListDisponibiliter) {
            navigate('/500');
        }
    }, [isErreurTypeMoto, isErreurListMotoSelectBenevole, isErreurMaxMotoInscription, isErreurListMotoBenevole, isErreurAssurance, isErreurSerieZorga, isErreurListTShirt, isErreurListRepas, isErreurListDisponibiliter]);

    useEffect(() => {
        if (SucessSerieZorga && serieZorga != null) {
            setFormInformation({
                ...formInformation,
                openZorga: serieZorga.zorga
            })
        }
    }, [SucessSerieZorga, serieZorga]);

    useEffect(() => {
        if (SucessDataBenevole && dataBenevole != null && serieZorga != null) {
            const newDispo = [...formInformation.disponibiliter.map(value => {
                return {...value, check: dataBenevole.disponibiliters.filter(data => data.id === value.id).length === 1}
            })]
            const newRepas = [...formInformation.repas.map(value => {
                return {
                    ...value, repas: value.repas.map(val => {
                        return {...val, check: dataBenevole.repas.filter(data => data.id === val.id).length === 1}
                    })
                }
            })];

            if (dataBenevole.statut === Constants.STATUT_DOSSIER_INIT || dataBenevole.statut === Constants.STATUT_DOSSIER_IN_PROGRESS) {
                setModeConsultation(false);
            } else {
                setModeConsultation(true);
            }

            if (serieZorga.zorga) {
                setFormInformation({
                    ...formInformation,
                    disponibiliter: newDispo,
                    repas: newRepas,
                    TShirt: dataBenevole.tshirt === null ? '' : dataBenevole.tshirt.id,
                    checkGroupe: dataBenevole.groupe_pilote != null,
                    messageComplementaire: dataBenevole.commentaire != null ? dataBenevole.commentaire : '',
                    groupe: dataBenevole.groupe_pilote != null ? {
                        id: dataBenevole.groupe_pilote.id,
                        libelle: dataBenevole.groupe_pilote.libelle
                    } : {...formInformation.groupe},
                    participationZorga: dataBenevole.rouleur,
                    numeroPermis: dataBenevole.numeropermis != null ? dataBenevole.numeropermis : '',
                    assurance: dataBenevole.assurance != null ? dataBenevole.assurance.id : '',
                    nomContact: dataBenevole.nomcontact != null ? dataBenevole.nomcontact : '',
                    prenomContact: dataBenevole.prenomcontact != null ? dataBenevole.prenomcontact : '',
                    telephoneContact: dataBenevole.numerocontact != null ? dataBenevole.numerocontact : '',
                });
            } else {
                setFormInformation({
                    ...formInformation,
                    disponibiliter: newDispo,
                    repas: newRepas,
                    TShirt: dataBenevole.tshirt === null ? '' : dataBenevole.tshirt.id,
                    checkGroupe: dataBenevole.groupe_pilote != null,
                    messageComplementaire: dataBenevole.commentaire != null ? dataBenevole.commentaire : '',
                    groupe: dataBenevole.groupe_pilote != null ? {
                        id: dataBenevole.groupe_pilote.id,
                        libelle: dataBenevole.groupe_pilote.libelle
                    } : {...formInformation.groupe}
                });
            }
            setLoadingGlobal(false);
        }
        if (errorDataBenevole != null && isErreurDataBenevole) {
            if (errorDataBenevole.status === 401) {
                navigate('/home');
            }
        }
    }, [SucessDataBenevole, dataBenevole, serieZorga, errorDataBenevole, isErreurDataBenevole]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoadingSave(true);
        if (checkBoxValidation) {
            if (verifErreurFormulaire(verifFormulaire())) {
                await handleConfirmBenevole();
            } else {
                setLoadingSave(false);
            }
        } else {
            await submitSaveBenevole();
        }
    };
    const handleConfirmBenevole = async () => {
        try {
            await saveDataBenevole();
            await confirmBenevole(urlData.benevoleID).unwrap();
            navigate('/home');
        } catch (e) {
            setLoadingSave(false);
            setOpenSnackBarError(true);
        }
    };
    const submitSaveBenevole = async () => {
        try {
            await saveDataBenevole();
            setLoadingSave(false);
            setOpenSnackBar(true);
        } catch (e) {
            setLoadingSave(false);
            setOpenSnackBarError(true);
        }
    };
    const saveDataBenevole = async () => {
        const obj_save_benevole = {
            benevoleID: urlData.benevoleID,
            data: {
                numeropermis: formInformation.numeroPermis,
                nomcontact: formInformation.nomContact,
                prenomcontact: formInformation.prenomContact,
                numerocontact: formInformation.telephoneContact,
                commentaire: formInformation.messageComplementaire,
                assuranceid: formInformation.assurance,
                groupe: formInformation.checkGroupe ? formInformation.groupe : null,
                tshirtid: formInformation.TShirt,
                rouleur: formInformation.participationZorga,
                repas: exportRepas(formInformation.repas),
                disponibiliter: exportDisponiblilter(formInformation.disponibiliter)
            }
        };
        await saveBenevole(obj_save_benevole).unwrap();
    };
    const handleCreateMoto = async (data) => {
        try {
            const newMoto = await addMoto(data).unwrap();
            await addMotoToBenevole({benevoleID: urlData.benevoleID, motoID: newMoto.id}).unwrap();
            setMotoModal({...motoModal, editMoto: false});
        } catch (e) {
            if (e.status === 405) {
                setMotoModal({...motoModal, editMoto: false, maxMoto: true});
            }
        }
    };
    const handleDeleteMotoBenevole = async (data) => {
        try {
            await deleteMotoToIBenevole({benevoleID: urlData.benevoleID, motoID: data}).unwrap();
            setMotoModal({...motoModal, maxMoto: true});
        } catch (e) {
            setOpenSnackBarError(true);
        }
    };
    const handleEditMotoFetch = async (dataMoto) => {
        try {
            await putMoto({data: dataMoto.data, motoID: dataMoto.motoID}).unwrap();
            setMotoModal({...motoModal, editMoto: false});
        } catch (e) {
            setOpenSnackBarError(true);
        }
    };
    const handleSelectDispo = (dataDispo) => {
        const result = [...formInformation.disponibiliter.map(value => {
            if (value.id === dataDispo.id) {
                return {...value, check: dataDispo.choice}
            } else {
                return value;
            }
        })];
        setFormInformation({
            ...formInformation,
            disponibiliter: result
        });
    };
    const handleSelectRepas = (dataDispo) => {
        const result = [...formInformation.repas.map(value => {
            return {
                ...value, repas: value.repas.map(val => {
                    if (val.id === dataDispo.id) {
                        return {...val, check: dataDispo.choice}
                    } else {
                        return val
                    }
                })
            }
        })];
        setFormInformation({
            ...formInformation,
            repas: result
        });
    };
    const handleSelectTShirt = (dataTShirt) => {
        setFormInformation({
            ...formInformation,
            TShirt: dataTShirt
        });
    };
    const handleChangeNumeroPermis = (event) => {
        if (verifAlpha(event.target.value)) {
            setFormInformation({
                ...formInformation,
                numeroPermis: event.target.value
            });
        }
    };
    const handleSelectAssurance = (dataAssurance) => {
        setFormInformation({
            ...formInformation,
            assurance: dataAssurance
        });
    };
    const handleChangeContact = (dataContactForm) => {
        if (verifAlpha(dataContactForm.data)) {
            if (dataContactForm.champs === 'prenom') {
                setFormInformation({
                    ...formInformation,
                    prenomContact: dataContactForm.data
                });
            } else if (dataContactForm.champs === 'nom') {
                setFormInformation({
                    ...formInformation,
                    nomContact: dataContactForm.data
                });
            } else if (dataContactForm.champs === 'numero') {
                setFormInformation({
                    ...formInformation,
                    telephoneContact: dataContactForm.data.replace(/\s+/g, '')
                });
            }
        }
    };
    const handleChangeNomGroupe = (dataGroupe) => {
        setFormInformation({
            ...formInformation,
            groupe: {
                id: formInformation.groupe.id,
                libelle: dataGroupe
            }
        });
    };
    const handleChangeMessageComplementaire = (event) => {
        if (verifAlpha(event.target.value)) {
            setFormInformation({
                ...formInformation,
                messageComplementaire: event.target.value
            });
        }
    };
    const exportDisponiblilter = (stateDispo) => {
        let arrayOutput = [];
        stateDispo.forEach(value => {
            if (value.check) {
                arrayOutput.push(value.id);
            }
        });
        return arrayOutput;
    };
    const exportRepas = (stateRepas) => {
        let arrayOutput = [];
        stateRepas.map(value => {
            value.repas.map(val => {
                if (val.check) {
                    arrayOutput.push(val.id);
                }
            })
        })
        return arrayOutput;
    }
    const verifFormulaire = () => {
        let ObjVerif;
        if (!formInformation.participationZorga) {
            ObjVerif = {
                TShirt: formInformation.TShirt.length === 0,
                nomGroupe: formInformation.checkGroupe ? formInformation.groupe.libelle.length === 0 : false
            }
        } else {
            ObjVerif = {
                TShirt: formInformation.TShirt.length === 0,
                moto: listMotoBenevole.length === 0,
                numeroPermis: formInformation.numeroPermis.length === 0,
                nomContact: formInformation.nomContact.length === 0,
                prenomContact: formInformation.prenomContact.length === 0,
                telephoneContact: formInformation.telephoneContact.length === 0,
                assurance: formInformation.assurance.length === 0,
                nomGroupe: formInformation.checkGroupe ? formInformation.groupe.libelle.length === 0 : false
            }
        }
        setFormInformationError({
            ...formInformationError,
            ...ObjVerif
        });
        return ObjVerif;
    };
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    const handleCheckZorga = (event) => {
        setFormInformation({
            ...formInformation,
            participationZorga: event.target.checked,
            numeroPermis: '',
            nomContact: '',
            prenomContact: '',
            telephoneContact: '',
            assurance: ''
        });
    };
    const handleCloseSnackBarError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBarError(false);
    };
    const handleChangeCheckValidation = (event) => {
        setCheckBoxValidation(event.target.checked);
    };

    return (
        <Fragment>
            {loadingGlobal ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="md">
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography align='center' variant="h4">Benevole</Typography>
                                </Grid>
                            </Grid>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}>Disponibiliter</Divider>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <ListDisponibiliter data={formInformation.disponibiliter}
                                                        selectDispo={handleSelectDispo}
                                                        disable={modeConsultation}/>
                                </Grid>
                            </Grid>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}>Repas</Divider>
                            <Grid container spacing={2}>
                                {formInformation.repas.map(value => {
                                    return (
                                        <Grid key={value.id} item xs={12} sm={6}>
                                            <ListRepas data={value} selectRepas={handleSelectRepas}
                                                       disable={modeConsultation}/>
                                        </Grid>)
                                })}
                            </Grid>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}>TShirt</Divider>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <ListTShirt
                                        selected={formInformation.TShirt}
                                        data={listTShirt}
                                        selectTShirt={handleSelectTShirt}
                                        errorMoto={formInformationError.TShirt}
                                        disable={modeConsultation}/>
                                </Grid>
                            </Grid>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}>Complément</Divider>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            disabled={modeConsultation}
                                            control={<Switch checked={formInformation.checkGroupe}
                                                             onChange={event => setFormInformation({
                                                                 ...formInformation,
                                                                 checkGroupe: event.target.checked,
                                                                 groupe: {...formInformation.groupe, libelle: ''}
                                                             })}/>}
                                            label="Je fais parti d'un groupe (amis, famille, ...)"/>
                                    </FormGroup>
                                </Grid>
                                {formInformation.checkGroupe &&
                                    <Grid item xs={12} sm={12}>
                                        <GroupeBenevole
                                            nameGroupe={formInformation.groupe.libelle}
                                            changeGroupe={handleChangeNomGroupe}
                                            errorGroupe={formInformationError.nomGroupe}
                                            disable={modeConsultation}
                                        />
                                    </Grid>}
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        name="Message"
                                        fullWidth
                                        id="prenom"
                                        label="Message"
                                        multiline
                                        onChange={handleChangeMessageComplementaire}
                                        value={formInformation.messageComplementaire}
                                        disabled={modeConsultation}
                                    />
                                </Grid>
                            </Grid>
                            {formInformation.openZorga &&
                                <Fragment>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 2
                                    }}>Serie Zorga</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    disabled={modeConsultation}
                                                    control={<Switch checked={formInformation.participationZorga}
                                                                     onChange={handleCheckZorga}/>}
                                                    label="Je souhaite participer a la série zorga"/>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    {formInformation.participationZorga &&
                                        <Fragment>
                                            <Divider textAlign="left" sx={{
                                                marginTop: 2,
                                                marginBottom: 2
                                            }}>Moto</Divider>
                                            <Grid container justifyContent="center" spacing={2}>
                                                <MotoForm
                                                    errorModal={motoModal}
                                                    listSelectMoto={listMotoSelectBenevole}
                                                    listMotoBenevole={listMotoBenevole}
                                                    maxMoto={maxMotoInscription.number}
                                                    typeMoto={typeMoto}
                                                    createMoto={handleCreateMoto}
                                                    removeMotoBenevole={handleDeleteMotoBenevole}
                                                    editMoto={handleEditMotoFetch}
                                                    errorMoto={formInformationError.moto}
                                                    disable={modeConsultation}
                                                />
                                            </Grid>
                                            <Divider textAlign="left" sx={{
                                                marginTop: 2,
                                                marginBottom: 2
                                            }}>Information</Divider>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        required
                                                        name="Numero de permis / CASM / BSR"
                                                        fullWidth
                                                        id="Numero_Permis"
                                                        label="Numero de permis / CASM / BSR"
                                                        onChange={handleChangeNumeroPermis}
                                                        value={formInformation.numeroPermis}
                                                        error={formInformationError.numeroPermis}
                                                        helperText={formInformationError.numeroPermis ? 'Veuillez renseigner numéro de permis' : ''}
                                                        disabled={modeConsultation}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Divider textAlign="left" sx={{
                                                marginTop: 2,
                                                marginBottom: 2
                                            }}>Contact</Divider>
                                            <Grid container spacing={2}>
                                                <ContactForm
                                                    changeContact={handleChangeContact}
                                                    dataContact={{
                                                        nom: formInformation.nomContact,
                                                        prenom: formInformation.prenomContact,
                                                        telephone: formInformation.telephoneContact
                                                    }}
                                                    errorContact={{
                                                        nom: formInformationError.nomContact,
                                                        prenom: formInformationError.prenomContact,
                                                        telephone: formInformationError.telephoneContact
                                                    }}
                                                    disable={modeConsultation}
                                                />
                                            </Grid>
                                            <Divider textAlign="left" sx={{
                                                marginTop: 2,
                                                marginBottom: 2
                                            }}>Assurance</Divider>
                                            <Grid container spacing={2}>
                                                <AssuranceForm
                                                    selected={formInformation.assurance}
                                                    listAssurance={assurance}
                                                    selectAssurance={handleSelectAssurance}
                                                    errorAssurance={formInformationError.assurance}
                                                    disable={modeConsultation}
                                                />
                                            </Grid>
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                            {!modeConsultation &&
                                <Fragment>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 2
                                    }}>Validation</Divider>
                                    <Grid container spacing={2} sx={{
                                        marginBottom: 2
                                    }}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl component="fieldset" variant="standard">
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox checked={checkBoxValidation}
                                                                                         onChange={handleChangeCheckValidation}/>}
                                                                      label="J'ai compris que la création de mon dossier en ligne ne constitue pas une confirmation de ma participation au Trofeo Rosso"/>
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} justifyContent="center" sx={{
                                        mt: 1
                                    }}>
                                        <Grid item xs={12} sm={5}>
                                            <LoadingButton
                                                fullWidth
                                                loading={loadingSave}
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                            >
                                                {checkBoxValidation ? 'Confirmer' : 'Sauvegarder'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            }
                            <Grid container sx={{
                                marginTop: 5,
                            }}>
                            </Grid>
                        </Box>
                    </Container>
                    <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" sx={{width: '100%'}}>
                            Enregistrement effectuer avec succès !
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnackBarError} autoHideDuration={5000} onClose={handleCloseSnackBarError}>
                        <Alert onClose={handleCloseSnackBarError} severity="error" sx={{width: '100%'}}>
                            Erreur interne réessayer ultérieurement !
                        </Alert>
                    </Snackbar>
                </ThemeProvider>
            }
        </Fragment>
    )
};
export default RegistrationBenevole;