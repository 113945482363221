import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Fragment, useCallback, useRef, useState, useMemo, useEffect} from "react";
import {Alert, CircularProgress, Paper} from "@mui/material";
import CheckOk from '../../file/picture/checked.png';
import CheckKo from '../../file/picture/cancel.png';
import {useLocation} from "react-router-dom";
import styles from './NewPassword.module.css';
import ValidationPassword from "../../components/sign-up/ValidationPassword";

const theme = createTheme();

const NewPassword = () => {

    // récupération dans l'URL
    const {search} = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    const newPassword = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [Error, setError] = useState(false);
    const [viewVerifPassword, setviewVerifPassword] = useState(false);
    const [passwordValidLenght, setpasswordValidLenght] = useState(false);
    const [passwordValidLowerCase, setpasswordValidLowerCase] = useState(false);
    const [passwordValidUpperCase, setpasswordValidUpperCase] = useState(false);
    const [passwordValidnumber, setpasswordValidnumber] = useState(false);
    const [libelleError, setlibelleError] = useState('');
    const [sucessAlert, setsucessAlert] = useState(false);
    const [validToken, setvalidToken] = useState(false);
    const [isBusy, setIsBusy] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(false);
        setviewVerifPassword(false);
        // validation du mot de passe
        confirmPassword();
        if (!validPolicie) {
            await SendNewPassword();
        }
    };
    //password
    const passwordOnBlur = () => {
        setviewVerifPassword(false)
    };
    const passwordOnFocus = () => {
        setviewVerifPassword(true);
    };
    const validationPassword = () => {
        const enteredNewPassword = newPassword.current.value;

        /[a-z]+/.test(enteredNewPassword) ? setpasswordValidLowerCase(true) : setpasswordValidLowerCase(false);
        /[A-Z]+/.test(enteredNewPassword) ? setpasswordValidUpperCase(true) : setpasswordValidUpperCase(false);
        /[1-9]+/.test(enteredNewPassword) ? setpasswordValidnumber(true) : setpasswordValidnumber(false);
        String(enteredNewPassword).length >= 8 ? setpasswordValidLenght(true) : setpasswordValidLenght(false);
    };
    const confirmPassword = () => {
        if (validPolicie) {
            setlibelleError('Politique de mot de passe incorrecte');
            setError(true);
            setviewVerifPassword(true);
        }
    };

    useEffect(async () => {
        try {
            const responseValidToken = await fetch(`${process.env.REACT_APP_API_URL}/auth/password-reset/check/${query.get("userid")}/${query.get("token")}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            });

            if (!responseValidToken.ok) {
                setIsLoading(false);
                setlibelleError('Erreur interne');
                setError(true);
            }

            const responseToken = await responseValidToken.json();
            setvalidToken(responseToken.valid);
            setIsBusy(true);
        } catch (e) {
            setError(true);
            setIsLoading(false);
        }
    }, []);

    const SendNewPassword = useCallback(async () => {
        setIsLoading(true);
        try {
            const responseEmailExist = await fetch(`${process.env.REACT_APP_API_URL}/auth/password-reset/${query.get("userid")}/${query.get("token")}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    newpassword: newPassword.current.value
                })
            });
            if (responseEmailExist.status === 400) {
                setIsLoading(false);
                setlibelleError('liens invalide ou expiré');
                setError(true);
                return;
            }

            if (!responseEmailExist.ok) {
                setIsLoading(false);
                setlibelleError('Erreur interne');
                setError(true);
            } else {
                setsucessAlert(true);
            }
            setIsLoading(false);

        } catch (e) {
            setError(true);
            setIsLoading(false);
        }
    }, []);

    const validPolicie = !passwordValidnumber || !passwordValidUpperCase || !passwordValidLowerCase || !passwordValidLenght;

    return (
        <ThemeProvider theme={theme}>
            {isBusy && <Container component="main" maxWidth="xs">
                <Paper elevation={24} sx={{
                    pl: 2,
                    pr: 2,
                }}>
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography paddingTop={2} component="h2" variant="h5">
                            Mot de passe oublié
                        </Typography>
                        {validToken &&
                            <Fragment>
                                <Typography paddingTop={2} textAlign={"center"} variant="body1"
                                            paddingLeft={2} paddingRight={2}>
                                    Saisissez l'adresse email que vous utilisez habituellement pour vous connecter
                                </Typography>
                                <Typography paddingLeft={2} paddingRight={2} paddingTop={2} textAlign={"center"}
                                            fontSize={15} color={"gray"}>
                                    Un email vous sera envoyé pour réinitialiser votre mot de passe
                                </Typography>
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1, width: 1}}>
                                    {isLoading && <CircularProgress color="success"/>}
                                    {Error && <Alert sx={{width: '100%'}} severity="error">{libelleError}</Alert>}
                                    {sucessAlert &&
                                        <Alert sx={{width: 1}} severity="success">Mot de passe modifier avec
                                            succès</Alert>}
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="Nouveau Mot de Passe"
                                                type="password"
                                                id="password"
                                                inputRef={newPassword}
                                                onFocus={passwordOnFocus}
                                                onBlur={passwordOnBlur}
                                                onChange={validationPassword}
                                            />
                                        </Grid>
                                        {viewVerifPassword &&
                                            <Grid item xs={12} sm={12}>
                                                <Box sx={{
                                                    border: '2px solid black',
                                                    borderRadius: 2,
                                                    p: 1,
                                                }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body1">Politique de mot de passe
                                                                : </Typography>
                                                        </Grid>
                                                        <Grid item xs={11} sm={11}>
                                                            <Typography>Longueur du mot de passe (8) </Typography>
                                                        </Grid>
                                                        <Grid item xs={1} sm={1}>
                                                            {passwordValidLenght && <img alt="test" src={CheckOk}/>}
                                                            {!passwordValidLenght && <img alt="test" src={CheckKo}/>}
                                                        </Grid>
                                                        <Grid item xs={11} sm={11}>
                                                            <Typography>Nombre de caractère minuscule (1)</Typography>
                                                        </Grid>
                                                        <Grid item xs={1} sm={1}>
                                                            {passwordValidLowerCase && <img alt="test" src={CheckOk}/>}
                                                            {!passwordValidLowerCase && <img alt="test" src={CheckKo}/>}
                                                        </Grid>
                                                        <Grid item xs={11} sm={11}>
                                                            <Typography>Nombre de caractère majuscule (1)</Typography>
                                                        </Grid>
                                                        <Grid item xs={1} sm={1}>
                                                            {passwordValidUpperCase && <img alt="test" src={CheckOk}/>}
                                                            {!passwordValidUpperCase && <img alt="test" src={CheckKo}/>}
                                                        </Grid>
                                                        <Grid item xs={11} sm={11}>
                                                            <Typography>Nombre de chiffre (1)</Typography>
                                                        </Grid>
                                                        <Grid item xs={1} sm={1}>
                                                            {passwordValidnumber && <img alt="test" src={CheckOk}/>}
                                                            {!passwordValidnumber && <img alt="test" src={CheckKo}/>}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>}
                                        <Grid item xs={12} sm={12}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{mt: 3, mb: 2}}
                                            >
                                                Confirmer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Fragment>}
                        {!validToken &&
                            <Fragment>
                                <Typography paddingTop={2} textAlign={"center"} variant="body1"
                                            paddingLeft={2} paddingRight={2}>
                                    Liens invalid ou expiré
                                </Typography>
                            </Fragment>
                        }
                    </Box>
                </Paper>
            </Container>}
        </ThemeProvider>
    );
}

export default NewPassword;