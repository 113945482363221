import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {logOut, setAcessToken} from '../../feature/auth/authSlice';
import PublicURL from '../config/publicURL';
import jwtDecode from "jwt-decode";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    // credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result;
    const auth = api.getState().auth;

    if (auth.token) {
        const decodedToken = jwtDecode(auth.token);
        let currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            const refreshResult = await baseQuery({
                url: `auth/refreshtoken/`,
                method: 'POST',
                body: {'refreshtoken': auth.refreshToken}
            }, api, extraOptions);
            if (refreshResult?.data) {
                api.dispatch(setAcessToken({...refreshResult.data}));
                result = await baseQuery(args, api, extraOptions);
            } else {
                api.dispatch(logOut());
            }
        } else {
            result = await baseQuery(args, api, extraOptions);
        }
    } else {
        if (PublicURL.filter(url => url === api.endpoint).length === 1) {
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})