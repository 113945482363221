import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import BlocMenu from "../../components/mon-compte/BlocMenu";
import {useState} from "react";
import InformationPersonnel from "../../components/mon-compte/InformationPersonnel";
import ChangerMotDePasse from "../../components/mon-compte/ChangerMotDePasse";

const theme = createTheme();

const MonCompte = (props) => {

    const {listMotoClub, listLanguage, listCountries, userInfo} = props;

    const [selectedMenu, setSelectedMenu] = useState({
        infoPerso: true,
        historique: false,
        motDePasse: false
    });

    const handleSelectMenu = (menuKey) => {
        const newState = Object.keys(selectedMenu).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});

        newState[menuKey] = true;

        setSelectedMenu(newState);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                component="main"
                sx={{
                    backgroundColor: '#e6e6e6',
                    flexGrow: 1,
                    overflow: 'auto',
                    marginBottom: 4
                }}
            >
                <CssBaseline/>
                <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper
                                variant="outlined"
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderColor: '#B2B2B2'

                                }}
                            >
                                <BlocMenu
                                    nom={userInfo.nom}
                                    prenom={userInfo.prenom}
                                    selectedMenu={selectedMenu}
                                    changeMenu={handleSelectMenu}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper
                                variant="outlined"
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderColor: '#B2B2B2'
                                }}
                            >
                                {selectedMenu.infoPerso &&
                                    <InformationPersonnel
                                        userInfo={userInfo}
                                        listCountries={listCountries}
                                        listLanguage={listLanguage}
                                        listMotoClub={listMotoClub}/>}
                                {selectedMenu.motDePasse &&
                                    <ChangerMotDePasse/>}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </ThemeProvider>
    )
};

export default MonCompte;