import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CardStatutRemboursement from "./CardStatutRemboursement";
import moment from "moment";

export default (props) => {

    const handleClose = () => {
        props.close();
    };

    return (
        <Dialog open={true} fullWidth maxWidth="xs" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Information Remboursement</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sx={{textAlign: 'center'}}>
                                Montant :
                            </Grid>
                            <Grid item xs={8} sx={{fontWeight: 'bold', textAlign: 'center'}}>
                                {props.data.montant} €
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sx={{textAlign: 'center'}}>
                                Type :
                            </Grid>
                            <Grid item xs={8} sx={{fontWeight: 'bold', textAlign: 'center'}}>
                                {props.data.type}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sx={{textAlign: 'center'}}>
                                Date :
                            </Grid>
                            <Grid item xs={8} sx={{fontWeight: 'bold', textAlign: 'center'}}>
                                {moment(props.data.date).locale('fr').format('DD/MM/YYYY')}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sx={{textAlign: 'center'}}>
                                Statut :
                            </Grid>
                            <Grid item xs={8}>
                                <CardStatutRemboursement statut={props.data.statut}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="error">Fermer</Button>
            </DialogActions>
        </Dialog>
    )
}