import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {MuiTelInput} from "mui-tel-input";
import {Fragment, useEffect, useState} from "react";

const ContactForm = (props) => {

    const [nomContact, setNomContact] = useState('');
    const [prenomContact, setPrenomContact] = useState('');
    const [numeroContact, setNumeroContact] = useState('');

    const handleChangePrenom = (event) => {
        props.changeContact({
            champs: 'prenom',
            data: event.target.value
        });
    };

    const handleChangeNom = (event) => {
        props.changeContact({
            champs: 'nom',
            data: event.target.value
        });
    };

    const handleChangeNumero = (event) => {
        props.changeContact({
            champs: 'numero',
            data: event
        });
    };

    useEffect( () => {
        setNomContact(props.dataContact.nom);
        setPrenomContact(props.dataContact.prenom);
        setNumeroContact(props.dataContact.telephone);
    },[props.dataContact]);

    return (
        <Fragment>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="Prenom"
                    fullWidth
                    id="prenom_contact"
                    label="Prenom"
                    value={prenomContact}
                    onChange={handleChangePrenom}
                    error={props.errorContact.prenom}
                    helperText={props.errorContact.prenom ? 'Veuillez renseigner le prénom du contact' : ''}
                    required
                    disabled={props.disable}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="Nom"
                    fullWidth
                    id="nom_contact"
                    label="Nom"
                    value={nomContact}
                    onChange={handleChangeNom}
                    error={props.errorContact.nom}
                    helperText={props.errorContact.nom ? 'Veuillez renseigner le nom du contact' : ''}
                    required
                    disabled={props.disable}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <MuiTelInput
                    fullWidth
                    id="telephone_contact"
                    label="Numero de Téléphone"
                    value={numeroContact}
                    onChange={handleChangeNumero}
                    onlyCountries={['FR', 'BE', 'IT', 'ES', 'GB', 'CH', 'IE', 'NL', 'LU']}
                    langOfCountryName="fr"
                    defaultCountry="FR"
                    forceCallingCode
                    error={props.errorContact.telephone}
                    helperText={props.errorContact.telephone ? 'Veuillez renseigner le numero du contact' : ''}
                    required
                    disabled={props.disable}
                />
            </Grid>
        </Fragment>
    )
};

export default ContactForm;