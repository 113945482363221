import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckOk from "../../file/picture/checked.png";
import CheckKo from "../../file/picture/cancel.png";
import {useEffect, useState} from "react";
import formUtils from "../../utils/formUtils";

export default (props) => {

    const [passwordValidLenght, setpasswordValidLenght] = useState(false);
    const [passwordValidLowerCase, setpasswordValidLowerCase] = useState(false);
    const [passwordValidUpperCase, setpasswordValidUpperCase] = useState(false);
    const [passwordValidnumber, setpasswordValidnumber] = useState(false);

    useEffect(() => {
        formUtils.passwordPolicieRegexLowerCase().test(props.password) ? setpasswordValidLowerCase(true) : setpasswordValidLowerCase(false);
        formUtils.passwordPolicieRegexUpperCase().test(props.password) ? setpasswordValidUpperCase(true) : setpasswordValidUpperCase(false);
        formUtils.passwordPolicieRegexNumber().test(props.password) ? setpasswordValidnumber(true) : setpasswordValidnumber(false);
        String(props.password).length >= formUtils.passwordPolicieLenght() ? setpasswordValidLenght(true) : setpasswordValidLenght(false);
    }, [props.password]);

    return (
        <Grid item xs={12} sm={12}>
            <Box sx={{
                border: '2px solid black',
                borderRadius: 2,
                p: 1,
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body1">Politique de mot de passe
                            : </Typography>
                    </Grid>
                    <Grid item xs={11} sm={11}>
                        <Typography>Longueur du mot de passe (8) </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        {passwordValidLenght && <img alt="test" src={CheckOk}/>}
                        {!passwordValidLenght && <img alt="test" src={CheckKo}/>}
                    </Grid>
                    <Grid item xs={11} sm={11}>
                        <Typography>Nombre de caractère minuscule (1)</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        {passwordValidLowerCase && <img alt="test" src={CheckOk}/>}
                        {!passwordValidLowerCase && <img alt="test" src={CheckKo}/>}
                    </Grid>
                    <Grid item xs={11} sm={11}>
                        <Typography>Nombre de caractère majuscule (1)</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        {passwordValidUpperCase && <img alt="test" src={CheckOk}/>}
                        {!passwordValidUpperCase && <img alt="test" src={CheckKo}/>}
                    </Grid>
                    <Grid item xs={11} sm={11}>
                        <Typography>Nombre de chiffre (1)</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        {passwordValidnumber && <img alt="test" src={CheckOk}/>}
                        {!passwordValidnumber && <img alt="test" src={CheckKo}/>}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}