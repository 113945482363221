import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {MuiTelInput} from 'mui-tel-input'
import Grid from "@mui/material/Grid";
import * as Constants from "../../utils/ContantesDossier";
import {
    Card,
    CardContent,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Switch
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardAddMoto from "../../components/FormInscription/CardAddMoto";
import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect, useState} from "react";
import ListMotoInscription from "../../components/FormInscription/ListMotoInscription";
import {useConfirmInscriptionMutation, useSaveInscriptionMutation} from "../../feature/inscription/inscriptionApiSlice";
import {
    setAncienneParticipation,
    setAssurancePilote,
    setCheckLicence,
    setCompetition,
    setDepartArreter,
    setEditionMoto,
    setEmptyGroup,
    setEmptyPassagerSideData,
    setEmptySecondGuidon,
    setExperiencePilote,
    setGroupeAmis,
    setInscription,
    setMessageComplementaire,
    setNomContact,
    setNumeroContact,
    setNumeroLicence,
    setPanelMoto,
    setPartageInscription,
    setPassagerSide,
    setPassagerSideData,
    setPermis,
    setPrenomContact,
    setRoulage,
    setSecondGuidon,
    setStage,
    setSuiviMarshall,
    setTypeInscription
} from "../../feature/inscription/inscriptionSlice";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import AddPilote from "../../components/FormInscription/AddPilote";
import {useNavigate} from "react-router-dom";
import Alert from '@mui/material/Alert';
import GroupeInscription from "../../components/FormInscription/GroupeInscription";
import formUtils from "../../utils/formUtils";
import Button from "@mui/material/Button";
import * as ConstanteNotification from "../../utils/ConstantesNotificationHomePage";

const theme = createTheme();

export default (props) => {

    const dispatch = useDispatch();
    const sliceInscription = useSelector((state) => state.inscription);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setInscription(props.dossierInscription));
    }, []);

    useEffect(() => {
        setNombreMotoInscription(props.listMotosDossier.length);
        if (props.listMotosDossier.length <= props.maxMoto.number) {
            setMaxMotoAlert(false);
        }
    }, [props.listMotosDossier]);
    const displayMoto = sliceInscription.addmoto;

    const [saveInscription, {isLoading: isLoadingSaveInscription}] = useSaveInscriptionMutation();
    const [confirmInscription, {isLoading: isLoadingConfirmInscription}] = useConfirmInscriptionMutation();

    const [checkBoxValidation, setCheckBoxValidation] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarError, setOpenSnackBarError] = useState(false);

    const [maxMotoAlert, setMaxMotoAlert] = useState(false);

    const [errorField, setErrorField] = useState({
        'select-type-inscription': {
            error: false, message: ''
        },
        'numero-permis-pilote-principal': {
            error: false, message: ''
        },
        'experience-pilote-principal': {
            error: false, message: ''
        },
        'assurance-pilote-principal': {
            error: false, message: ''
        },
        'nom-contact-principal': {
            error: false, message: ''
        },
        'prenom-contact-principal': {
            error: false, message: ''
        },
        'numero-contact-principal': {
            error: false, message: ''
        },
        'licence-pilote-principal': {
            error: false, message: ''
        },
        'input-text-nom-pilote-partage': {
            error: false, message: ''
        },
        'input-text-prenom-pilote-partage': {
            error: false, message: ''
        },
        'input-text-email-pilote-partage': {
            error: false, message: ''
        },
        'input-assurance-pilote-partage': {
            error: false, message: ''
        },
        'input-text-numero-permis-pilote-partage': {
            error: false, message: ''
        },
        'input-text-numero-licence-pilote-partage': {
            error: false, message: ''
        },
        'input-text-nom-pilote-passager': {
            error: false, message: ''
        },
        'input-text-prenom-pilote-passager': {
            error: false, message: ''
        },
        'input-text-email-pilote-passager': {
            error: false, message: ''
        },
        'input-assurance-pilote-passager': {
            error: false, message: ''
        },
        'input-text-numero-licence-pilote-passager': {
            error: false, message: ''
        },
        'input-text-groupe-pilote': {
            error: false, message: ''
        },
        'input-number-moto': {
            error: false, message: ''
        },
    });

    const [nombreMotoInscription, setNombreMotoInscription] = useState(0);

    let listMoto, listAssurance, listExperience, listTypeInscription, listDesciptionAssurance;

    const filterMotoNotSelected = (listMoto) => {
        if (listMoto != null && props.listAllMotosUtilisateur != null) {
            return props.listAllMotosUtilisateur.filter(type => listMoto.filter(list => list.id === type.id).length === 0);
        } else {
            return []
        }
    };

    if (props.listMotos !== null) {
        listMoto = filterMotoNotSelected(props.listMotosDossier).map(value => {
            return <MenuItem value={value.id} key={value.id}>{`${value.marque} ${value.modele}`}</MenuItem>
        });
    }

    listAssurance = props.listAssurances.map(value => {
        return <MenuItem value={value.id} key={value.id}>{`${value.libellecourt} (${value.prix} €)`}</MenuItem>
    });

    listDesciptionAssurance = props.listAssurances.map(value => {
        return <li key={value.id}>{`${value.libellecourt}: ${value.libellelong}`}</li>
    });

    listExperience = props.listExperience.map(value => {
        return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
    });

    listTypeInscription = props.listTypeInscription.map(value => {
        return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
    });

    const handleAjouterNouvelleMoto = () => {
        dispatch(setPanelMoto(true));
        dispatch(setEditionMoto(false));
    };
    const handleChangeAssurance = async (event) => {
        dispatch(setAssurancePilote(event.target.value));
    };
    const handleChangeExperience = async (event) => {
        dispatch(setExperiencePilote(event.target.value));
    };
    const handleChangeTypeInscription = async (event) => {
        dispatch(setTypeInscription(event.target.value));
        const type = props.listTypeInscription.filter(val => val.id === event.target.value)[0];
        if (type.side) {
            dispatch(setPassagerSide(type.side));
        } else {
            dispatch(setPassagerSide(type.side));
            dispatch(setEmptyPassagerSideData());
        }
    };
    const handleChangeCheckValidation = (event) => {
        setCheckBoxValidation(event.target.checked);
    };
    const handleChangePartageGuidon = (event) => {
        dispatch(setPartageInscription(event.target.checked));
        if (!event.target.checked) {
            dispatch(setEmptySecondGuidon());
        }
    };
    const handleChangeDepartArreter = (event) => {
        dispatch(setDepartArreter(event.target.checked));
    };
    const handleChangePrenomContact = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            dispatch(setPrenomContact(event.target.value));
        }
    };
    const handleChangeNomContact = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            dispatch(setNomContact(event.target.value));
        }
    };
    const handleChangeNumerocontact = (event) => {
        if (formUtils.verifAlpha(event)) {
            dispatch(setNumeroContact(event));
        }
    };
    const handleDataPartageGuidon = (data) => {
        dispatch(setSecondGuidon(data));
    };
    const handleDataPassagerSide = (data) => {
        dispatch(setPassagerSideData(data));
    };
    const handleLicenceChecked = (event) => {
        dispatch(setCheckLicence(event.target.checked));
    };
    const handleChangeNumeroPermis = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            dispatch(setPermis(event.target.value));
        }
    };
    const handleChangeLicence = (event) => {
        dispatch(setNumeroLicence(event.target.value));
    };
    const handleChangeMessageComplementaire = (event) => {
        dispatch(setMessageComplementaire(event.target.value));
    };
    const handleChangeRoulage = (event) => {
        if (formUtils.verifAlpha(event.target.value)) {
            dispatch(setRoulage(event.target.value));
        }
    };
    const handleChangeStage = (event) => {
        dispatch(setStage(event.target.checked));
    };
    const handleChangeCompetition = (event) => {
        dispatch(setCompetition(event.target.checked));
    };
    const handleChangeOldParticipation = (event) => {
        dispatch(setAncienneParticipation(event.target.checked));
    };
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    const handleCloseSnackBarError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBarError(false);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (checkBoxValidation) {
            if (ValidationFormulaire()) {
                await handleConfirmInscription();
            }
        } else {
            await submitSaveInscription();
        }
    };
    const saveDataInscription = async () => {
        try {
            const obj_save_inscription = {
                inscriptionID: sliceInscription.inscriptionID,
                data: {
                    inscriptionTypeid: sliceInscription.typeInscription,
                    inferno: sliceInscription.departArreter,
                    numeropermis: sliceInscription.numeroPermis,
                    numerolicence: sliceInscription.numeroLicence,
                    nomcontact: sliceInscription.NomContact,
                    prenomcontact: sliceInscription.PrenomContact,
                    numerocontact: sliceInscription.numeroContact,
                    commentaire: sliceInscription.messageComplementaire,
                    experienceid: sliceInscription.experiencePilote,
                    stage: sliceInscription.stage,
                    competition: sliceInscription.competition,
                    circuitfavori: sliceInscription.roulage,
                    secondguidon: sliceInscription.partageInscription ? replaceEmptyFieldsWithNull(sliceInscription.secondGuidon) : null,
                    passagersidedata: sliceInscription.passagerSideCar ? replaceEmptyFieldsWithNull(sliceInscription.passagerSideData) : null,
                    assuranceid: sliceInscription.assurancePilote,
                    dejapresent: sliceInscription.ancienneParticipation,
                    groupe: sliceInscription.groupeAmis ? sliceInscription.groupe : null,
                    suivimarshall: sliceInscription.suiviMarshall,
                }
            };
            await saveInscription(obj_save_inscription).unwrap();
        } catch (e) {
            setOpenSnackBarError(true);
        }
    };
    const replaceEmptyFieldsWithNull = (inputObj) => {
        const outputObj = {...inputObj};

        for (const key in outputObj) {
            if (outputObj.hasOwnProperty(key) && (outputObj[key] === '' || outputObj[key] === undefined)) {
                outputObj[key] = null;
            }
        }
        return outputObj;
    };
    const handleConfirmInscription = async () => {
        try {
            await saveDataInscription();
            await confirmInscription(sliceInscription.inscriptionID).unwrap();
            localStorage.setItem('notification-home', ConstanteNotification.DOSSIER_PRISE_EN_COMPTE);
            navigate('/home');
        } catch (e) {
            setOpenSnackBarError(true);
        }
    };
    const submitSaveInscription = async () => {
        try {
            await saveDataInscription();
            setOpenSnackBar(true);
        } catch (e) {

        }
    };
    const handleChangeParticipationAmis = (event) => {
        dispatch(setGroupeAmis(event.target.checked));
        if (!event.target.checked) {
            dispatch(setEmptyGroup());
        }
    };
    const handleChangeSuiviMarshall = (event) => {
        dispatch(setSuiviMarshall(event.target.checked));
    };
    const handleErrorMaxMotoError = (data) => {
        setMaxMotoAlert(data);
    };

    const ValidationFormulaire = () => {
        const fieldsToValidate = [
            {
                name: 'select-type-inscription',
                value: sliceInscription.typeInscription,
                validator: formUtils.verifEmptyEntry,
                verif: true,
                message: null
            },
            {
                name: 'numero-permis-pilote-principal',
                value: sliceInscription.numeroPermis,
                validator: formUtils.verifEmptyEntry,
                verif: true,
                message: 'Saisir votre numero permis / CASM / BSR'
            },
            {
                name: 'experience-pilote-principal',
                value: sliceInscription.experiencePilote,
                validator: formUtils.verifEmptyEntry,
                verif: true,
                message: null
            },
            {
                name: 'assurance-pilote-principal',
                value: sliceInscription.assurancePilote,
                validator: formUtils.verifEmptyEntry,
                verif: true,
                message: null
            },
            {
                name: 'nom-contact-principal',
                value: sliceInscription.NomContact,
                validator: formUtils.verifEmptyEntry,
                verif: true,
                message: 'Saisir le nom de famille de votre contact'
            },
            {
                name: 'prenom-contact-principal',
                value: sliceInscription.PrenomContact,
                validator: formUtils.verifEmptyEntry,
                verif: true,
                message: 'Saisir le prénom de votre contact'
            },
            {
                name: 'numero-contact-principal',
                value: sliceInscription.numeroContact,
                validator: formUtils.verifEmptyEntry,
                verif: true,
                message: 'Saisir le numéro de votre contact'
            },
            {
                name: 'licence-pilote-principal',
                value: sliceInscription.numeroLicence,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.checkLicence,
                message: 'Saisir le numéro de licence'
            },
            {
                name: 'input-text-nom-pilote-partage',
                value: sliceInscription.secondGuidon.nom,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.partageInscription,
                message: 'Saisir le nom'
            },
            {
                name: 'input-text-prenom-pilote-partage',
                value: sliceInscription.secondGuidon.prenom,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.partageInscription,
                message: 'Saisir le prenom'
            },
            {
                name: 'input-text-email-pilote-partage',
                value: sliceInscription.secondGuidon.mail,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.partageInscription,
                message: "Saisir l'email"
            },
            {
                name: 'input-text-email-pilote-partage',
                value: sliceInscription.secondGuidon.mail,
                validator: formUtils.verifEmailFormat,
                verif: sliceInscription.partageInscription && formUtils.verifEmailFormat(sliceInscription.secondGuidon.mail),
                message: "Email invalide"
            },
            {
                name: 'input-assurance-pilote-partage',
                value: sliceInscription.secondGuidon.assurance,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.partageInscription,
                message: "Sélectionner l'assurance"
            },
            {
                name: 'input-text-numero-permis-pilote-partage',
                value: sliceInscription.secondGuidon.permis,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.partageInscription,
                message: "Saisir le numero de permis"
            },
            {
                name: 'input-text-numero-licence-pilote-partage',
                value: sliceInscription.secondGuidon.licence,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.partageInscription && sliceInscription.secondGuidon.checkLicence,
                message: "Saisir le numéro de licence"
            },
            {
                name: 'input-text-nom-pilote-passager',
                value: sliceInscription.passagerSideData.nom,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.passagerSideCar,
                message: 'Saisir le nom'
            },
            {
                name: 'input-text-prenom-pilote-passager',
                value: sliceInscription.passagerSideData.prenom,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.passagerSideCar,
                message: 'Saisir le prenom'
            },
            {
                name: 'input-text-email-pilote-passager',
                value: sliceInscription.passagerSideData.mail,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.passagerSideCar,
                message: "Saisir l'email"
            },
            {
                name: 'input-text-email-pilote-passager',
                value: sliceInscription.passagerSideData.mail,
                validator: formUtils.verifEmailFormat,
                verif: sliceInscription.passagerSideCar && formUtils.verifEmailFormat(sliceInscription.passagerSideCar.mail),
                message: "Email invalide"
            },
            {
                name: 'input-assurance-pilote-passager',
                value: sliceInscription.passagerSideData.assurance,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.passagerSideCar,
                message: "Sélectionner l'assurance"
            },
            {
                name: 'input-text-numero-licence-pilote-passager',
                value: sliceInscription.passagerSideData.licence,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.passagerSideCar && sliceInscription.passagerSideData.checkLicence,
                message: "Saisir le numéro de licence"
            },
            {
                name: 'input-text-groupe-pilote',
                value: sliceInscription.groupe.libelle,
                validator: formUtils.verifEmptyEntry,
                verif: sliceInscription.groupeAmis,
                message: 'Saisir le nom de votre groupe'
            },
        ];

        let firstErrorFieldName = '';
        let newErrorField = {...errorField};
        let isFormValid = true;

        fieldsToValidate.forEach(({name, value, validator, verif, message}) => {
            if (verif) {
                const isFieldValid = !validator(value);
                console.log(isFieldValid, value, validator);
                newErrorField[name] = {
                    error: !isFieldValid,
                    message: isFieldValid ? '' : message === null ? '' : message
                };
                if (!isFieldValid && !firstErrorFieldName) {
                    firstErrorFieldName = name;
                    isFormValid = false;
                }
            }
        });

        if (nombreMotoInscription <= 0) {
            newErrorField["input-number-moto"] = {
                error: nombreMotoInscription <= 0,
                message: ''
            }
            isFormValid = false;
        } else {
            newErrorField["input-number-moto"] = {
                error: false,
                message: ''
            }
        }

        setErrorField(newErrorField);

        if (firstErrorFieldName) {
            const errorElement = document.querySelector(`[name="${firstErrorFieldName}"]`);
            if (errorElement) {
                errorElement.focus();
                errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        } else {
            if (nombreMotoInscription <= 0) {
                const errorElement = document.querySelector(`[id="input-number-moto"]`);
                if (errorElement) {
                    errorElement.focus();
                    errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            }
        }

        return isFormValid;
    };

    const loadingSave = isLoadingSaveInscription || isLoadingConfirmInscription;
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' variant="h4">Inscription</Typography>
                        </Grid>
                    </Grid>
                    {sliceInscription.statut === Constants.STATUT_DOSSIER_INCOMPLET && <Fragment>
                        <Divider textAlign="center" sx={{
                            marginTop: 2,
                            marginBottom: 2,
                            color: 'red',
                            fontWeight: 'bold'
                        }}>Motif refus dossier</Divider>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <Card variant="outlined">
                                    <CardContent sx={{marginBottom: -1.5}}>
                                        <Typography variant="body1" gutterBottom
                                                    sx={{fontWeight: 'bold', borderColor: 'red'}}>
                                            {sliceInscription.motifInvalid}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Fragment>}
                    <Divider textAlign="left" sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}>Type d'inscription</Divider>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel error={errorField["select-type-inscription"].error}
                                            required>Inscription</InputLabel>
                                <Select
                                    name="select-type-inscription"
                                    label="Inscription"
                                    value={sliceInscription.typeInscription}
                                    onChange={handleChangeTypeInscription}
                                    error={errorField["select-type-inscription"].error}
                                    disabled={sliceInscription.modeConsultation}
                                >
                                    {listTypeInscription}
                                </Select>
                                {errorField["select-type-inscription"].error &&
                                    <FormHelperText error={true}>Selectionner votre
                                        inscription</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        disabled={sliceInscription.modeConsultation}
                                        control={<Switch checked={sliceInscription.departArreter}
                                                         onChange={handleChangeDepartArreter}/>}
                                        label="Je souhaite participer aux 200 mètres départ arrêté"/>
                                    <FormControlLabel
                                        disabled={sliceInscription.modeConsultation}
                                        control={<Switch checked={sliceInscription.partageInscription}
                                                         onChange={handleChangePartageGuidon}/>}
                                        label="Partager mon guidon"/>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {sliceInscription.partageInscription &&
                                <AddPilote listAssurance={listAssurance}
                                           type="partage"
                                           retrieveData={handleDataPartageGuidon}
                                           errorField={errorField}
                                           data={sliceInscription.secondGuidon}
                                           disable={sliceInscription.modeConsultation}
                                />}
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}>Information</Divider>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                name="numero-permis-pilote-principal"
                                fullWidth
                                id="Numero_Permis"
                                label="Numero de permis / CASM / BSR"
                                onChange={handleChangeNumeroPermis}
                                value={sliceInscription.numeroPermis}
                                error={errorField["numero-permis-pilote-principal"].error}
                                helperText={errorField["numero-permis-pilote-principal"].error ? errorField["numero-permis-pilote-principal"].message : ''}
                                disabled={sliceInscription.modeConsultation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormGroup>
                                <FormControlLabel
                                    disabled={sliceInscription.modeConsultation}
                                    control={<Switch checked={sliceInscription.checkLicence}
                                                     onChange={handleLicenceChecked}/>}
                                    label="Je possède une licence"/>
                            </FormGroup>
                        </Grid>
                        {sliceInscription.checkLicence &&
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    name="licence-pilote-principal"
                                    fullWidth
                                    id="licence"
                                    label="Licence"
                                    onChange={handleChangeLicence}
                                    value={sliceInscription.numeroLicence}
                                    error={errorField["licence-pilote-principal"].error}
                                    helperText={errorField["licence-pilote-principal"].error ? errorField["licence-pilote-principal"].message : ''}
                                    disabled={sliceInscription.modeConsultation}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Divider textAlign="left" sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}>Expérience</Divider>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label"
                                            error={errorField["experience-pilote-principal"].error}
                                            required>Expérience</InputLabel>
                                <Select
                                    name="experience-pilote-principal"
                                    label="Expérience"
                                    value={sliceInscription.experiencePilote}
                                    onChange={handleChangeExperience}
                                    error={errorField["experience-pilote-principal"].error}
                                    disabled={sliceInscription.modeConsultation}
                                >
                                    {listExperience}
                                </Select>
                                {errorField["experience-pilote-principal"].error &&
                                    <FormHelperText error={true}>Selectionner votre experience</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="Roulage"
                                fullWidth
                                id="Roulage"
                                label="Roulage"
                                multiline
                                helperText="Roulages auquels tu as participé ces 5 dernières années ? (Année / Circuit / Meilleur temps)"
                                onChange={handleChangeRoulage}
                                value={sliceInscription.roulage}
                                disabled={sliceInscription.modeConsultation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormGroup>
                                <FormControlLabel
                                    disabled={sliceInscription.modeConsultation}
                                    control={<Switch checked={sliceInscription.stage}
                                                     onChange={handleChangeStage}/>}
                                    label="J'ai déjà suivi un stage de piste"/>
                                <FormControlLabel
                                    disabled={sliceInscription.modeConsultation}
                                    control={<Switch checked={sliceInscription.competition}
                                                     onChange={handleChangeCompetition}/>}
                                    label="Je roule de temps en temps en compétition"/>
                                <FormControlLabel
                                    disabled={sliceInscription.modeConsultation}
                                    control={<Switch checked={sliceInscription.ancienneParticipation}
                                                     onChange={handleChangeOldParticipation}/>}
                                    label="As-tu déja participer au Trofeo Rosso"/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}>Moto</Divider>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                            <Button
                                disabled={props.listMotosDossier.length >= props.maxMoto.number || sliceInscription.modeConsultation}
                                variant="contained"
                                onClick={handleAjouterNouvelleMoto}>Ajouter une moto</Button>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Box sx={{
                                textAlign: "center"
                            }}>
                                <Chip id="input-number-moto"
                                      label={`Nombres de motos maximum : ${props.maxMoto.number}`}
                                      color={errorField["input-number-moto"].error ? "error" : "primary"}
                                      variant="outlined"/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ListMotoInscription listMotos={props.listMotosDossier}
                                                 disable={sliceInscription.modeConsultation}/>
                        </Grid>
                        {maxMotoAlert &&
                            <Grid item xs={12} sm={8}>
                                <Alert onClose={() => {
                                    setMaxMotoAlert(false)
                                }} severity="warning">Nombre de moto maximum atteint !</Alert>
                            </Grid>
                        }

                    </Grid>
                    {sliceInscription.passagerSideCar &&
                        <Fragment>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}>Ton passager side-car</Divider>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <AddPilote listAssurance={listAssurance}
                                               retrieveData={handleDataPassagerSide}
                                               errorField={errorField}
                                               data={sliceInscription.passagerSideData}
                                               disable={sliceInscription.modeConsultation}/>
                                </Grid>
                            </Grid>
                        </Fragment>}

                    <Divider textAlign="left" sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}>Assurance</Divider>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel error={errorField["assurance-pilote-principal"].error}
                                            required>Assurance</InputLabel>
                                <Select
                                    name="assurance-pilote-principal"
                                    label="Assurance"
                                    value={sliceInscription.assurancePilote}
                                    onChange={handleChangeAssurance}
                                    error={errorField["assurance-pilote-principal"].error}
                                    disabled={sliceInscription.modeConsultation}
                                >
                                    {listAssurance}
                                </Select>
                                {errorField["assurance-pilote-principal"].error &&
                                    <FormHelperText error={true}>Selectionner votre assurance</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Card sx={{
                                backgroundColor: "#D7D7D7"
                            }}>
                                <CardContent>
                                    <Typography variant="caption">
                                        <ul>
                                            {listDesciptionAssurance}
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}>Contact</Divider>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="nom-contact-principal"
                                fullWidth
                                label="Prenom"
                                value={sliceInscription.PrenomContact}
                                onChange={handleChangePrenomContact}
                                error={errorField["nom-contact-principal"].error}
                                helperText={errorField["nom-contact-principal"].error ? errorField["nom-contact-principal"].message : ''}
                                required
                                disabled={sliceInscription.modeConsultation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="prenom-contact-principal"
                                fullWidth
                                id="nom_contact"
                                label="Nom"
                                value={sliceInscription.NomContact}
                                onChange={handleChangeNomContact}
                                error={errorField["prenom-contact-principal"].error}
                                helperText={errorField["prenom-contact-principal"].error ? errorField["prenom-contact-principal"].message : ''}
                                required
                                disabled={sliceInscription.modeConsultation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <MuiTelInput
                                fullWidth
                                name="numero-contact-principal"
                                label="Numero de Téléphone"
                                value={sliceInscription.numeroContact}
                                onChange={handleChangeNumerocontact}
                                onlyCountries={['FR', 'BE', 'IT', 'ES', 'GB', 'CH', 'IE', 'NL', 'LU']}
                                langOfCountryName="fr"
                                defaultCountry="FR"
                                forceCallingCode
                                error={errorField["numero-contact-principal"].error}
                                helperText={errorField["numero-contact-principal"].error ? errorField["numero-contact-principal"].message : ''}
                                required
                                disabled={sliceInscription.modeConsultation}
                            />
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}>Complément</Divider>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormGroup>
                                <FormControlLabel
                                    disabled={sliceInscription.modeConsultation}
                                    control={<Switch checked={sliceInscription.suiviMarshall}
                                                     onChange={handleChangeSuiviMarshall}/>}
                                    label="Je souhaitre être suivi par un marchall"/>
                                <FormControlLabel
                                    disabled={sliceInscription.modeConsultation}
                                    control={<Switch checked={sliceInscription.groupeAmis}
                                                     onChange={handleChangeParticipationAmis}/>}
                                    label="Je participe avec des collègues (amis, famille, ...)"/>
                            </FormGroup>
                        </Grid>
                        {sliceInscription.groupeAmis &&
                            <Grid item xs={12} sm={12}>
                                <GroupeInscription
                                    disable={sliceInscription.modeConsultation}
                                    errorField={errorField}/>
                            </Grid>}
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="Message"
                                fullWidth
                                id="prenom"
                                label="Message"
                                multiline
                                onChange={handleChangeMessageComplementaire}
                                value={sliceInscription.messageComplementaire}
                                disabled={sliceInscription.modeConsultation}
                            />
                        </Grid>
                    </Grid>
                    {!sliceInscription.modeConsultation &&
                        <Fragment>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}>Validation</Divider>
                            <Grid container spacing={2} sx={{
                                marginBottom: 2
                            }}>
                                <Grid item xs={12} sm={12}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={checkBoxValidation}
                                                                                 onChange={handleChangeCheckValidation}/>}
                                                              label="Je comprends que la mise en place de mon dossier en ligne ne garantit pas ma participation au Trofeo Rosso"/>
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} sm={5}>
                                    <LoadingButton
                                        fullWidth
                                        loading={loadingSave}
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                    >
                                        {checkBoxValidation ? 'Confirmer' : 'Sauvegarder'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Fragment>
                    }
                    <Grid container sx={{
                        marginTop: 5,
                    }}>
                    </Grid>
                </Box>
            </Container>
            {displayMoto && <CardAddMoto errorMaxMoto={handleErrorMaxMotoError}
                                         edit={sliceInscription.editionMoto}/>}
            <Snackbar open={openSnackBar} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{width: '100%'}}>
                    Enregistrement Effectuer avec succès !
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarError} autoHideDuration={1000} onClose={handleCloseSnackBarError}>
                <Alert onClose={handleCloseSnackBarError} severity="error" sx={{width: '100%'}}>
                    Erreur interne veuillez réessayer ultérieurement
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
};