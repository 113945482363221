import {Fragment, useEffect, useState} from "react";
import {useGetFactureQuery, useGetPublicKeyQuery} from "../../feature/paiement/paiementApiSlice";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {useNavigate, useParams} from "react-router-dom";
import FormulairePaiement from "../../components/paiement/FormulairePaiement";

const PaiementFacture = () => {

    const navigate = useNavigate();

    const {factureID} = useParams();

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const {
        data: facture,
        isLoading: isLoadingFacture,
        isSuccess: SucessFacture,
        isError: isErreurfacture,
        error: errorFacture
    } = useGetFactureQuery(factureID);

    useEffect(() => {
        if (facture != null && SucessFacture) {
            const {clientSecret} = facture;
            // vérification si le client secret est présent
            if (clientSecret != null) {
                setClientSecret(clientSecret);
            } else {
                // redirection vers la home page
                navigate('/home');
            }
        }

        if (errorFacture != null && isErreurfacture) {
            if (errorFacture.status === 406) {
                navigate('/home');
            } else if (errorFacture.status === 500) {
                navigate('/500');
            }
        }
    }, [SucessFacture, facture, isErreurfacture, errorFacture]);

    const {
        data: publicKeyStripe,
        isSuccess: SucessPublicKeyStripe,
        isError: isErreurPublicKeyStripe,
        error: errorStripe
    } = useGetPublicKeyQuery();

    useEffect(() => {
        if (publicKeyStripe != null && SucessPublicKeyStripe) {
            const {publishableKey} = publicKeyStripe;
            setStripePromise(loadStripe(publishableKey));
        }

        if (errorStripe != null && isErreurPublicKeyStripe) {
            navigate('/500');
        }
    }, [SucessPublicKeyStripe, publicKeyStripe, isErreurPublicKeyStripe, errorStripe]);

    const loadElement = clientSecret != null && stripePromise != null && facture != null && !isLoadingFacture;

    return (
        <Fragment>
            {loadElement && (
                <Elements stripe={stripePromise} options={{clientSecret, locale: "fr"}}>
                    <FormulairePaiement dataFacture={facture.facture} factureId={factureID}/>
                </Elements>
            )}
        </Fragment>
    )
};
export default PaiementFacture;