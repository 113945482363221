import {apiSlice} from "../../app/api/apiSlice";

const urlInscription = "inscription";

export const inssciptionApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Motosinscription', 'Motosuser'],
    endpoints: builder => ({
        getListMotosInscription: builder.query({
            query: (inscriptionID) => ({
                url: `${urlInscription}/motos/${inscriptionID}`,
                method: 'GET'
            }),
            // keepUnusedDataFor: 5,
            providesTags: (result) =>
                result ? [...result.map(({id}) => ({type: 'Motosinscription', id})), {
                        type: 'Motosinscription',
                        id: 'LIST'
                    }] :
                    [{type: 'Motosinscription', id: 'LIST'}],
        }),
        addMotoToInscription: builder.mutation({
            query: (obj) => ({
                url: `${urlInscription}/${obj.inscriptionID}/moto/${obj.motoID}`,
                method: 'PUT'
            }),
            invalidatesTags: [{type: 'Motosinscription', id: 'LIST'}, {type: 'Motosuser', id: 'LIST'}],
        }),
        deleteMotoToInscription: builder.mutation({
            query: (obj) => ({
                url: `${urlInscription}/${obj.inscriptionID}/moto/${obj.motoID}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{type: 'Motosinscription', id: 'LIST'}, {type: 'Motosuser', id: 'LIST'}],
        }),
        getListTypeInscription: builder.query({
            query: (evenementID) => ({
                url: `${urlInscription}/type/${evenementID}`,
                method: 'GET'
            }),
        }),
        saveInscription: builder.mutation({
            query: (obj) => ({
                url: `${urlInscription}/${obj.inscriptionID}`,
                method: 'PUT',
                body: {...obj.data}
            }),
            invalidatesTags: [{type: 'DossierInscription', id: 'UNIQUE'}],
        }),
        getInscription: builder.query({
            query: (obj) => ({
                url: `${urlInscription}/${obj}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'DossierInscription', ...result.id}, {type: 'DossierInscription', id: 'UNIQUE'}] :
                    [{type: 'DossierInscription', id: 'UNIQUE'}],
        }),
        initInscription: builder.mutation({
            query: (evenementID) => ({
                url: `${urlInscription}/init/`,
                method: 'POST',
                body: {
                    evenementid: evenementID
                }
            }),
        }),
        deleteInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/${inscriptionID}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'InscriptionsEvenement', id: 'LIST'}],
        }),
        maxMotoInscription: builder.query({
            query: () => ({
                url: `${urlInscription}/maxmotos`,
                method: 'GET',
            }),
        }),
        confirmInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/confirm`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID
                }
            }),
        }),
        initPaiementInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/init-paiement`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID
                }
            }),
        }),
    })
})

export const {
    useGetListMotosInscriptionQuery,
    useAddMotoToInscriptionMutation,
    useDeleteMotoToInscriptionMutation,
    useGetListTypeInscriptionQuery,
    useSaveInscriptionMutation,
    useGetInscriptionQuery,
    useInitInscriptionMutation,
    useDeleteInscriptionMutation,
    useMaxMotoInscriptionQuery,
    useConfirmInscriptionMutation,
    useInitPaiementInscriptionMutation
} = inssciptionApiSlice