import {createSelector, createSlice} from "@reduxjs/toolkit"
import jwtDecode from "jwt-decode";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken')
    },
    reducers: {
        setCredentials: (state, action) => {
            const {accessToken, refreshToken} = action.payload
            //storage
            localStorage.setItem('token', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            state.token = accessToken;
            state.refreshToken = refreshToken;
        },
        logOut: (state, action) => {
            state.user = null;
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
        },
        setAcessToken: (state, action) => {
            const {accessToken} = action.payload;
            localStorage.setItem('token', accessToken);
            state.token = accessToken;
        }
    },
});

export const {setCredentials, logOut, setAcessToken} = authSlice.actions

export default authSlice.reducer

const tokenSelector = state => state.auth.token;

export const selectCurrentUser = createSelector(
    [tokenSelector],
    (token) => {
        if (token != null) {
            const payload = jwtDecode(token);
            return {
                nom: payload.nom,
                prenom: payload.prenom
            }
        } else {
            return null;
        }
    }
);
export const selectCurrentToken = createSelector(
    [tokenSelector],
    (token) => {
        return token ? token : undefined;
    }
);

export const selectCurrentTokenPayload = createSelector(
    [tokenSelector],
    (token) => {
        return token ? jwtDecode(token) : undefined;
    }
);
export const userConnected = (state) => {
    return state.auth.token != null;
};