import {apiSlice} from "../../app/api/apiSlice";

const urlEvenement = "evenement";

export const motosApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Evenement'],
    endpoints: builder => ({
        getEvenement: builder.query({
            query: () => ({
                url: `${urlEvenement}/current/`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
        }),
        getInscriptionEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlEvenement}/inscription/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: (result) =>
                result ? [...result.map(({id}) => ({
                        type: 'InscriptionsEvenement',
                        id
                    })), {type: 'InscriptionsEvenement', id: 'LIST'}] :
                    [{type: 'InscriptionsEvenement', id: 'LIST'}],
        }),
        getBenevoleEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlEvenement}/benevole/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: (result) =>
                result ? [...result.map(({id}) => ({type: 'BenevolesEvenement', id})), {
                        type: 'BenevolesEvenement',
                        id: 'LIST'
                    }] :
                    [{type: 'BenevolesEvenement', id: 'LIST'}],
        }),
        getZorgaEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlEvenement}/zorga/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 60,
        }),
    })
})

export const {
    useGetEvenementQuery,
    useGetInscriptionEvenementQuery,
    useGetBenevoleEvenementQuery,
    useGetZorgaEvenementQuery,
} = motosApiSlice