import {createSlice} from "@reduxjs/toolkit";
import * as Constants from "../../utils/ContantesDossier";
import {STATUT_DOSSIER_INCOMPLET} from "../../utils/ContantesDossier";

const intialState = {
    inscriptionID: '',
    skipFetchData: true,
    addmoto: false,
    editionMoto: false,
    partageInscription: false,
    passagerSideCar: false,
    checkLicence: false,
    departArreter: false,
    stage: false,
    competition: false,
    ancienneParticipation: false,
    groupeAmis: false,
    suiviMarshall: false,
    editionMotoID: '',
    evenementID: '',
    typeInscription: '',
    assurancePilote: '',
    experiencePilote: '',
    PrenomContact: '',
    NomContact: '',
    numeroContact: '',
    numeroPermis: '',
    numeroLicence: '',
    messageComplementaire: '',
    roulage: '',
    statut: '',
    secondGuidon: {
        id: '',
        nom: '',
        prenom: '',
        assurance: '',
        permis: '',
        licence: '',
        mail: '',
        checkLicence: false
    },
    passagerSideData: {
        id: '',
        nom: '',
        prenom: '',
        assurance: '',
        licence: '',
        mail: '',
        checkLicence: false
    },
    groupe: {
        id: '',
        libelle: ''
    },
    modeConsultation: false
};

const inscriptionSlice = createSlice({
        name: 'inscription',
        initialState: intialState,
        reducers: {
            setPanelMoto: (state, action) => {
                state.addmoto = action.payload;
            },
            setEditionMoto: (state, action) => {
                state.editionMoto = action.payload;
            },
            setEditionMotoID: (state, action) => {
                state.editionMotoID = action.payload;
            },
            setEvenementID: (state, action) => {
                state.editionMotoID = action.payload;
            },
            setPartageInscription: (state, action) => {
                state.partageInscription = action.payload;
            },
            setDepartArreter: (state, action) => {
                state.departArreter = action.payload;
            },
            setPassagerSide: (state, action) => {
                state.passagerSideCar = action.payload;
            },
            setTypeInscription: (state, action) => {
                state.typeInscription = action.payload;
            },
            setAssurancePilote: (state, action) => {
                state.assurancePilote = action.payload;
            },
            setExperiencePilote: (state, action) => {
                state.experiencePilote = action.payload;
            },
            setPrenomContact: (state, action) => {
                state.PrenomContact = action.payload;
            },
            setNomContact: (state, action) => {
                state.NomContact = action.payload;
            },
            setNumeroContact: (state, action) => {
                state.numeroContact = action.payload.replace(/\s+/g, '');
            },
            setPermis: (state, action) => {
                state.numeroPermis = action.payload;
            },
            setNumeroLicence: (state, action) => {
                state.numeroLicence = action.payload;
            },
            setMessageComplementaire: (state, action) => {
                state.messageComplementaire = action.payload;
            },
            setRoulage: (state, action) => {
                state.roulage = action.payload;
            },
            setStage: (state, action) => {
                state.stage = action.payload;
            },
            setCompetition: (state, action) => {
                state.competition = action.payload;
            },
            setSecondGuidon(state, action) {
                const {champ, content} = action.payload;
                switch (champ) {
                    case 'assurance' :
                        state.secondGuidon.assurance = content;
                        break;
                    case 'nom' :
                        state.secondGuidon.nom = content;
                        break;
                    case 'prenom' :
                        state.secondGuidon.prenom = content;
                        break;
                    case 'permis' :
                        state.secondGuidon.permis = content;
                        break;
                    case 'licence' :
                        state.secondGuidon.licence = content;
                        break;
                    case 'checkLicence' :
                        state.secondGuidon.checkLicence = content;
                        break;
                    case 'mail' :
                        state.secondGuidon.mail = content;
                        break;
                }
            },
            setPassagerSideData(state, action) {
                const {champ, content} = action.payload;
                switch (champ) {
                    case 'assurance' :
                        state.passagerSideData.assurance = content;
                        break;
                    case 'nom' :
                        state.passagerSideData.nom = content;
                        break;
                    case 'prenom' :
                        state.passagerSideData.prenom = content;
                        break;
                    case 'licence' :
                        state.passagerSideData.licence = content;
                        break;
                    case 'checkLicence' :
                        state.passagerSideData.checkLicence = content;
                        break;
                    case 'mail' :
                        state.passagerSideData.mail = content;
                        break;
                }
            },
            setInscription(state, action) {
                const payload = action.payload;

                state.motifInvalid = payload.motifInvalid != null ? payload.motifInvalid : '';
                state.numeroPermis = payload.numeropermis != null ? payload.numeropermis : '';
                state.typeInscription = payload.inscriptiontype != null ? payload.inscriptiontype.id : '';
                state.experiencePilote = payload.experience != null ? payload.experience.id : '';
                state.assurancePilote = payload.assurance != null ? payload.assurance.id : '';
                state.PrenomContact = payload.prenomcontact != null ? payload.prenomcontact : '';
                state.NomContact = payload.nomcontact != null ? payload.nomcontact : '';
                state.numeroContact = payload.numerocontact != null ? payload.numerocontact : '';
                state.numeroContact = payload.numerocontact != null ? payload.numerocontact : '';
                state.departArreter = payload.inferno != null ? payload.inferno : false;
                state.ancienneParticipation = payload.dejapresent != null ? payload.dejapresent : false;
                state.stage = payload.stage != null ? payload.stage : false;
                state.competition = payload.competition != null ? payload.competition : false;
                state.messageComplementaire = payload.commentutil != null ? payload.commentutil : '';
                state.roulage = payload.circuitfavoris != null ? payload.circuitfavoris : '';
                state.statut = payload.statut;
                state.modeConsultation = !(payload.statut === Constants.STATUT_DOSSIER_INIT || payload.statut === Constants.STATUT_DOSSIER_IN_PROGRESS || payload.statut === Constants.STATUT_DOSSIER_INCOMPLET);
                if (payload.inscription_extra_pilotes.length > 0) {
                    payload.inscription_extra_pilotes.forEach((val) => {
                        if (val.type === 'SECOND') {
                            state.secondGuidon.id = val.id;
                            state.secondGuidon.nom = val.nom !== null ? val.nom : '';
                            state.secondGuidon.prenom = val.prenom !== null ? val.prenom : '';
                            state.secondGuidon.assurance = val.assurance !== null ? val.assurance.id : '';
                            state.secondGuidon.permis = val.numeropermis !== null ? val.numeropermis : '';
                            state.secondGuidon.licence = val.licence !== null ? val.licence : '';
                            state.secondGuidon.mail = val.mail !== null ? val.mail : '';
                            state.partageInscription = true;
                        }
                        if (val.type === 'SIDE') {
                            state.passagerSideData.id = val.id;
                            state.passagerSideData.nom = val.nom !== null ? val.nom : '';
                            state.passagerSideData.prenom = val.prenom !== null ? val.prenom : '';
                            state.passagerSideData.assurance = val.assurance !== null ? val.assurance.id : '';
                            state.passagerSideData.licence = val.licence !== null ? val.licence : '';
                            state.passagerSideData.mail = val.mail !== null ? val.mail : '';
                            state.passagerSideCar = true;
                        }
                    });
                }
                if (payload.groupe_pilote != null) {
                    state.groupe.id = payload.groupe_pilote.id;
                    state.groupe.libelle = payload.groupe_pilote.libelle;
                    state.groupeAmis = true;
                }
                if (payload.numerolicence != null) {
                    state.checkLicence = true;
                    state.numeroLicence = payload.numerolicence;
                }
            },
            setEmptySecondGuidon(state) {
                state.secondGuidon.id = '';
                state.secondGuidon.nom = '';
                state.secondGuidon.prenom = '';
                state.secondGuidon.assurance = '';
                state.secondGuidon.permis = '';
                state.secondGuidon.licence = '';
            },
            setEmptyPassagerSideData(state) {
                state.passagerSideData.id = '';
                state.passagerSideData.nom = '';
                state.passagerSideData.prenom = '';
                state.passagerSideData.assurance = '';
                state.passagerSideData.licence = '';
            },
            setInscriptionid(state, action) {
                state.inscriptionID = action.payload;
            },
            setEvenementid(state, action) {
                state.evenementID = action.payload;
            },
            setSkipFetchData(state, action) {
                state.skipFetchData = action.payload;
            },
            setAncienneParticipation(state, action) {
                state.ancienneParticipation = action.payload;
            },
            setLibelleGroupe(state, action) {
                state.groupe.libelle = action.payload;
            },
            setEmptyGroup(state) {
                state.groupe.id = '';
                state.groupe.libelle = '';
            },
            setGroupeAmis(state, action) {
                state.groupeAmis = action.payload;
            },
            setCheckLicence(state, action) {
                state.checkLicence = action.payload;
                if (!action.payload) {
                    state.numeroLicence = '';
                }
            },
            setSuiviMarshall(state, action) {
                state.suiviMarshall = action.payload;
            },
            reset(state) {
                state.inscriptionID = '';
                state.evenementID = '';
                state.numeroPermis = '';
                state.typeInscription = '';
                state.experiencePilote = '';
                state.assurancePilote = '';
                state.PrenomContact = '';
                state.NomContact = '';
                state.numeroContact = '';
                state.numeroLicence = '';
                state.messageComplementaire = '';
                state.roulage = '';
                state.statut = '';
                state.ancienneParticipation = false;
                state.partageInscription = false;
                state.passagerSideCar = false;
                state.checkLicence = false;
                state.departArreter = false;
                state.stage = false;
                state.competition = false;
                state.groupeAmis = false;
                state.skipFetchData = true;
                state.suiviMarshall = false;
                // second guidon
                state.secondGuidon.id = '';
                state.secondGuidon.nom = '';
                state.secondGuidon.prenom = '';
                state.secondGuidon.assurance = '';
                state.secondGuidon.permis = '';
                state.secondGuidon.licence = '';
                state.secondGuidon.checkLicence = false;
                // passager side
                state.passagerSideData.id = '';
                state.passagerSideData.nom = '';
                state.passagerSideData.prenom = '';
                state.passagerSideData.assurance = '';
                state.passagerSideData.licence = '';
                state.passagerSideData.checkLicence = false;
                // groupe
                state.groupe.id = '';
                state.groupe.libelle = '';
                //mode consultation
                state.modeConsultation = false;
            }
        },
    })
;

export const {
    setPanelMoto,
    setEditionMoto,
    setEditionMotoID,
    setPartageInscription,
    setDepartArreter,
    setPassagerSide,
    setTypeInscription,
    setAssurancePilote,
    setExperiencePilote,
    setPrenomContact,
    setNomContact,
    setNumeroContact,
    setSecondGuidon,
    setPassagerSideData,
    setPermis,
    setNumeroLicence,
    setMessageComplementaire,
    setRoulage,
    setStage,
    setCompetition,
    setInscription,
    setEmptySecondGuidon,
    setEmptyPassagerSideData,
    setInscriptionid,
    setEvenementid,
    setSkipFetchData,
    setAncienneParticipation,
    setLibelleGroupe,
    setEmptyGroup,
    setGroupeAmis,
    setCheckLicence,
    setSuiviMarshall,
    reset
} = inscriptionSlice.actions;

export default inscriptionSlice.reducer;