import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Card, Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import {
    useDeleteBenevoleMutation,
    useInitBenevoleMutation,
    useInitPaiementBenevoleMutation
} from "../../feature/benevole/benevoleApiSlice";
import StatutDossier from "./StatutDossier";
import ButtonBenevole from "./button/ButtonBenevole";
import {LoadingButton} from "@mui/lab";

const Benevole = (props) => {

    let dossier;

    const evenement = props.evenement;
    const navigate = useNavigate();

    const [initBenevole, {isLoading: isInitBenevole}] = useInitBenevoleMutation();
    const [deleteBenevole] = useDeleteBenevoleMutation();
    const [initPaiementBenevole] = useInitPaiementBenevoleMutation();

    const handleClickInitDossier = async () => {
        await initBenevole(evenement.id).unwrap()
            .then(payload => {
                navigate(`/benevole/${payload.id_benevole}/${evenement.id}`);
            })
            .catch(() => {
                props.isError(true);
            })
    };
    const handleClickUpdateDossier = (idDossier) => {
        navigate(`/benevole/${idDossier}/${evenement.id}`);
    };
    const handleClickDeleteDossier = async (idDossier) => {
        await deleteBenevole(idDossier).unwrap()
            .catch(() => {
                props.isError(true);
            })
    };
    const handleClickPayDossier = async (idDossier) => {
        initPaiementBenevole(idDossier).unwrap()
            .then((data) => {
                let {factureID} = data;
                navigate(`/paiement/${factureID}`);
            })
            .catch(() => {
                props.isError(true);
            })
    };
    const handleOpenQrCode = (barCodeDossier) => {
        props.openQrCode(barCodeDossier);
    };
    const handleConsulterBenevole = (idDossier) => {
        navigate(`/benevole/${idDossier}/${evenement.id}`);
    };

    dossier = props.benevoles.map(val => {
        return (
            <Grid key={val.id} item xs={12} sm={6}>
                <Paper elevation={2} sx={{p: {xs: 2, md: 2}}}>
                    <Typography variant="subtitle1" align="center">
                        {val.barcode}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        paddingTop: 0.5
                    }}>
                        <Card>
                            <StatutDossier statut={val.statut}/>
                        </Card>
                    </Box>
                    <Fragment>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <ButtonBenevole
                                data={{idBenevole: val.id, statut: val.statut, barcode: val.barcode}}
                                suppresionBenevole={handleClickDeleteDossier}
                                updateBenevole={handleClickUpdateDossier}
                                payBenevole={handleClickPayDossier}
                                showQrCode={handleOpenQrCode}
                                consulterBenevole={handleConsulterBenevole}
                            />
                        </Box>
                    </Fragment>
                </Paper>
            </Grid>)
    });

    return (
        <Fragment>
            <Grid item xs={12} sm={12}>
                {new Date(evenement.datefininscription).getTime() >= new Date().getTime() &&
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <LoadingButton loading={isInitBenevole} color="info" size="medium" variant="contained"
                                       sx={{ml: 1}}
                                       onClick={handleClickInitDossier}
                                       disabled={props.benevoles.length >= process.env.REACT_APP_MAX_BENEVOLE}>
                            Créer Dossier Benevole
                        </LoadingButton>
                    </Box>}
            </Grid>
            {dossier}
        </Fragment>
    )
};

export default Benevole;