import {apiSlice} from "../../app/api/apiSlice"

export const motosApiSlice = apiSlice.injectEndpoints({
    tagTypes: ['Motos'],
    endpoints: builder => ({
        getMotos: builder.query({
            query: (userID) => ({
                url: `moto/user/${userID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
            providesTags: (result) =>
                // is result available?
                result ? [...result.map(({id}) => ({type: 'Motos', id})), {type: 'Motos', id: 'LIST'},] :
                    [{type: 'Motos', id: 'LIST'}],
        }),
        getTypesMotos: builder.query({
            query: () => ({
                url: `moto/types`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
        }),
        getTypesMotosEvent: builder.query({
            query: (evenementID) => ({
                url: `moto/types/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
        }),
        addMoto: builder.mutation({
            query: (newMoto) => ({
                url: 'moto/',
                method: 'POST',
                body: {...newMoto}
            }),
            invalidatesTags: [{type: 'Motosuser', id: 'LIST'}],
        }),
        getMoto: builder.query({
            query: (motoID) => ({
                url: `moto/${motoID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
            providesTags: (result) =>
                // is result available?
                result ? [{type: 'Moto', ...result}] : [{type: 'Moto', id: 'UNIQUE'}],
        }),
        putMoto: builder.mutation({
            query: (updateMoto) => ({
                url: `moto/${updateMoto.motoID}`,
                method: 'PUT',
                body: {...updateMoto.data}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Moto', id: arg.motoID},
                {type: 'Moto', id: 'UNIQUE'},
                {type: 'Motosinscription', id: 'LIST'},
                {type: 'Motosbenevole', id: 'LIST'}],
        }),
    })
})

export const {
    useGetMotosQuery,
    useGetTypesMotosQuery,
    useGetTypesMotosEventQuery,
    useAddMotoMutation,
    useGetMotoQuery,
    usePutMotoMutation,
} = motosApiSlice