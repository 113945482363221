import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";

const ListTShirt = (props) => {

    let listTShirt;

    const [selectTShirt, setSelectTShirt] = useState('');
    const [errorMoto, setErrorMoto] = useState(false);

    const handleChangeTShirt = (event) => {
        setSelectTShirt(event.target.value);
        props.selectTShirt(event.target.value);
    };

    useEffect( () => {
        if (props.errorMoto != null) {
            setErrorMoto(props.errorMoto);
        }
    },[props.errorMoto]);

    useEffect( () => {
        setSelectTShirt(props.selected);
    },[props.selected]);

    if (props.data.length > 0) {
        listTShirt = props.data.map( value => {
            return <MenuItem value={value.id} key={value.id}>{value.libelle}</MenuItem>
        })
    }

    return(
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" required>T-Shirt</InputLabel>
            <Select
                id="select-benevole-tshirt"
                label="T-Shirt"
                value={selectTShirt}
                onChange={handleChangeTShirt}
                error={errorMoto}
                disabled={props.disable}
            >
                {listTShirt}
            </Select>
            {errorMoto &&
                <FormHelperText error={true}>Veuillez sélectionner un TShirt</FormHelperText>
            }
        </FormControl>
    )
};

export default ListTShirt;