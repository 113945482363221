import * as Constants from "../../utils/ContantesDossier";
import Typography from "@mui/material/Typography";
import {CardContent} from "@mui/material";
import {useEffect, useState} from "react";
import {STATUT_DOSSIER_INCOMPLET} from "../../utils/ContantesDossier";

const StatutDossier = (props) => {

    const statut = props.statut;

    const [color, setColor] = useState('');

    useEffect( () => {
        if (statut === Constants.STATUT_DOSSIER_INIT || statut === Constants.STATUT_DOSSIER_IN_PROGRESS) {
            setColor('#1976d2');
        } else if (statut === Constants.STATUT_DOSSIER_ATTENTE_VALIDATION || statut === Constants.STATUT_DOSSIER_ATTENTE_PAIEMENT || statut === Constants.STATUT_DOSSIER_ATTENTE) {
            setColor('#fb8c00');
        } else if (statut === Constants.STATUT_DOSSIER_VALIDER || statut === Constants.STATUT_DOSSIER_CONFIRMER ||  statut === Constants.STATUT_DOSSIER_PAYER || statut === Constants.STATUT_DOSSIER_PRESENT) {
            setColor('#4caf50');
        } else if (statut === Constants.STATUT_DOSSIER_ANNULE || statut === Constants.STATUT_DOSSIER_ERREUR_PAIEMENT || statut === Constants.STATUT_DOSSIER_INCOMPLET || statut === Constants.STATUT_DOSSIER_REFUSER || statut === Constants.STATUT_DOSSIER_ABANDON) {
            setColor('#c50c0c');
        }
    })

    return(
        <CardContent sx={{
            pt: 0.25,
            '&:last-child': {pb: 0.25},
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 0.5,
            backgroundColor: color,
            color: '#fff'
        }}>
            <Typography variant="body1">
                Statut:
            </Typography>
            <Typography variant="body1" sx={{
                fontWeight: "bold"
            }}>
                {statut}
            </Typography>
        </CardContent>
    )
};
export default StatutDossier;